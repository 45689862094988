








































































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import UserDTO from "../../dto/UserDTO";
import RouteNames from "@/router/RouteNames";

const Auth = namespace("Auth");

@Component
export default class MobileMenu extends Vue {

  @Auth.State("user")
  private currentUser!: UserDTO;

  @Auth.Getter("hasAdministrativePrivileges")
        private hasAdministrativePrivileges!: boolean;

        @Auth.Action
        private signOut!: () => void;

        private routes = RouteNames

        private onSelect (){
            this.$emit("menuItemSelected");
        }

        private servicesExpanded = false;

        private profileExpanded = false;

        goToMain(){
            this.$router.push({name:"home"});
            this.onSelect();
        }

        goToAbout(){
            this.$router.push({name: "about"});
            this.onSelect();
        }

        logOut() {
            this.signOut();
            this.$router.push("/login");
            this.onSelect();
        }

        goToPersonalTaxation(){
            this.$router.push({name: this.routes.TAXATION_INDIVIDUAL, params: { userId: `${this.currentUser.id}` }});
            this.onSelect();
        }

        goToBusinessTaxation(){
            this.$router.push({name: this.routes.TAXATION_BUSINESS, params: { userId: `${this.currentUser.id}` }});
            this.onSelect();
        }

        goToProfile(){
            this.$router.push({name: this.routes.USER_PROFILE, params: {id: `${this.currentUser.id}`}});
            this.onSelect();
        }

        goToLoginPage(){
            this.$router.push({name: 'login'});
            this.onSelect();
        }

        goToAccount(){
            this.$router.push({ name: 'profile.account', params: { id: `${this.currentUser.id}` }});
            this.onSelect();
        }

        goToPersonalData(){
            this.$router.push({ name: 'personaldata', params: { id: `${this.currentUser.id}` }});
            this.onSelect();
        }

        goToDependants(){
            this.$router.push({ name: 'dependants', params: { id: `${this.currentUser.id}` }});
            this.onSelect();
        }

        goToCompanies(){
            this.$router.push({ name: 'companies', params: { id: `${this.currentUser.id}` }});
            this.onSelect();
        }

        goToDocuments(){
            this.$router.push({ name: 'profile.files', params: { id: `${this.currentUser.id}` }});
            this.onSelect();
        }

    }
