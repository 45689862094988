export default class CompanyFilterDTO {

    public searchQuery: string | null = null;

    public companyName: string | null = null;

    public pageNumber = 1;

    public ein: string | null = null;
    public email: string | null = null;

    public pageSize = 20;

    public isClient: boolean | null = null;

    public orderField = "ID";

    public orderDirection = "ASC";

    constructor(init?: Partial<CompanyFilterDTO>) {
        Object.assign(this, init);
    }
}