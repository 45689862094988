export default class PersonFilterDTO {

    public firstName: string | null = null;

    public lastName: string | null = null;

    public fullNameFilter: string | null = "";

    public email: string | null = null;

    public ssn: string | null = null;

    public pageNumber = 1;

    public pageSize = 20;

    public onlyCustomers = false;

    public onlyAdmins = false;

    public orderField = "ID";

    public orderDirection = "ASC";

    public isClient: null | boolean = null

    constructor(init?: Partial<PersonFilterDTO>) {
        Object.assign(this, init);
    }

}