







































import {Component, Emit, Model, Prop, Vue} from "vue-property-decorator";
import CountryPhone from "@/dto/CountryPhone";
import colorsJson from "../../data/countriesPhones.json";

@Component
export default class PhoneInput extends Vue {
	private countries: Array<CountryPhone> = [];
	private prefix = "";
	private phoneValue: string | null = null;

	@Prop({default: false})
	private isReadOnly!: boolean;

	@Prop({default: ''})
	private validatePattern!: string;

	@Prop({default: '10px'})
	private marginTop!: string;

	@Prop()
	@Model("input") phone!: string | null

	@Emit("input")
	updatePhone(maskedPhone: string) : string{
		return maskedPhone;
	}

	created() {
		for (let i = 0; i < colorsJson.length; i++) {
			this.countries.push(new CountryPhone(colorsJson[i].name, colorsJson[i].phoneMask))
		}
		if (this.phone != null) {
			this.prefix = this.phone.split(" ")[0]
			this.phoneValue = this.phone;
		} else {
			this.prefix = this.countries[0].phoneMask;
			this.phoneValue = this.prefix;
		}
	}

	maskHandle() {
		let mask: string = this.prefix + " (___) ___-__-__-__";
		let oldVal = this.phoneValue!.replace(/\D/g, "");

		if (this.phoneValue!.length <= this.prefix.length) {
			this.phoneValue = this.prefix;
		} else if (mask.length >= this.phoneValue!.length) {
			this.phoneValue = this.buildPhoneWithMask(mask, oldVal);
		} else if (mask.length < this.phoneValue!.length){
			this.phoneValue = this.phoneValue!.substr(0, mask.length)
		}

		this.updatePhone(this.phoneValue!);
	}

	buildPhoneWithMask(mask: string, oldVal: string) {
		let i = 0;

		let newValue = this.deleteSuffixIfNeed(mask.replace(/[_\d]/g, function (a:string) {
			return i < oldVal.length ? oldVal.charAt(i++) : a
		}));

		if (newValue.length <= mask.length) {
			oldVal = newValue;
		}

		if (this.phoneValue!.substr(oldVal.length -1) == "-") {
			newValue = oldVal.substr(0, oldVal.length);
		}

		return newValue;
	}

	deleteSuffixIfNeed(newValue: string) {
		if  (newValue.indexOf("_") != -1) {
			newValue = newValue.substr(0, newValue.indexOf("_"))
		}

		if (newValue.substr(newValue.length - 1) == "-") {
			newValue = newValue.substr(0, newValue.length - 1);
		}

		let suffix = newValue.substr(newValue.length - 2);

		if (suffix == " (" || suffix == ") ") {
			newValue = newValue.substr(0, newValue.length - 2)
		}

		return newValue;
	}

	changeHandle() {
		this.phoneValue = this.prefix;
	}

  get isRequired(): boolean {
    return this.validatePattern?.includes("required");
  }

}

