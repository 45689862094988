import { render, staticRenderFns } from "./GeneralPayrollReportView.vue?vue&type=template&id=be3e1132&scoped=true&"
import script from "./GeneralPayrollReportView.vue?vue&type=script&lang=ts&"
export * from "./GeneralPayrollReportView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be3e1132",
  null
  
)

export default component.exports