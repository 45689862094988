









































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import UserDTO from "../../dto/UserDTO";
import RouteNames from "@/router/RouteNames";

const Auth = namespace("Auth");

@Component
export default class DesktopMenu extends Vue {

    private routes = RouteNames

    @Auth.State("user")
    private currentUser!: UserDTO;

    @Auth.Getter("hasAdministrativePrivileges")
    private hasAdministrativePrivileges!: boolean;

    @Auth.Action
    private signOut!: () => void;

    goToMain(){
        this.$router.push({name:RouteNames.HOME})
    }

    logOut() {
        this.signOut();
        this.$router.push({name: RouteNames.LOGIN});
    }

}
