import ShareholderDTO from "@/dto/company/ShareholderDTO";
import EmploymentDTO from "@/dto/employee/EmploymentDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import AddressDTO from "@/dto/AddressDTO";
import {FederalTaxClassification} from "@/constants/FederalTaxClassification";
import {Account} from "@/dto/Account";
import {AccountType} from "@/constants/AccountTypes";
import BranchDto from "@/components/profile/branches/BranchDtos";
import {ProjectDTO} from "@/dto/payroll/ProjectDTOs";

export default class CompanyDTO implements Account{

    public id!: number;

    public name!: string;

    public ein!: string;

    public nationalTin!: string | null;

    public type: AccountType | null = null;

    public status = "";

    public registrationDate: Date | null = null;

    public userId!: number;

    public email!: string;

    public phone!: string | null;

    public client: boolean | null = null;

    public website = "";

    headquarter: BranchDto | null = null;

    branches: Array<BranchDto> = [];

    headquarterAddress: AddressDTO | null = null;

    mailingAddress: AddressDTO | null = null;

    registrationAgentAddress: AddressDTO | null = null;

    public dba = "";

    public officer: EmploymentDTO | null = null;

    public filingReceipt: FileMetaDTO | null = null;

    public certificateOfIncorporation: FileMetaDTO | null = null;

    public ss4Form: FileMetaDTO | null = null;

    public federalTaxClass: FederalTaxClassification | null = null;

    public shareholders: Array<ShareholderDTO> = [];

    public naicsType: number | null = null;

    public additionalNaicsType: number | null = null;

    public tin: string | null = null;
}