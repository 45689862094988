import SelectOption from "@/components/common/SelectOption";

export enum PayType {
    HOUR_RATE = "HOUR_RATE",
    FIX_RATE = "FIX_RATE"
}

export enum PayPeriodType {
    WEEKLY = "WEEKLY",
    BIWEEKLY = "BIWEEKLY",
    MONTHLY = "MONTHLY",
    QUARTERLY = "QUARTERLY",
    SEMI_MONTHLY = "SEMI_MONTHLY",
    // SEMI_ANNUALLY = "SEMI_ANNUALLY",
    // ANNUALLY = "ANNUALLY",
    UNDEFINED = "UNDEFINED"
}

export const PAY_TYPE_OPTIONS: Array<SelectOption> = [
    SelectOption.builder().title("Fix rate").value(PayType.FIX_RATE).build(),
    SelectOption.builder().title("Hour rate").value(PayType.HOUR_RATE).build()
]

export const PAY_PERIOD_TYPE_OPTIONS: Array<SelectOption> = [
    SelectOption.builder().title("Weekly").value(PayPeriodType.WEEKLY).build(),
    SelectOption.builder().title("Biweekly").value(PayPeriodType.BIWEEKLY).build(),
    SelectOption.builder().title("Monthly").value(PayPeriodType.MONTHLY).build(),
    SelectOption.builder().title("Semi-Monthly").value(PayPeriodType.SEMI_MONTHLY).build(),
    SelectOption.builder().title("Quarterly").value(PayPeriodType.QUARTERLY).build(),
    // SelectOption.builder().title("Semi-Annually").value(PayPeriodType.SEMI_ANNUALLY).build(),
    // SelectOption.builder().title("Annually").value(PayPeriodType.ANNUALLY).build(),
    SelectOption.builder().title("Payment schedule undefined").value(PayPeriodType.UNDEFINED).build()
]