import axios from "axios";
import {TaxBatch} from "@/dto/payroll/Tax";
import {TARIFFICATION_ENDPOINT, TARIFFICATION_OVERRIDE_TAX_RATE} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import {
    OverrideTaxRatePayload,
    UpdateTarifficationPayload
} from "@/dto/payroll/Tariffication";

class TarifficationService {

    public getTaxesByEmploymentId(employmentId: number) {
        return axios.get<TaxBatch>(`${TARIFFICATION_ENDPOINT}/${employmentId}`, {headers: authHeader()})
    }

    public overrideTaxRate(payload: OverrideTaxRatePayload) {
        return axios.put(`${TARIFFICATION_OVERRIDE_TAX_RATE}`, payload, {headers: authHeader()})
    }

    public updateTarifficationInfo(payload: UpdateTarifficationPayload) {
        return axios.put(`${TARIFFICATION_ENDPOINT}`, payload, {headers: authHeader()})
    }
}

export default new TarifficationService()