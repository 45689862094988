










































import {Component, Vue} from "vue-property-decorator";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import {ProjectDTO, UpdateProjectPayload} from "@/dto/payroll/ProjectDTOs";
import Workspaces from "@/state/Workspaces";
import CompanyService from "@/services/CompanyService";
import Application from "@/state/Application";
import Notifications from "@/state/Notifications";
import {processError} from "@/utils/ComponentUtils";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";
import EmploymentService from "@/services/EmploymentService";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import SelectOption from "@/components/common/SelectOption";
import ProjectCreationComponent from "@/components/payroll/business/ProjectCreationComponent.vue";
import moment from "moment/moment";
import {Moment} from "moment";
import {EmploymentType} from "@/constants/EmploymentType";
import RouteNames from "@/router/RouteNames";
import {AccountType} from "@/constants/AccountTypes";

@Component({
	methods: {
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		}
	},
	components: {PortalSelect, PortalInput, BTPortalCollapse}
})
export default class Projects extends Vue {

	private projects: Array<ProjectDTO> = [];


	public created(): void {
		this.loadProjects().then(() => Application.stopLoading())
	}


	private async loadProjects() {
		Application.startLoading()
		CompanyService.getProjectsByCompanyId().then(
			res => {
				console.log(res)
				this.projects = res.data
				console.log(res.data)
				Application.stopLoading()
				return
			},
			err => processError(err, this)
		)
	}

	private save(project: ProjectDTO): void {
		const updateReq = new UpdateProjectPayload()
		updateReq.id = project.id;
		updateReq.title = project.title;
		updateReq.employmentsId = project.employments.map(it => it.id as number)
		Application.startLoading();
		CompanyService.updateProject(updateReq).then(
			() => {
				Notifications.success('The project has been successfully updated!')
				Application.stopLoading()
				this.loadProjects()
			},
			err => processError(err, this)
		)
	}

	private openModal() {
		this.$modal.show(
			ProjectCreationComponent,
			{
				onSuccess: this.loadProjects()
			}
		)
	}

	private resolveRouterNameByEmploymentType(employment: IEmployment): { name: string, params: any } {
		switch (employment.employmentType!) {
			case EmploymentType.STAFF: return {
				name: RouteNames.PAYROLL_COMPANY_EMPLOYEE_DETAILS,
				params: {
					companyId: employment.employer!.id,
					employeeId: employment.id
				}
			};
			case EmploymentType.CONTRACTOR: if (employment.details.type == AccountType.PERSON) {
				return {
					name: RouteNames.PAYROLL_COMPANY_INDIVIDUAL_CONTRACTOR,
					params: {
						companyId: employment.employer!.id,
						contractorId: employment.id
					}
				};
			} else {
				return {
					name: RouteNames.PAYROLL_COMPANY_BUSINESS_CONTRACTOR,
					params: {
						companyId: employment.employer!.id,
						contractorId: employment.id
					}
				};
			}
			case EmploymentType.FOREIGN: if (employment.details.type == AccountType.PERSON) {
				return {
					name: RouteNames.PAYROLL_COMPANY_INDIVIDUAL_FOREIGN_CONTRACTOR,
					params: {
						companyId: employment.employer!.id,
						contractorId: employment.id
					}
				};
			} else {
				return {
					name: RouteNames.PAYROLL_COMPANY_BUSINESS_FOREIGN_CONTRACTOR,
					params: {
						companyId: employment.employer!.id,
						contractorId: employment.id
					}
				};
			}
		}
	}
}

