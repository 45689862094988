import {Role} from "@/constants/Role";

export default class SublimeEmployeeFilterDTO {
    public firstName: string | null = null;

    public lastName: string | null = null;

    public position: string | null = null;

    public role: Role | null = null;

    public email: string | null = null;

    public officeId: number | null = null;

    public fullNameFilter: string | null = null;

    public pageNumber = 0;

    public pageSize = 20;

    public orderField = 'ID';

    public orderDirection = 'ASC';

    constructor(init?: Partial<SublimeEmployeeFilterDTO>) {
        Object.assign(this, init);
    }
}
