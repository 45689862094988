import { render, staticRenderFns } from "./PolicySignatureOptions.vue?vue&type=template&id=53f7fd67&scoped=true&"
import script from "./PolicySignatureOptions.vue?vue&type=script&lang=ts&"
export * from "./PolicySignatureOptions.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f7fd67",
  null
  
)

export default component.exports