import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import AuthService from '@/services/AuthService';
import UserDTO from "@/dto/UserDTO";
import LoginRequestDTO from "@/dto/auth/LoginRequestDTO";
import InvitationCompletionRequestDTO from "@/dto/auth/InvitationCompletionRequestDTO";
import Workspaces from "@/state/Workspaces";
import PasswordResetCompletionRequestDTO from "@/dto/auth/PasswordResetCompletionRequestDTO";

const storedUser = localStorage.getItem('user');

@Module({namespaced: true})
class User extends VuexModule {

    public status = storedUser ? {loggedIn: true} : {loggedIn: false};

    public user: UserDTO | null = storedUser ? JSON.parse(storedUser) : null;

    // ---------------------------- MUTATIONS ----------------------------

    @Mutation
    public loginSuccess(user: any): void {
        this.status.loggedIn = true;
        this.user = user;
        Workspaces.resetWorkspace();
    }

    @Mutation
    public loginFailure(): void {
        this.status.loggedIn = false;
        this.user = null;
        Workspaces.setUser(null);
    }

    @Mutation
    public logout(): void {
        this.status.loggedIn = false;
        this.user = null;
        Workspaces.setUser(null);
    }

    @Mutation
    public registerSuccess(): void {
        this.status.loggedIn = false;
    }

    @Mutation
    public registerFailure(): void {
        this.status.loggedIn = false;
    }

    // ---------------------------- ACTIONS ----------------------------

    @Action({rawError: true})
    login(request: LoginRequestDTO): Promise<any> {
        return AuthService.login(request).then(
            user => {
                this.context.commit('loginSuccess', user);
                Workspaces.resetWorkspace();
                return Promise.resolve(user);
            },
            error => {
                this.context.commit('loginFailure');
                Workspaces.setUser(null);
                const message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                return Promise.reject(message);
            }
        );
    }

    @Action({rawError: true})
    completeInvitation(request: InvitationCompletionRequestDTO): Promise<any> {
        return AuthService.completeInvitation(request).then(
            user => {
                this.context.commit('loginSuccess', user);
                Workspaces.resetWorkspace();
                return Promise.resolve(user);
            },
            error => {
                this.context.commit('loginFailure');
                Workspaces.setUser(null);
                return Promise.reject(error);
            }
        );
    }

    @Action({rawError: true})
    resetPassword(request: PasswordResetCompletionRequestDTO): Promise<any> {
        return AuthService.resetPassword(request).then(
            user => {
                this.context.commit('loginSuccess', user);
                Workspaces.resetWorkspace();
                return Promise.resolve(user);
            },
            error => {
                this.context.commit('loginFailure');
                Workspaces.setUser(null);
                return Promise.reject(error);
            }
        );
    }

    @Action({rawError: true})
    signOut(): void {
        AuthService.logout();
        this.context.commit('logout');
        Workspaces.setUser(null);
    }

    // ---------------------------- GETTERS ----------------------------

    get isLoggedIn(): boolean {
        return this.status.loggedIn;
    }

    get isAdmin(): boolean {
        return !!this.user?.sublimeEmployee;
    }

    get isModerator(): boolean {
        return Workspaces.isUserSublimeEmployee
    }

    get hasAdministrativePrivileges(): boolean {
        return Workspaces.isUserSublimeEmployee
    }

}

export default User;
