

































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import PortalInput from "@/components/common/PortalInput.vue";
import {namespace} from "vuex-class";
import PortalDate from "@/components/common/PortalDate.vue";
import RouteNames from "@/router/RouteNames";
import {WorkspaceType, WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import TerminationModal from "@/components/payroll/business/employments/contractors/TerminationModal.vue";
import TerminationPayloadDTO from "@/components/payroll/business/employments/contractors/TerminationPayloadDTO";
import {ifValid, processError} from "@/utils/ComponentUtils";
import {
	IndividualForeignContractorDTO
} from "@/components/payroll/business/employments/foreignContractors/ForeignContractorPayloads";
import IndividualForeignContractorService
	from "@/components/payroll/business/employments/foreignContractors/IndividualForeignContractorService";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import Notifications from "@/state/Notifications";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {PAY_PERIOD_TYPE_OPTIONS, PAY_TYPE_OPTIONS, PayPeriodType, PayType} from "@/constants/TarifficationConstants";
import {DateTime} from "luxon";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import {contractTypeOptions} from "@/constants/EmploymentType";
import {EmploymentVue} from "@/components/payroll/business/employments/EmploymentVue";

const AppModule = namespace("App");

@Component({
	computed: {
		DateTime() {
			return DateTime
		},
		PAY_TYPE_OPTIONS() {
			return PAY_TYPE_OPTIONS
		},
		PayPeriodType() {
			return PayPeriodType
		},
		PayType() {
			return PayType
		},
		WorkspaceTypePathElement() {
			return WorkspaceTypePathElement
		},
		WorkspaceType() {
			return WorkspaceType
		},
		PAY_PERIOD_TYPE_OPTIONS() {
			return PAY_PERIOD_TYPE_OPTIONS
		},
	},
	methods: {
		contractTypeOptions: contractTypeOptions,
		RouteNames() {
			return RouteNames
		}
	},
	components: {PortalRadio, PortalCheckbox, PortalSelect, BTPortalCollapse, PortalDate, PortalInput}
})
export default class IndividualForeignContractor extends EmploymentVue<IndividualForeignContractorDTO> {

	private successful = false;

	@Prop()
	employment!: IndividualForeignContractorDTO

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private openTerminateModal():void {
		this.$modal.show(
			TerminationModal,
			{
				terminateId: this.employment.id,
				applyTerminate: (payload: TerminationPayloadDTO) => this.terminate(payload)
			}
		)
	}

	private terminate(payload: TerminationPayloadDTO): void {
		this.startLoading();
		IndividualForeignContractorService.terminateContractor(payload).then(
			() => {
				this.successful = true;
				Notifications.success("The contractor's contract has been terminated")
				this.$router.push({name: RouteNames.HR_CONTRACTORS, params: {companyId: String(this.employment.employer?.id)}})
				this.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private deleteContractor(): void {
		this.startLoading()
		IndividualForeignContractorService.deleteContractor(this.employment.id as number).then(
			() => {
				this.stopLoading()
				this.$router.push({name: RouteNames.HR_FOREIGN_CONTRACTORS, params: {companyId: String(this.employment.employer?.id)}})
			},
			err => processError(err, this)
		)
	}

	private update(): void {
		ifValid(this, () => {
			this.startLoading()
			IndividualForeignContractorService.updateContractor(this.employment).then(
				() => {
					this.successful = true;
					Notifications.success("The contractor's data has been updated")
					this.stopLoading()
				},
				err => processError(err, this)
			)
		})
	}
}

