export enum SalesTaxFilingPeriod {
    MONTHLY = "MONTHLY",
    QUARTERLY = "QUARTERLY",
    SEASONLY = "SEASONLY",
    ANNUAL = "ANNUAL"
}

export enum SalesTaxWorkflowStatus {
    CREATED = 'CREATED',
    PENDING_FOR_DOCUMENTS = 'PENDING_FOR_DOCUMENTS',
    DOCUMENTS_ACCEPTED = 'DOCUMENTS_ACCEPTED',
    ON_REVIEW = 'ON_REVIEW',
    ON_CUSTOMERS_APPROVAL = 'ON_CUSTOMERS_APPROVAL',
    REJECTED_BY_CUSTOMER = 'REJECTED_BY_CUSTOMER',
    APPROVED_BY_CUSTOMER = 'APPROVED_BY_CUSTOMER',
    SENT_TO_IRS = 'SENT_TO_IRS'
}


export enum SalesTaxBankStatementStatus {
    UPLOADED = 'UPLOADED',
    BEING_PROCESSED = 'BEING_PROCESSED',
    PROCESSED = 'PROCESSED',
    DECLINED = 'DECLINED',
    REQUESTED = 'REQUESTED'
}

export enum SalesTaxSubscriptionField {
    CONTRACT_NUMBER = "CONTRACT_NUMBER",
    CONTRACT_START_DATE = "CONTRACT_START_DATE",
    CONTRACT_END_DATE = "CONTRACT_END_DATE",
    OFFICE = "OFFICE",
    BANK_ACCOUNT = "BANK_ACCOUNT",
    PROCEED_PAYMENT_FROM_SA = "PROCEED_PAYMENT_FROM_SA",
    PAYMENTS = "PAYMENTS",
    STATE_FILING_PERIOD = "STATE_FILING_PERIOD",
    STATE_SCHEDULE = "STATE_SCHEDULE"
}

export enum SalesTaxReportFormat {
    E_FILE = 'E_FILE',
    PAPER = 'PAPER'
}