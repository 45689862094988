






























































































































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import UserDTO from "@/dto/UserDTO";
import FeedbackDTO from "@/dto/FeedbackDTO";
import PublicService from "@/services/PublicService";
import RegexUtils from "@/utils/RegexUtils";
import RouteNames from "@/router/RouteNames";
import PhoneInput from "@/components/util/PhoneInput.vue";
import Chat from "@/components/support/Chat.vue";

const Auth = namespace("Auth");
const AppModule = namespace("App");

const PERSON = 'p';
const COMPANY = 'c';

@Component({
	components: {
		Chat,
		PhoneInput
	}
})
export default class Home extends Vue {

	private routes = RouteNames

	private content = "";

	private feedback = new FeedbackDTO();

	private feedbackSuccessful = false;

	private feedbackMessage = "";

	private circleDiameter = 0;

	private borderRadius = 100;

	private circleFontSize = 0;

	private loading = false;


	@Auth.State("user")
	private currentUser!: UserDTO;

	@Auth.Getter("isAdmin")
	private isAdmin!: boolean;

	@Auth.Getter("isModerator")
	private isModerator!: boolean;

	@AppModule.State
	private isMobile!: boolean;

	@Auth.Getter("hasAdministrativePrivileges")
	private hasAdministrativePrivileges!: boolean;

	created(){
		this.$validator.extend('requiredPhoneOrEmail',{
			getMessage: field => 'Required phone number or email',
			validate: value => (this.feedback.phone != undefined && this.feedback.phone != null && this.feedback.phone != "")
				|| (this.feedback.email != undefined && this.feedback.email != null && this.feedback.email != "")
		});
	}

	mounted(){
		this.recalcCircleDiameter();
		this.recalcCircleFontSize();
		if(localStorage.getItem("locale")){
			this.$i18n.locale = localStorage.getItem("locale") || "en";
		}
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	}

	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	}

	onResize(){
		this.recalcCircleDiameter();
		this.recalcCircleFontSize();
	}

	recalcCircleDiameter(){
		this.circleDiameter = document.getElementById("circleContainer")?.clientWidth as number/4*0.81;
		return this.circleDiameter;
	}

	recalcCircleFontSize(){
		this.circleFontSize = this.circleDiameter * 0.133;
		return this.circleFontSize;
	}

	hasAdminAccess(){
		return this.isModerator || this.isAdmin;
	}

	sendFeedback(){
		this.feedbackMessage = "";
		// this.$validator.resume();
		this.$validator.validateAll().then((isValid) => {
			if(isValid){
				this.loading = true;
				PublicService.sendFeedback(this.feedback).then(
					response => {
						this.loading = false;
						this.feedbackMessage = this.$t("profile.we_will_contact") as string;
						this.feedbackSuccessful = true;
						this.$validator.pause();
						this.feedback = new FeedbackDTO();
					},
					error => {
						this.loading = false;
						this.feedbackMessage = "We are sorry, a server error occurred. Please call us call us on +1 (841) 852-9562"
						this.feedbackSuccessful = false;
					}
				);
			}
		})
	}

	handlePhone(event: KeyboardEvent) {
		if(event.key == "+" && this.feedback.phone != ""){
			event.preventDefault();
		}
		if (event.key != "+" && !RegexUtils.testNumeric(event.key)) {
			event.preventDefault();
		}
	}

	get isPhoneFilled(){
		return this.feedback.phone != undefined && this.feedback.phone != null && this.feedback.phone != "";
	}

	get isEmailFilled(){
		return this.feedback.email != undefined && this.feedback.email != null && this.feedback.email != "";
	}

	switchLocale(locale: string) {
		if (this.$i18n.locale !== locale) {
			this.$i18n.locale = locale;
			localStorage.setItem("locale", locale);
		}
	}

	handleChange(event: any){
		this.switchLocale(event.target.value.toLowerCase())
	}

	handleLetters(event: KeyboardEvent){
		if(!RegexUtils.testAnyLetter(event.key)){
			event.preventDefault();
		}
	}

	handleEmail(event: KeyboardEvent) {
		if(!RegexUtils.testEmailCharacters(event.key)){
			event.preventDefault();
		}
	}

	get userId(){
		return this.currentUser?.id;
	}

	get ovalStyle(){
		return `width:${this.circleDiameter}px;
            height:${this.circleDiameter*3.65}px;
            background-size: ${this.circleDiameter}px, ${this.circleDiameter*3.65}px;
            font-size:${this.circleFontSize}px`;

	}
}

