import { render, staticRenderFns } from "./PoliciesView.vue?vue&type=template&id=28a9ebf7&scoped=true&"
import script from "./PoliciesView.vue?vue&type=script&lang=ts&"
export * from "./PoliciesView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28a9ebf7",
  null
  
)

export default component.exports