import {hashCode} from "@/utils/StringUtils";

export enum Role {
    ADMIN = 'ADMIN',

    SALES_TAX_ADMIN = 'SALES_TAX_ADMIN',
    SALES_TAX_MANAGER = 'SALES_TAX_MANAGER',

    SUPPORT_MANAGER = 'SUPPORT_MANAGER',

    BANK_ACCOUNT_MANAGER = 'BANK_ACCOUNT_MANAGER',

    PAYROLL_ADMIN = 'PAYROLL_ADMIN',
    PAYROLL_MANAGER = 'PAYROLL_MANAGER',

    INDIVIDUAL_TAX_RETURN_ADMIN = 'INDIVIDUAL_TAX_RETURN_ADMIN',
    INDIVIDUAL_TAX_RETURN_MANAGER = 'INDIVIDUAL_TAX_RETURN_MANAGER',

    OPERATOR = 'OPERATOR',
    ON_SITE_MANAGER = 'ON_SITE_MANAGER',

    COMPANY_CLIENT_MANAGER = 'COMPANY_CLIENT_MANAGER',

    TEMP_ROLE = 'TEMP_ROLE'
}

class RoleNode {
    role!: Role;
    parentRole!: RoleNode | null;
    childRoles: Array<RoleNode> = [];
    hashCode!: number;

    isOrHasInTree(role: Role): boolean {
        return this.role === role || this.hasInTree(role);
    }

    hasInTree(role: Role): boolean {
        return !!this.childRoles.find(childRole => childRole.isOrHasInTree(role));
    }

    constructor(role: Role, parentRole: RoleNode | null) {
        this.role = role;
        this.parentRole = parentRole;
        if(parentRole){
           parentRole.childRoles.push(this);
        }
        this.hashCode = hashCode(role);
    }
}

const ADMIN = new RoleNode(Role.ADMIN, null);
const SALES_TAX_ADMIN = new RoleNode(Role.SALES_TAX_ADMIN, ADMIN);
const SALES_TAX_MANAGER = new RoleNode(Role.SALES_TAX_MANAGER, SALES_TAX_ADMIN);
const SUPPORT_MANAGER = new RoleNode(Role.SUPPORT_MANAGER, ADMIN);
const BANK_ACCOUNT_MANAGER = new RoleNode(Role.BANK_ACCOUNT_MANAGER, ADMIN);
const PAYROLL_ADMIN = new RoleNode(Role.PAYROLL_ADMIN, ADMIN);
const PAYROLL_MANAGER = new RoleNode(Role.PAYROLL_MANAGER, PAYROLL_ADMIN);
const INDIVIDUAL_TAX_RETURN_ADMIN = new RoleNode(Role.INDIVIDUAL_TAX_RETURN_ADMIN, ADMIN);
const INDIVIDUAL_TAX_RETURN_MANAGER = new RoleNode(Role.INDIVIDUAL_TAX_RETURN_MANAGER, INDIVIDUAL_TAX_RETURN_ADMIN);
const OPERATOR = new RoleNode(Role.OPERATOR, ADMIN);
const ON_SITE_MANAGER = new RoleNode(Role.ON_SITE_MANAGER, ADMIN);
const COMPANY_CLIENT_MANAGER = new RoleNode(Role.COMPANY_CLIENT_MANAGER, ADMIN);
const TEMP_ROLE = new RoleNode(Role.TEMP_ROLE, ADMIN);

export const ROLES_ROOT = ADMIN;

function buildNodesByRolesMap(): Map<Role, RoleNode> {
    const res = new Map<Role, RoleNode>();
    function putToMap(role: RoleNode){
        res.set(role.role, role);
        role.childRoles.forEach(r => putToMap(r))
    }
    putToMap(ROLES_ROOT)
    return res;
}

export const NODES_BY_ROLES: Map<Role, RoleNode> = buildNodesByRolesMap();

function buildRolesByCodesMap(): Map<number, Role>{
    const res = new Map<number, Role>();
    for (const entry of NODES_BY_ROLES.entries()) {
        res.set(entry[1].hashCode, entry[0]);
    }
    return res;
}

export const ROLES_BY_CODES: Map<number, Role> = buildRolesByCodesMap();