export default class ParticipantDTO {
    public _id: string | null = null;

    public username: string | null = null;

    public avatar: string | null = null;

    public status: ParticipantStatus | null = null;
}

class ParticipantStatus {
    public state: string | null = null;

    public lastChanged: string | null = null;
}