import { render, staticRenderFns } from "./EmploymentCard.vue?vue&type=template&id=3d61371c&scoped=true&"
import script from "./EmploymentCard.vue?vue&type=script&lang=ts&"
export * from "./EmploymentCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d61371c",
  null
  
)

export default component.exports