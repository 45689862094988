import CompanyDTO from "@/dto/company/CompanyDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
export class IRSLetter implements UpdateIRSLetterPayload{
    id!: number
    title: string | null = null;
    company!: CompanyDTO;
    letterFile!: FileMetaDTO;
    hidden = false;
    notify = false;
}

export interface UpdateIRSLetterPayload {
    id: number,
    title: string | null,
    hidden: boolean,
    notify: boolean
}

export class CreateIRSLetterPayload {
    title: string | null = null;
    companyId: number | null = null;
    letterFileMeta: FileMetaDTO | null = null;
    isHidden = false;
    isNotify = true;
}

export class IRSLetterFilter {
    title: string | null = null;
    page = 0;
    size = 20;
    companyId: number | null = null
}

export class AdminIRSLetterFilter extends IRSLetterFilter {
    isHidden: boolean | null = null
}