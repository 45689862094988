import SelectOption from "@/components/common/SelectOption";

export enum EmploymentType {

    STAFF = 'STAFF',
    CONTRACTOR = 'CONTRACTOR',
    FOREIGN = 'FOREIGN'

}

export enum ContractType {
    FULL_TIME = "FULL_TIME",
    PART_TIME = "PART_TIME",
    FIXED_TERM = "FIXED_TERM"
}

export function contractTypeOptions(exclude: ContractType[] = []): Array<SelectOption> {
    const options: Array<SelectOption> = [];
    if (exclude.indexOf(ContractType.FULL_TIME) < 0) options.push(SelectOption.builder().title("Full time").value(ContractType.FULL_TIME).build())
    if (exclude.indexOf(ContractType.PART_TIME) < 0) options.push(SelectOption.builder().title("Part time").value(ContractType.PART_TIME).build())
    if (exclude.indexOf(ContractType.FIXED_TERM) < 0) options.push(SelectOption.builder().title("Fixed term").value(ContractType.FIXED_TERM).build())
    return options;
}

export const EMPLOYMENT_TYPE_OPTIONS: Array<SelectOption> = [
    SelectOption.builder().title("All").value(null).build(),
    SelectOption.builder().title("Employee").value(EmploymentType.STAFF).build(),
    SelectOption.builder().title("Contractor").value(EmploymentType.CONTRACTOR).build(),
    SelectOption.builder().title("Foreign contractor").value(EmploymentType.FOREIGN).build()
]