import _Vue from "vue";
import {STATE_LIST} from "@/constants/ProfileConstants";
import {SalesTaxBankStatementStatus, SalesTaxFilingPeriod} from "@/constants/SalesTaxConstants";
import {Month, Period, YEARS} from "@/constants/DateTimeConstants";
import RouteNames from "@/router/RouteNames";
import {Role} from "@/constants/Role";

/**
 * Declare types at vue-plugins.d.ts
 * */
export function ConstantsVuePlugin(Vue: typeof _Vue, options?: any): void {

    Vue.prototype.$states = STATE_LIST
    Vue.prototype.$sales_tax = {
        filingPeriods: Object.values(SalesTaxFilingPeriod) as SalesTaxFilingPeriod[],
        bankStatementStatuses: Object.values(SalesTaxBankStatementStatus) as SalesTaxBankStatementStatus[]
    }
    Vue.prototype.$months = Object.values(Month) as Month[]
    Vue.prototype.$periods = Object.values(Period) as Period[]
    Vue.prototype.$years = YEARS
    Vue.prototype.$routeNames = RouteNames
    Vue.prototype.$roles = Object.values(Role) as Role[]
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    Vue.prototype.$void = () => {}

}
