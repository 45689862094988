import axios from 'axios';
import {
    AUTH_CHANGE_PASSWORD_ENDPOINT,
    AUTH_CONFIRM_EMAIL_ENDPOINT,
    AUTH_INVITATION_COMPLETION_ENDPOINT,
    AUTH_INVITATION_ENDPOINT,
    AUTH_LOGOUT_ENDPOINT,
    AUTH_NOTIFICATION_ENDPOINT,
    AUTH_REFRESH_TOKEN_ENDPOINT,
    AUTH_RESET_PASSWORD_ENDPOINT,
    AUTH_RESET_PASSWORD_REQUEST_ENDPOINT,
    AUTH_SIGN_IN_ENDPOINT,
    AUTH_SIGN_UP_ENDPOINT,
    AUTH_USER_ENDPOINT,
    TEMP_AUTH_FEEDBACK_ENDPOINT
} from '@/constants/endpoints';
import authHeader from "@/services/auth-header";
import LoginRequestDTO from "@/dto/auth/LoginRequestDTO";
import FeedbackDTO from "@/dto/FeedbackDTO";
import InvitationCompletionRequestDTO from "@/dto/auth/InvitationCompletionRequestDTO";
import PasswordResetCompletionRequestDTO from "@/dto/auth/PasswordResetCompletionRequestDTO";
import ChangePasswordRequestDTO from "@/dto/auth/ChangePasswordRequestDTO";
import UserDataDTO from "@/dto/UserDataDTO";
import Auth from "@/state/Auth";


class AuthService {

    login(request: LoginRequestDTO) {
        return axios
            .post(AUTH_SIGN_IN_ENDPOINT, request).then(
                response => {
                    if (response.data.accessToken) {
                        Auth.setUser(response.data);
                    }
                    if(response.data.secondaryToken){
                        Auth.setPerms(response.data.secondaryToken);
                    }
                    return response.data;
                });
    }

    logout() {
        const storedUser = localStorage.getItem('user');
        const user = JSON.parse(storedUser ? storedUser : "");
        const accessToken = user.accessToken;
        Auth.clear();
        return axios.post(AUTH_LOGOUT_ENDPOINT, {
            accessToken
        })
    }

    register(data: any) {
        return axios.post(AUTH_SIGN_UP_ENDPOINT, data);
    }

    refreshToken() {
        const storedUser = localStorage.getItem('user');
        const user = JSON.parse(storedUser ? storedUser : "");
        const refreshToken = user.refreshToken;
        return axios.post(AUTH_REFRESH_TOKEN_ENDPOINT, {
            refreshToken
        }).then(response => {
            if (response.data.accessToken) {
                user.accessToken = response.data.accessToken;
                user.refreshToken = response.data.refreshToken;
                Auth.setUser(user);
            }
        })
    }

    confirmEmail(confirmationCode: string) {
        return axios.post(AUTH_CONFIRM_EMAIL_ENDPOINT, {confirmationCode})
    }

    sendFeedback(request: FeedbackDTO){
        return axios.post(TEMP_AUTH_FEEDBACK_ENDPOINT, request);
    }

    sendInvitation(userId: number){
        return axios.post(AUTH_INVITATION_ENDPOINT, {userId: userId}, {headers: authHeader()})
    }

    completeInvitation(request: InvitationCompletionRequestDTO){
        return axios.post(AUTH_INVITATION_COMPLETION_ENDPOINT, request).then(
            response => {
                if (response.data.accessToken) {
                    Auth.setUser(response.data);
                }
                return response.data;
            });
    }

    requestPasswordResetLink(email: string){
        return axios.post(AUTH_RESET_PASSWORD_REQUEST_ENDPOINT, {email: email})
    }

    resetPassword(request: PasswordResetCompletionRequestDTO){
        return axios.post(AUTH_RESET_PASSWORD_ENDPOINT, request).then(
            response => {
                if (response.data.accessToken) {
                    Auth.setUser(response.data);
                }
                return response.data;
            });
    }

    changePassword(request: ChangePasswordRequestDTO) {
        return axios.post(AUTH_CHANGE_PASSWORD_ENDPOINT, request, {headers: authHeader()});
    }

    getUser(userId: number) {
        return axios.get<UserDataDTO>(`${AUTH_USER_ENDPOINT}/${userId}`, {headers: authHeader()});
    }

    toggleNotificationEnable(userId: number, notificationEnable: boolean) {
        return axios.put(`${AUTH_NOTIFICATION_ENDPOINT}`, {userId:userId, notificationEnable: notificationEnable}, {headers: authHeader()})
    }
}

export default new AuthService();
