


































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {LOCALES} from "@/constants/AppConstants";
import UserDTO from "@/dto/UserDTO";
import DesktopMenu from "@/components/main/DesktopMenu.vue";
import MobileMenu from "@/components/main/MobileMenu.vue";
import RouteNames from "@/router/RouteNames";
import SideMenu from "@/components/main/SideMenu.vue";
import Chat from "@/components/support/Chat.vue";
import Notification from "@/components/main/NotificationHolder.vue";
import chat from "@/store/modules/session.module";
import moment from "moment";
import {isPublicPage} from "@/utils/RoutePathUtils";
import Application from "./state/Application";

const Auth = namespace("Auth");
const AppModule = namespace("App");

@Component({
    components: { Notification, Chat, SideMenu, MobileMenu, DesktopMenu}
})
export default class App extends Vue {

    private showMobileMenu = false;

    private routes = RouteNames

	private currentYear = moment().format("YYYY");

	@Auth.Getter
	private isLoggedIn!: boolean;

    @Auth.State("user")
    private currentUser!: UserDTO;

    @Auth.Getter("isAdmin")
    private isAdmin!: boolean;

    @Auth.Getter("hasAdministrativePrivileges")
    private hasAdministrativePrivileges!: boolean;

    @AppModule.State
    private isMobile!: boolean;

    @AppModule.State
    private loading!: boolean;

    @AppModule.Action
    private onResize!: () => void

    private locales = LOCALES;

	get minHeight(): string {
		return window.innerHeight - 84 + 'px'
	}

    switchLocale(locale: string) {
        if (this.$i18n.locale !== locale) {
            this.$i18n.locale = locale;
            localStorage.setItem("locale", locale);
        }
    }

    mounted() {
        if (localStorage.getItem("locale")) {
            this.$i18n.locale = localStorage.getItem("locale") || "en";
        }
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
        chat.setConnection()
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    }

    get isPublicPage(): boolean{
      return !!this.$route.name && isPublicPage(this.$route.name) && this.$route.name != RouteNames.HOME && this.$route.name != RouteNames.LOCATIONS;
    }

}
