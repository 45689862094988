import {Month, MonthNumber, Period} from "@/constants/DateTimeConstants";

class DateUtils {
    formatDate(d: Date) {
        if (!d) {
            return '';
        }
        const date = new Date(d);
        return date.toLocaleDateString();
    }

    formatTime(d: Date) {
        if (!d) {
            return '';
        }
        const date = new Date(d);
        return date.toLocaleTimeString();
    }

    formatDateWithTime(d: Date) {
        if (!d) {
            return '';
        }
        const date = new Date(d);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    }

    formatDateToAmericanFormat(date: Date): string {
        date = new Date(date);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const day = date.getUTCDate();
        let daySuffix = 'th';
        switch (day) {
            case 1:
            case 21:
            case 31:
                daySuffix = 'st';
                break;
            case 2:
            case 22:
                daySuffix = 'nd';
                break;
            case 3:
            case 23:
                daySuffix = 'rd';
                break;
        }
        return `${months[date.getUTCMonth()]} ${day}${daySuffix} ${date.getUTCFullYear()}`;
    }

    formatDateToAmericanFormatWithTime(date: Date): string {
        date = new Date(date);
        return this.formatDateToAmericanFormat(date) + ' ' + date.toLocaleTimeString(['en-US'], {
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    getCurrentMonthNumber(): MonthNumber {
        return new Date().getMonth() + 1 as MonthNumber;
    }

    getPreviousMonthNumber() {
        const current = this.getCurrentMonthNumber();
        if (current == 1) {
            return 12 as MonthNumber;
        } else {
            return current - 1 as MonthNumber;
        }
    }

    getCurrentMonth(): Month {
        return this.getMonthByNumber(this.getCurrentMonthNumber());
    }

    getPreviousMonth(): Month {
        return this.getMonthByNumber(this.getPreviousMonthNumber());
    }

    getMonthByNumber(monthNumber: MonthNumber): Month {
        switch (monthNumber) {
            case 1:
                return Month.JANUARY;
            case 2:
                return Month.FEBRUARY;
            case 3:
                return Month.MARCH;
            case 4:
                return Month.APRIL;
            case 5:
                return Month.MAY;
            case 6:
                return Month.JUNE;
            case 7:
                return Month.JULY;
            case 8:
                return Month.AUGUST;
            case 9:
                return Month.SEPTEMBER;
            case 10:
                return Month.OCTOBER;
            case 11:
                return Month.NOVEMBER;
            case 12:
                return Month.DECEMBER;
        }
    }

    monthToPeriod(month: Month): Period {
        switch (month) {
            case Month.JANUARY:
                return Period.JANUARY;
            case Month.FEBRUARY:
                return Period.FEBRUARY;
            case Month.MARCH:
                return Period.MARCH;
            case Month.APRIL:
                return Period.APRIL;
            case Month.MAY:
                return Period.MAY;
            case Month.JUNE:
                return Period.JUNE;
            case Month.JULY:
                return Period.JULY;
            case Month.AUGUST:
                return Period.AUGUST;
            case Month.SEPTEMBER:
                return Period.SEPTEMBER;
            case Month.OCTOBER:
                return Period.OCTOBER;
            case Month.NOVEMBER:
                return Period.NOVEMBER;
            case Month.DECEMBER:
                return Period.DECEMBER;
        }
    }

    convertDatesToUTC(obj: Record<string, any>) {
        for (const key in obj) {
            if (obj[key] instanceof Date) {
                obj[key] = obj[key].toISOString();
            } else if (typeof obj[key] === 'object') {
                this.convertDatesToUTC(obj[key]);
            }
        }
    }

    convertUTCStringsToLocalDates(obj: Record<string, any>) {
        if (!obj || typeof obj !== 'object') return;
        for (const key in obj) {
            if (typeof obj[key] === 'string' && /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(obj[key])) {
                obj[key] = new Date(obj[key]);
            } else if (typeof obj[key] === 'object') {
                this.convertUTCStringsToLocalDates(obj[key]);
            }
        }
    }

    getCurrentYear(): number {
        return new Date().getFullYear();
    }

}

export default new DateUtils();