












import {Vue} from 'vue-property-decorator';

import Application from "@/state/Application";
import EmploymentService from "@/services/EmploymentService";
import {processError} from "@/utils/ComponentUtils";
import {IEmployment} from "@/dto/payroll/IEmployment";

export default class EmploymentCard<T extends IEmployment | null> extends Vue {

	employment: T | null = null

	get getEmployment(): T | null {
		return this.employment;
	}

	private title!: string

	private reload: () => void = this.loadEmployment

	get components() {
		const componentMap: Map<string, typeof Vue> = new Map<string, typeof Vue>()
		return componentMap
	}

	private loadEmployment(): Promise<void> {
		Application.startLoading();
		return EmploymentService.getById<T>(
			this.$route.params.employeeId ? this.$route.params.employeeId : this.$route.params.contractorId
		).then(
			res => {
				this.employment = res.data;
				Application.stopLoading();
			},
			err => processError(
				err,
				this,
				{
					callback: () => this.$modal.hideAll()
				}
			)
		)
	}

	public created() {
		this.loadEmployment()
	}

	public setTitle(value: string): void {
		this.title = value;
	}
}

