export default class UpdateSublimeEmployeeRequest {
    public employeeId: number | null = null;

    public firstName: string | null = null;

    public lastName: string | null = null;

    public position: string | null = null;

    public officeId: number | null = null;

    public officePhoneNumber: string | null = null;
}
