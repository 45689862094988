import axios, {AxiosResponse} from "axios";
import authHeader from "@/services/auth-header";
import {
    ADMIN_ASSIGNMENTS_ENDPOINT,
    ADMIN_EMPLOYEES_ENDPOINT, ADMIN_ENDPOINT,
    ADMIN_OFFICES_ENDPOINT,
    ADMIN_PORTAL_MAIL_SERVICE_ENDPOINT, ADMIN_ROLES_ENDPOINT, AUDIT_EVENTS_ENDPOINT,
} from "@/constants/endpoints";
import SublimeOfficeDTO from "@/dto/sublime/office/SublimeOfficeDTO";
import CreateSublimeOfficeRequest from "@/dto/sublime/office/CreateSublimeOfficeRequest";
import SublimeOfficeUpdateRequest from "@/dto/sublime/office/SublimeOfficeUpdateRequest";
import SublimeEmployeeFilterDTO from "@/dto/sublime/employee/SublimeEmployeeFilterDTO";
import QueryUtils from "@/utils/QueryUtils";
import CollectionUtils from "@/utils/CollectionUtils";
import Pageable from "@/dto/Pageable";
import RegisterSublimeEmployeeRequest from "@/dto/sublime/employee/RegisterSublimeEmployeeRequest";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";
import UpdateSublimeEmployeeRequest from "@/dto/sublime/employee/UpdateSublimeEmployeeRequest";
import {AutoAssignmentOptionsRecordDTO} from "@/dto/sublime/assignments/AutoAssignmentOptionsRecordDTO";
import {Role} from "@/constants/Role";
import PersonDTO from "@/dto/person/PersonDTO";

export default new class AdminService {
    togglePortalNotificationEnable() {
        return axios.put(`${ADMIN_PORTAL_MAIL_SERVICE_ENDPOINT}`, {}, {headers: authHeader()})
    }

    getPortalNotificationsEnable() {
        return axios.get<boolean>(`${ADMIN_PORTAL_MAIL_SERVICE_ENDPOINT}`, {headers: authHeader()})
    }

    getAllOffices(): Promise<AxiosResponse<Array<SublimeOfficeDTO>>> {
        return axios.get<Array<SublimeOfficeDTO>>(`${ADMIN_OFFICES_ENDPOINT}`, {headers: authHeader()})
    }

    public createSublimeOffice(req: CreateSublimeOfficeRequest) {
        return axios.post(`${ADMIN_OFFICES_ENDPOINT}`, req, {headers: authHeader()})
    }

    public getOffice(id: string) {
        return axios.get<SublimeOfficeDTO>(`${ADMIN_OFFICES_ENDPOINT}/${id}`, {headers: authHeader()})
    }

    public getEmployee(id: string | number) {
        return axios.get<SublimeEmployeeDTO>(`${ADMIN_EMPLOYEES_ENDPOINT}/${id}`, {headers: authHeader()})
    }

    public getEmployeePersonProfile() {
        return axios.get<PersonDTO>(`${ADMIN_ENDPOINT}/person`, {headers: authHeader()})
    }

    public updateSublimeOffice(req: SublimeOfficeUpdateRequest) {
        return axios.put(`${ADMIN_OFFICES_ENDPOINT}`, req, {headers: authHeader()})
    }

    public updateEmployeeData(req: UpdateSublimeEmployeeRequest) {
        return axios.put(`${ADMIN_OFFICES_ENDPOINT}`, req, {headers: authHeader()})
    }

    public getEmployees(filter: SublimeEmployeeFilterDTO) {
        const queryString = QueryUtils.objectToQueryString(filter);
        return axios.get<Pageable<SublimeEmployeeDTO>>(`${ADMIN_EMPLOYEES_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    public registerSublimeEmployee(request: RegisterSublimeEmployeeRequest) {
        return axios.post<void>(`${ADMIN_EMPLOYEES_ENDPOINT}`, request, {headers: authHeader()})
    }

    public getAutoAssignmentOptions(){
        return axios.get<Array<AutoAssignmentOptionsRecordDTO>>(ADMIN_ASSIGNMENTS_ENDPOINT, {headers: authHeader()});
    }

    public setAutoAssignment(record: AutoAssignmentOptionsRecordDTO){
        return axios.put(ADMIN_ASSIGNMENTS_ENDPOINT, record, {headers: authHeader()});
    }

    public getEmployeesByRole(role: Role){
        return axios.get<Array<SublimeEmployeeDTO>>(`${ADMIN_ROLES_ENDPOINT}/${role}/employees`, {headers: authHeader()});
    }


}
