import axios from "axios";
import RoomDTO from "@/dto/chat/RoomDTO";
import { CHAT_ENDPOINT } from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import CreateChatRequest from "@/dto/chat/CreateChatRequest";
import ParticipantDTO from "@/dto/chat/ParticipantDTO";
import MessageDTO from "@/dto/chat/MessageDTO";
import QueryUtils from "@/utils/QueryUtils";
import ChatResponse from "@/dto/chat/ChatResponse";
import Workspace from "@/dto/auth/Workspace";

class ChatService {
    public getChatsById(id: string) {
        return axios.get<RoomDTO>(`${CHAT_ENDPOINT}/${id}`, {headers: authHeader()})
    }

    public getChats(id: number, pageNumber = 0) {
        const queryString = QueryUtils.buildQueryString(
            ['id', id],
            ['page', pageNumber]);
        return axios.get<ChatResponse>(CHAT_ENDPOINT + queryString, {headers: authHeader()})
    }

    public createChat(req: CreateChatRequest) {
        return axios.post<RoomDTO>(`${CHAT_ENDPOINT}`, req, {headers: authHeader()})
    }

    public fetchMessages(roomId: string) {
        return axios.get<Array<MessageDTO>>(`${CHAT_ENDPOINT}/${roomId}/messages`, {headers: authHeader()})
    }

    public fetchMessagesForAdmin(roomId: string) {
        return axios.get<Array<MessageDTO>>(`${CHAT_ENDPOINT}/admin/${roomId}/messages`, {headers: authHeader()})
    }
}

export default new ChatService();
