import {
    ApproveRequestPayload,
    ChangeStatusVCRRequestPayload,
    CreateVacationPayload, CreateVCRequestPayload, RejectRequestPayload,
    UpdateVacationCarryoverPayload,
    VacationBalance, VacationBalanceDetailForPeriod, VacationBalanceDetails, VacationBalanceDetailsFilter,
    VacationDto,
    VacationFilter, VCR, VCRFilter
} from "@/dto/payroll/VacationDTO";
import QueryUtils from "@/utils/QueryUtils";
import axios, {AxiosResponse} from "axios";
import Pageable from "@/dto/Pageable";
import {PAYROLL_VACATION_ENDPOINT, PAYROLL_VACATION_REQUEST_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";

class VacationService {
    getPageOfVacations(filter: VacationFilter) {
        const query = QueryUtils.objectToQueryString(filter)
        return axios.get<Pageable<VacationDto>>(`${PAYROLL_VACATION_ENDPOINT}${query}`, {headers: authHeader()})
    }

    getVacationBalance(employmentId: number) {
        return axios.get<VacationBalance>(`${PAYROLL_VACATION_ENDPOINT}/balance?employmentId=${employmentId}`, {headers: authHeader()})
    }

    createVacationRecord(req: CreateVacationPayload) {
        return axios.post<void>(`${PAYROLL_VACATION_ENDPOINT}`, req, {headers: authHeader()})
    }

    deleteVacationRecord(vacationId: number, employmentId: number) {
        return axios.delete(`${PAYROLL_VACATION_ENDPOINT}?vacationId=${vacationId}&employmentId=${employmentId}`, {headers: authHeader()})
    }

    updateVacationBalance(payload: UpdateVacationCarryoverPayload) {
        return axios.put(`${PAYROLL_VACATION_ENDPOINT}/balance`, payload, {headers: authHeader()})
    }

    requestVacation(payload: CreateVCRequestPayload) {
        return axios.post(`${PAYROLL_VACATION_REQUEST_ENDPOINT}`, payload, {headers: authHeader()})
    }

    getPageOfVacationRequests(reqFilter: VCRFilter) {
        const query = QueryUtils.objectToQueryString(reqFilter)
        return axios.get<Pageable<VCR>>(`${PAYROLL_VACATION_REQUEST_ENDPOINT}${query}`, {headers: authHeader()})
    }

    changeStatus(payload: ChangeStatusVCRRequestPayload) {
        return axios.put(`${PAYROLL_VACATION_REQUEST_ENDPOINT}`, payload, {headers: authHeader()})
    }

    rejectRequest(payload: RejectRequestPayload) {
        return axios.put(`${PAYROLL_VACATION_REQUEST_ENDPOINT}/reject`, payload, {headers: authHeader()})
    }

    approveRequest(payload: ApproveRequestPayload) {
        return axios.put(`${PAYROLL_VACATION_REQUEST_ENDPOINT}/approve`, payload, {headers: authHeader()})
    }

    getBalanceDetails(balanceDetailsFilter: VacationBalanceDetailsFilter): Promise<AxiosResponse<Pageable<VacationBalanceDetails>>> {
        const query = QueryUtils.objectToQueryString(balanceDetailsFilter)
        return axios.get<Pageable<VacationBalanceDetails>>(`${PAYROLL_VACATION_ENDPOINT}/balance/details${query}`, {headers: authHeader()})
    }

    getBalanceDetailsForPeriod(filter: VacationBalanceDetailsFilter): Promise<AxiosResponse<VacationBalanceDetailForPeriod>> {
        const query = QueryUtils.objectToQueryString(filter)
        return axios.get<VacationBalanceDetailForPeriod>(`${PAYROLL_VACATION_ENDPOINT}/balance/period_details${query}`, {headers: authHeader()})
    }

}

export default new VacationService()