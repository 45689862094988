








































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {PTOPolicy, UpdatePTOPolicyPayload} from "@/dto/payroll/policies/PTOPolicyDTO";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {STATE_LIST} from "@/constants/ProfileConstants";
import {EMPLOYMENT_TYPE_OPTIONS, ContractType, contractTypeOptions} from "@/constants/EmploymentType";
import SelectOption from "@/components/common/SelectOption";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {processError} from "@/utils/ComponentUtils";
import PolicyService from "@/services/payroll/PolicyService";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";

@Component({
	computed: {
		SelectOption() {
			return SelectOption
		},
		EMPLOYMENT_TYPE_OPTIONS() {
			return EMPLOYMENT_TYPE_OPTIONS
		}
	},
	methods: {
		STATE_LIST() {
			return STATE_LIST
		}
	},
	components: {PortalRadio, PortalCheckbox, PortalSelect, PortalInput}
})
export default class PTOEditorModal extends Vue {

	@Prop()
	private pto!: PTOPolicy

	@Prop()
	private callback!: () => void

	private req: UpdatePTOPolicyPayload = new UpdatePTOPolicyPayload(
		{
			policyId: this.pto.id,
			eligibilityPeriod: this.pto.eligibilityPeriod,
			vacationIntervalTotal: this.pto.vacationIntervalTotal,
			vacationIntervalMin: this.pto.vacationIntervalMin,
			vacationNoticePeriod: this.pto.vacationNoticePeriod,
			carryover: this.pto.carryover,
			vacationPayout: this.pto.vacationPayout,
			applicableFor: this.pto.applicableFor,
			applicableState: this.pto.applicableState,
			title: this.pto.title,
			officerTitle: this.pto.officerTitle
		}
	)

	private edit() {
		Application.startLoading();
		PolicyService.editPTOPolicy(this.req).then(
			() => {
				this.$modal.hideAll();
				Application.stopLoading();
				this.callback();
			},
			err => processError(err, this)
		)
	}

	private get contractType(): Array<SelectOption> {
		const options: Array<SelectOption> = []
		options.push(SelectOption.builder().title("All").value(null).build())
		options.push(...contractTypeOptions([ContractType.FIXED_TERM]))
		return options;
	}
}

