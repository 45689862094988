












import {Component, Vue} from "vue-property-decorator";
import BusinessTimesheet from "@/components/payroll/timesheet/BusinessTimesheet.vue";
import IndividualTimesheet from "@/components/payroll/timesheet/IndividualTimesheet.vue";
import Workspaces from "@/state/Workspaces";
import {WorkspaceType} from "@/dto/auth/Workspace";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import SelectOption from "@/components/common/SelectOption";

@Component({
	components: {PortalRadio}
})
export default class TimeSheet extends Vue {

	private format = "hh:mm A"

	private readonly businessTimeSheet = BusinessTimesheet
	private readonly individualTimesheet = IndividualTimesheet

	private timesheet!: typeof Vue

	public created(): void {
		switch (Workspaces.getCurrent.type) {
			case WorkspaceType.PERSON:
			case WorkspaceType.SUBLIME_EMPLOYEE:
				this.timesheet = this.individualTimesheet; break;
			case WorkspaceType.COMPANY:
				this.timesheet = this.businessTimeSheet
		}
	}

	timeFormats(): SelectOption[] {
		return [
			SelectOption.builder().title("12 hours").value("hh:mm A").build(),
			SelectOption.builder().title("24 hours").value("HH:mm").build()
		]
	}

}

