





































import {Vue, Component, Prop} from 'vue-property-decorator';
import {TimeSheetRecord, UpdateTimeSheetRecordPayload} from "@/dto/payroll/timesheet/TimesheetPayloads";
import moment from "moment/moment";
import PortalTime from "@/components/common/PortalTime.vue";
import {ifValid, processError} from "@/utils/ComponentUtils";
import Application from "@/state/Application";
import TimesheetService from "@/services/payroll/TimesheetService";
import Notifications from "@/state/Notifications";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import PortalDateTime from "@/components/common/PortalDateTime.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";

type viewMode = "EDIT" | "VIEW"

@Component({
	components: {PortalDate, PortalDateTime, PortalTime}
})
export default class TimesheetDetailsPeriodItem extends Vue {

	@Prop()
	private record!: TimeSheetRecord

	@Prop()
	private employment!: IEmployment

	private startTime = moment(this.record.workPeriodStart).format("HH:mm")
	private endTime = this.record.workPeriodEnd ? moment(this.record.workPeriodEnd).format("HH:mm") : null

	@Prop()
	private timeFormat!: string

	private mode: viewMode = "VIEW"


	get recordTimePeriod(): string {
		const end = this.record.workPeriodEnd ? moment(this.record.workPeriodEnd).format(this.timeFormat) : "in progress"
		return `${moment(this.record.workPeriodStart).format(this.timeFormat)} - ${end}`
	}

	private showEdit() {
		this.mode = "EDIT";
	}

	private closeEdit() {
		this.mode = "VIEW"
	}

	private updateRecord() {
		ifValid(this, () => {
			Application.startLoading()
			TimesheetService.updateRecord(
				UpdateTimeSheetRecordPayload.builder()
					.id(this.record.id)
					.employmentId(this.employment.id)
					.workPeriodEnd(this.endTime ? new Date(`${this.record.date} ${this.endTime}`) : null)
					.workPeriodStart(new Date(`${this.record.date} ${this.startTime}`))
					.build()
			).then(
				() => {
					Application.stopLoading()
					Notifications.success("Changes was saved!")
					this.closeEdit()
					this.$emit('onUpdateRecord')
				},
				err => processError(err, this)
			)

		})
	}

	private onDelete() {
		Application.startLoading()
		TimesheetService.deleteRecord(this.record.id!, this.employment.id!).then(
			() => {
				Application.stopLoading()
				Notifications.success("Record has been deleted!")
				this.$modal.hideAll()
				this.$emit("onDeleteRecord")
			},
			err => processError(err, this)
		)
	}

	private showDeleteModal() {
		this.$modal.show(
			DeleteConfirmation,
			{
				targetName: `timesheet record`,
				onDelete: this.onDelete,
			}
		)
	}
}
