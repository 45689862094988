import {Vue} from "vue-property-decorator";
import Application from "@/state/Application";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import Notifications, {NotificationSettings} from "@/state/Notifications";

export function validate(component: Vue): Promise<boolean> {
    const resultArr = [component.$validator.validateAll()];
    if (component.$children.length != 0) {
        component.$children.map(child => validate(child)).forEach(result => resultArr.push(result))
    }
    return Promise.all(resultArr).then(boolArr => {
        let result = true;
        for (let i = 0; i < boolArr.length; i++) {
            result = result && boolArr[i];
        }
        return result;
    })
}

export function ifValid(component: Vue, success: () => void, error: () => void = function () {return;}): void {
    validate(component).then(
        isValid => isValid ? success() : error()
    )
}

export function processError(error: any, vue: Vue, options?: {
    settings?: NotificationSettings,
    callback?: () => void
}) {
    Notifications.error(
        vue.$t(
            ErrorLocalePathUtils.getPathForError(error),
            error?.response?.data?.param ? { param: error?.response?.data?.param } : {}
        ) as string,
        options?.settings,
    )
    if (options?.callback) options.callback
    Application.stopLoading()
}
