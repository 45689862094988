import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
class App extends VuexModule {

    public isMobile = window.innerWidth < 992;

    public loading = false;

    @Mutation
    public sizeChanged(): void {
        this.isMobile = window.innerWidth < 992;
    }

    @Mutation
    public setLoading(loading: boolean){
        this.loading = loading;
    }

    @Action({rawError: true})
    public onResize(){
        this.context.commit('sizeChanged');
    }

    @Action({rawError: true})
    public startLoading(){
        this.context.commit('setLoading', true);
    }

    @Action({rawError: true})
    public stopLoading(){
        this.context.commit('setLoading', false);
    }

}


export default App;