
















import {Component, Prop, Vue} from "vue-property-decorator";
import PortalDate from "@/components/common/PortalDate.vue";
import TerminationPayloadDTO from "@/components/payroll/business/employments/contractors/TerminationPayloadDTO";
import {ifValid} from "@/utils/ComponentUtils";

@Component({
	components: {PortalDate}
})
export default class TerminationModal extends Vue {
	@Prop()
	private applyTerminate!: (payload: TerminationPayloadDTO) => void

	@Prop()
	private terminateId!: number;

	private employmentTermination: Date | null = null;

	private terminate(): void {
		ifValid(this, () => {
			this.applyTerminate(new TerminationPayloadDTO({id: this.terminateId, employmentTermination: this.employmentTermination}))
			this.$modal.hideAll()
		})
	}
}
