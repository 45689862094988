import { render, staticRenderFns } from "./PTOView.vue?vue&type=template&id=dea655ea&scoped=true&"
import script from "./PTOView.vue?vue&type=script&lang=ts&"
export * from "./PTOView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dea655ea",
  null
  
)

export default component.exports