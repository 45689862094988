






































































    import {Component, Vue} from "vue-property-decorator";
    import {namespace} from "vuex-class";
    import AuthService from "@/services/AuthService";
    import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
    import UserDataDTO from "@/dto/UserDataDTO";
    import UserPersonalDataDTO from "@/dto/UserPersonalDataDTO";
    import UserDTO from "@/dto/UserDTO";
    import RouteNames from "@/router/RouteNames";
	import {validate} from "@/utils/ComponentUtils";

    const Auth = namespace("Auth");
    const AppModule = namespace("App");


    @Component
    export default class Login extends Vue {
        private user: any = {email: "", password: ""};
        private message = "";

        private successful = true;

        @Auth.Getter
        private isLoggedIn!: boolean;

        @Auth.Action
        private login!: (data: any) => Promise<any>;

        @Auth.State("user")
        private loggedUser!: UserDataDTO | null

        @Auth.Getter
        private hasAdministrativePrivileges!: boolean;

        private forgotPassword = false;

        private linkSent = false;

        private emailForPasswordReset = "";

        @AppModule.Action
        private startLoading!: () => void

        @AppModule.Action
        private stopLoading!: () => void

        created() {
            if (this.isLoggedIn) {
                this.$router.push("/userprofile");
            }
        }

        handleLogin() {
            this.startLoading();
			validate(this).then((isValid) => {
                if (!isValid) {
                    this.stopLoading();
                    return;
                }

                if (this.user.email && this.user.password) {
                    this.login(this.user).then(
                        (data) => {
                            this.successful = true;
                            this.stopLoading();
                            this.afterLogin();
                        },
                        (error) => {
                            this.successful = false;
                            this.stopLoading();
                            this.message = error;
                        }
                    );
                }
            });
        }

        afterLogin(){
            this.$router.push("/")
        }

        requestPasswordReset(){
            validate(this).then(
                isValid => {
                    if(isValid){
						this.startLoading();
                        AuthService.requestPasswordResetLink(this.emailForPasswordReset).then(
                            response => {
                                this.linkSent = true;
                                this.stopLoading();
                                this.message = this.$t('auth.register_link_sent') as string;
                                this.successful = true;
                                this.forgotPassword = false;
                            },
                            error => {
                                this.successful = false;
                                this.stopLoading();
                                this.linkSent = false;
                                this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
                            }
                        );
                    }
                }
            );
        }

        private toggleResetPasswordWindow(): void {
            this.forgotPassword = !this.forgotPassword;
        }
    }
