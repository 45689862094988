import {
    UpdateVacationCarryoverPayload,
    VacationBalance,
    VacationDto,
} from "@/dto/payroll/VacationDTO";
import QueryUtils from "@/utils/QueryUtils";
import axios from "axios";
import Pageable from "@/dto/Pageable";
import {PAYROLL_SICK_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import {
    CreateSickLeavePayload,
    SickLeave,
    SickLeaveBalance,
    SickLeaveFilter, UpdateSickLeaveBalancePayload
} from "@/components/payroll/sickLeave/SickLeave";

class SickLeaveService {
    getPageByFilter(filter: SickLeaveFilter) {
        const query = QueryUtils.objectToQueryString(filter)
        return axios.get<Pageable<SickLeave>>(`${PAYROLL_SICK_ENDPOINT}${query}`, {headers: authHeader()})
    }

    getSickLeaveBalance(employmentId: number) {
        return axios.get<SickLeaveBalance>(`${PAYROLL_SICK_ENDPOINT}/balance?employmentId=${employmentId}`, {headers: authHeader()})
    }

    createSickLeaveRecord(req: CreateSickLeavePayload) {
        return axios.post<void>(`${PAYROLL_SICK_ENDPOINT}`, req, {headers: authHeader()})
    }

    deleteSickLeaveRecord(sickLeaveId: number, employmentId: number) {
        return axios.delete(`${PAYROLL_SICK_ENDPOINT}?sickLeaveId=${sickLeaveId}&employmentId=${employmentId}`, {headers: authHeader()})
    }

    updateSickLeaveBalance(payload: UpdateSickLeaveBalancePayload) {
        return axios.put(`${PAYROLL_SICK_ENDPOINT}/balance`, payload, {headers: authHeader()})
    }
}

export default new SickLeaveService()