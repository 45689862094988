import {IEmployment} from "@/dto/payroll/IEmployment";
import {Account} from "@/dto/Account";

export class VacationFilter {
    startDate: Date | string | null = null;
    endDate: Date | string | null = null;
    employmentsId: Array<number> = [];
    employerId: number | null = null
    page = 0;
    size = 20;

    constructor(init?: Partial<VacationFilter>) {
        Object.assign(this, init)
    }
}

export class VCRFilter {
    employerId: number | null = null;
    employmentsId: Array<number> | null = null;
    status: VCRStatus | null = null
    page = 0;
    size = 20;

    constructor(init?: Partial<VCRFilter>) {
        Object.assign(this, init)
    }
}

export class VacationDto {
    id!: number
    startDate!: Date
    endDate!: Date
    startTime!: Date
    endTime!: Date
    duration!: string
    paid!: boolean
    employment!: IEmployment
    startDateTime!: string
    endDateTime!: string
}

export class CreateVacationPayload {
    startDate: Date | null = null;
    endDate: Date | null = null;
    startTime: Date | string | null = null;
    endTime: Date | string | null = null;
    employmentId: number | null = null;
    isPaid = false

    constructor(init?: Partial<CreateVacationPayload>) {
        Object.assign(this, init)
    }
}

export class CreateVCRequestPayload extends CreateVacationPayload{
    employeeComment: string | null = null;

    constructor(init?: Partial<CreateVCRequestPayload>) {
        super(init)
    }
}

export class VacationBalance {
    id!: number
    balance!: number
    totalBalanceHours!: number
    totalBalanceDays!: number
    duration!: string
}

export class UpdateVacationCarryoverPayload {
    employmentId: number | null = null;
    balance: number | null = null;

    constructor(init?: Partial<UpdateVacationCarryoverPayload>) {
        Object.assign(this, init)
    }
}

export enum VCRStatus {
    REQUESTED = "REQUESTED",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    CANCELLED = "CANCELLED",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
}

export class VCR {
    id!: number
    status!: VCRStatus
    requester!: IEmployment
    employeeComment: string | null = null
    assigneeComment: string | null = null
    vacation!: VacationDto
    assignee!: Account
    createDate!: Date
}

export class ChangeStatusVCRRequestPayload {
    requestId: number | null = null;
    status: VCRStatus | null = null;
    employmentId: number | null = null;

    constructor(init?: Partial<ChangeStatusVCRRequestPayload>) {
        Object.assign(this, init)
    }
}

export class RejectRequestPayload {
    requestId: number | null = null;
    companyId: number | null = null;
    comment: string | null = null;

    constructor(init?: Partial<RejectRequestPayload>) {
        Object.assign(this, init)
    }
}

export class ApproveRequestPayload {
    requestId: number | null = null;
    companyId: number | null = null;
    comment: string | null = null;

    constructor(init?: Partial<ApproveRequestPayload>) {
        Object.assign(this, init)
    }
}

export class VacationBalanceDetails {
    id!: number;
    employment!: IEmployment;
    positive!: boolean;
    duration!: string;
    createDate!: Date;
}

export class VacationBalanceDetailForPeriod {
    data!: Array<VacationBalanceDetails>;
    totalPages!: number;
    balance!: number
}

export class VacationBalanceDetailsFilter {
    employmentId: number | null = null;
    startDate: Date | null = null;
    endDate: Date | null = null;
    page = 0;
    size = 20;

    constructor(init?: Partial<VacationBalanceDetailsFilter>) {
        Object.assign(this, init)
    }
}