
























import {Component, Prop, Vue} from "vue-property-decorator";
import moment from "moment/moment";
import PortalDate from "@/components/common/PortalDate.vue";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import {processError} from "@/utils/ComponentUtils";
import Application from "@/state/Application";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PortalTime from "@/components/common/PortalTime.vue";
import {
	TimeSheetFilter,
	TimeSheetPayPeriod,
} from "@/dto/payroll/timesheet/TimesheetPayloads";
import {prettyEnum} from "@/utils/StringUtils";
import EmploymentService from "@/services/EmploymentService";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import {PayType} from "@/constants/TarifficationConstants";
import RouteNames from "@/router/RouteNames";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {Moment} from "moment-timezone";
import {CounterpartyType} from "@/constants/CounterpartyType";
import TimesheetService from "@/services/payroll/TimesheetService";
import {v4 as uuidv4} from "uuid";
import TimeSheetPeriod from "@/components/payroll/timesheet/TimeSheetPeriod.vue";
import TimesheetReportCreationModal from "@/components/payroll/timesheet/TimesheetReportCreationModal.vue";

@Component({
	computed: {
		PayType() {
			return PayType
		}
	},
	methods: {
		RouteNames() {
			return RouteNames
		},
		prettyEnum,
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		}
	},
	components: {TimeSheetPeriod, PortalSelect, PaginationComponent, PortalTime, BTPortalCollapse, PortalDate}
})
export default class BusinessTimesheet extends Vue {

	private id = uuidv4()

	@Prop()
	private timeFormat!: string

	private employmentIdArr: Array<number> = [];

	private timesheetPeriods: Array<TimeSheetPayPeriod> = [];

	get timesheetFilter(): TimeSheetFilter {
		return new TimeSheetFilter(
			{
				startDate: moment().set({weekday: 0}).format("YYYY-MM-DD"),
				endDate: moment().set({weekday: 6}).format("YYYY-MM-DD"),
				employmentsId: this.employmentIdArr
			}
		)
	}

	private employmentFilter: EmploymentFilter = new EmploymentFilter(
		{
			types: ["STAFF", "FOREIGN"],
			contractorType: CounterpartyType.PERSON,
			employerId: this.$wss.getCurrent.id,
			status: [EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS, EmploymentStatus.NEW]
		}
	)

	public created(): void {
		Application.startLoading();
		this.loadEmploymentIds().then(
			() => {
				if (this.employmentIdArr.length > 0) this.loadTimesheetRecords()
			},
			err => processError(err, this)
		)
			.then(Application.stopLoading)
	}

	private async loadEmploymentIds() {
		return EmploymentService.getAllByFilter(this.employmentFilter).then(
			res => {
				this.employmentIdArr = res.data.map(it => it.id!)
			},
			err => processError(err, this)
		)
	}

	private async loadTimesheetRecords() {
		TimesheetService.getPayPeriod(this.timesheetFilter).then(
			res => {
				this.timesheetPeriods = res.data
			},
			err => processError(err, this)
		)
	}

	private showReportModal() {
		this.$modal.show(
			TimesheetReportCreationModal,
			{
				filter: this.timesheetFilter
			}
		)
	}

}

