








































































import {Vue, Component, Prop} from 'vue-property-decorator';
import PortalInput from "@/components/common/PortalInput.vue";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import {PAY_PERIOD_TYPE_OPTIONS, PAY_TYPE_OPTIONS, PayPeriodType, PayType} from "@/constants/TarifficationConstants";
import {contractTypeOptions} from "@/constants/EmploymentType";
import TarifficationDto, {UpdateTarifficationPayload} from "@/dto/payroll/Tariffication";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import {prettyEnum} from "@/utils/StringUtils";
import {Week} from "@/constants/DateTimeConstants";
import {ifValid, processError} from "@/utils/ComponentUtils";
import TarifficationService from "@/services/TarifficationService";
import {DateTime} from "luxon";
import Application from "@/state/Application";
import Notifications from "@/state/Notifications";
import {TariffedEmployment} from "@/dto/payroll/IEmployment";
import {EmploymentVue} from "@/components/payroll/business/employments/EmploymentVue";

@Component({
	methods: {contractTypeOptions: contractTypeOptions},
	computed: {
		DateTime() {
			return DateTime
		},
		PayPeriodType() {
			return PayPeriodType
		},
		PayType() {
			return PayType
		},
		PAY_TYPE_OPTIONS() {
			return PAY_TYPE_OPTIONS
		},
		PAY_PERIOD_TYPE_OPTIONS() {
			return PAY_PERIOD_TYPE_OPTIONS
		}
	},
	components: {PortalSelect, PortalRadio, PortalInput}
})
export default class Compensation extends EmploymentVue<TariffedEmployment>{
	@Prop()
	employment!: TariffedEmployment;

	private tariffication: TarifficationDto = this.employment.tariffication

	private updatePayload: UpdateTarifficationPayload = new UpdateTarifficationPayload(
		{
			payType: this.tariffication.payType,
			payPeriodType: this.tariffication.payPeriodType,
			rate: this.tariffication.rate,
			taxHolderId: this.employment.id!,
			overtimeRate: this.tariffication.overtimeRate,
			payPeriodStart: this.tariffication.payPeriodStart,
			payPeriodEnd: this.tariffication.payPeriodEnd,
			zoneId: this.tariffication.zoneId,
			contractType: this.tariffication.contractType
		}
	);

	private buildWeekDayOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title(prettyEnum(Week.SUNDAY)).value(Week.SUNDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.MONDAY)).value(Week.MONDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.TUESDAY)).value(Week.TUESDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.WEDNESDAY)).value(Week.WEDNESDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.THURSDAY)).value(Week.THURSDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.FRIDAY)).value(Week.FRIDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.SATURDAY)).value(Week.SATURDAY).build(),
		]
	}

	private update(): void {
		this.updatePayload.rate = Number(this.updatePayload.rate.toString().replaceAll(",", "."))
		this.updatePayload.overtimeRate = Number(this.updatePayload.overtimeRate.toString().replaceAll(",", "."))
		ifValid(this, () => {
			Application.startLoading();
			TarifficationService.updateTarifficationInfo(this.updatePayload).then(
				() => {
					Notifications.success("Compensation data has been successfully updated!");
					Application.stopLoading()
				},
				err => processError(err, this)
			)
		})
	}
}
