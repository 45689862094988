import CompanyDTO from "@/dto/company/CompanyDTO";
import PersonDTO from "@/dto/person/PersonDTO";
import {EmploymentType} from "@/constants/EmploymentType";
import {
    EmploymentStatus,
} from "@/components/payroll/business/employments/contractors/Contractor";
import {IEmployment, ProfileRoute, TariffedEmployment} from "@/dto/payroll/IEmployment";
import BranchDto from "@/components/profile/branches/BranchDtos";
import AddressDTO from "@/dto/AddressDTO";
import TarifficationDto from "@/dto/payroll/Tariffication";
import {ProjectDTO} from "@/dto/payroll/ProjectDTOs";
import Projects from "@/components/payroll/business/Projects.vue";
import RouteNames from "@/router/RouteNames";

export class UpdateEmployeePayload {
    id: number | null = null
    employmentStart: Date | null = null;
    branches: Array<BranchDto> = [];
    workplaces: Array<AddressDTO> = [];
    remote = true;
    projectsId: number[] = [];
    officer = false

    constructor(init?: Partial<UpdateEmployeePayload>) {
        Object.assign(this, init)
    }
}

export default class EmployeeDto implements IEmployment, TariffedEmployment {
    public id: number | null = null;

    public employer!: CompanyDTO;

    public officer = false

    public employmentStart: Date | null = null;

    public employmentTermination: Date | null = null;

    public details: PersonDTO = new PersonDTO();

    public remote = true

    public employmentType: EmploymentType | null = null;

    public status: EmploymentStatus = EmploymentStatus.NEW;

    public branches: Array<BranchDto> = []

    public workplaces: Array<AddressDTO> = [];

    public tariffication!: TarifficationDto;

    public projects: ProjectDTO[] = []

    public route!: ProfileRoute;

    constructor(init? : Partial<EmployeeDto>) {
        Object.assign(this, init)
        this.route = {
            name: RouteNames.PAYROLL_COMPANY_EMPLOYEE_DETAILS,
            params: {
                employeeId: this.id
            }
        };
    }

}