





































































import {Component, Vue} from "vue-property-decorator";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import BusinessContractorService from "@/components/payroll/business/employments/contractors/BusinessContractorService";
import {BusinessContractorDTO} from "@/components/payroll/business/employments/contractors/Contractor";
import {namespace} from "vuex-class";
import RouteNames from "@/router/RouteNames";
import {WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import TerminationModal from "@/components/payroll/business/employments/contractors/TerminationModal.vue";
import TerminationPayloadDTO from "@/components/payroll/business/employments/contractors/TerminationPayloadDTO";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {ifValid} from "@/utils/ComponentUtils";
import {EmploymentVue} from "@/components/payroll/business/employments/EmploymentVue";

const AppModule = namespace("App");

@Component({
	computed: {
		WorkspaceTypePathElement() {
			return WorkspaceTypePathElement
		}
	},
	methods: {
		RouteNames() {
			return RouteNames
		}
	},
	components: {PortalDate, PortalInput}
})
export default class BusinessContractor extends EmploymentVue<BusinessContractorDTO> {

	private contractorDetails = new BusinessContractorDTO();

	private message: string | null = null;

	private successful = false;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	public mounted() {
		this.loadContractor();
	}

	private loadContractor() {
		this.startLoading();
		BusinessContractorService.getContractorById(Number.parseInt(this.$route.params.contractorId, 10)).then(
			res => {
				this.contractorDetails = res.data;
				this.stopLoading()
			},
			err => this.processError(err)
		)
	}

	private openTerminateModal():void {
		this.$modal.show(
			TerminationModal,
			{
				terminateId: this.contractorDetails.id,
				applyTerminate: (payload: TerminationPayloadDTO) => this.terminate(payload)
			},
			{
				height: "auto"
			}
		)
	}

	private terminate(payload: TerminationPayloadDTO): void {
		this.startLoading();
		BusinessContractorService.terminateContractor(payload).then(
			() => {
				this.successful = true;
				this.message = "The contractor's contract has been terminated"
				this.$router.push({name: RouteNames.HR_CONTRACTORS, params: {companyId: String(this.contractorDetails.employer?.id)}})
				this.stopLoading()
			},
			err => this.processError(err)
		)
	}

	private deleteContractor(): void {
		this.startLoading()
		BusinessContractorService.deleteContractor(this.contractorDetails.id as number).then(
			() => {
				this.stopLoading()
				this.$router.push({name: RouteNames.HR_CONTRACTORS, params: {companyId: String(this.contractorDetails.employer?.id)}})
			},
			err => this.processError(err)
		)
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}

	private update(): void {
		ifValid(this, () => {
			this.startLoading()
			BusinessContractorService.updateContractor(this.contractorDetails).then(
				() => {
					this.successful = true;
					this.message = "The contractor's data has been updated"
					this.stopLoading()
				},
				err => this.processError(err)
			)
		})
	}
}

