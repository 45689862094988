

























































































































































import {Component, Prop} from 'vue-property-decorator';
import {IEmployment} from "@/dto/payroll/IEmployment";
import {
	VacationBalance,
	VacationBalanceDetails,
	VacationBalanceDetailsFilter,
	VacationDto,
	VacationFilter,
	VCR,
	VCRFilter
} from "@/dto/payroll/VacationDTO";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import moment, {Moment} from "moment-timezone";
import {prettyEnum} from "@/utils/StringUtils";
import VacationCreationModal from "@/components/payroll/vacation/VacationCreationModal.vue";
import VacationOptionsModal from "@/components/payroll/vacation/VacationOptionsModal.vue";
import VacationBalanceEditModal from "@/components/payroll/vacation/VacationBalanceEditModal.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import VacationService from "@/services/payroll/VacationService";
import PortalDate from "@/components/common/PortalDate.vue";
import {EmploymentVue} from "@/components/payroll/business/employments/EmploymentVue";
import {WorkspaceType} from "@/dto/auth/Workspace";
import VacationRequestCreationModal from "@/components/payroll/vacation/VacationRequestCreationModal.vue";
import VacationRequestEditor from "@/components/payroll/vacation/VacationRequestEditor.vue";
import VacationRequestView from "@/components/payroll/vacation/VacationReaquestView.vue";

@Component({
	computed: {
		WorkspaceType() {
			return WorkspaceType
		}
	},
	components: {PortalDate, PaginationComponent},
	methods: {
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		},
		prettyEnum
	}
})
export default class EmploymentVacationList extends EmploymentVue<IEmployment> {

	private vacations: Array<VacationDto> = [];
	private balanceDetails: Array<VacationBalanceDetails> = []
	private balance: number | null = null;
	private vcrs: Array<VCR> = [];
	private totalPages = 0
	private reqTotalPages = 0
	private detailsPages = 0

	@Prop()
	employment!: IEmployment

	private balanceDetailsFilter = new VacationBalanceDetailsFilter(
		{
			employmentId: this.employment.id
		}
	)

	private filter = new VacationFilter(
		{
			employmentsId: [this.employment.id!],
		}
	)

	private reqFilter = new VCRFilter(
		{
			employmentsId: [this.employment.id!],
		}
	)

	private loadVacations() {
		Application.startLoading()
		return VacationService.getPageOfVacations(this.filter).then(
			res => {
				this.vacations = res.data.data
				this.totalPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private loadRequests() {
		Application.startLoading()
		return VacationService.getPageOfVacationRequests(this.reqFilter).then(
			res => {
				this.vcrs = res.data.data
				this.reqTotalPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created() {
		this.loadVacations()
			.then(() => this.loadRequests())
			.then(() => this.loadBalanceDetails())
			.then(() => this.loadAvailableBalance())
	}

	private showCreationWindow() {
		this.$modal.show(
			VacationCreationModal,
			{
				employment: this.employment,
				callback: () => this.loadVacations()
			}
		)
	}

	private showOptions(vacation: VacationDto) {
		this.$modal.show(
			VacationOptionsModal,
			{
				vacation: vacation,
				callback: () => this.loadVacations()
			}
		)
	}


	private showCarryoverModal() {
		this.$modal.show(
			VacationBalanceEditModal,
			{
				employment: this.employment,
				callback: () => this.loadVacations()
			}
		)
	}

	private loadAvailableBalance() {
		Application.startLoading()
		return VacationService.getVacationBalance(this.employment.id!).then(
			res => {
				this.balance = res.data.totalBalanceDays;
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private changePage(page: number){
		this.filter.page = page - 1;
		this.loadVacations();
	}

	private changeReqPage(page: number){
		this.reqFilter.page = page - 1;
		this.loadRequests();
	}

	private changeBalanceDetailsPage(page: number) {
		this.balanceDetailsFilter.page = page - 1;
		if (this.balanceDetailsFilter.startDate || this.balanceDetailsFilter.endDate) {
			this.loadBalanceDetailsForPeriod()
		} else {
			this.loadBalanceDetails()
		}
	}

	private loadBalanceDetails() {
		Application.startLoading();
		return VacationService.getBalanceDetails(this.balanceDetailsFilter).then(
			res => {
				this.balanceDetails = res.data.data
				this.detailsPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private reset() {
		this.filter = new VacationFilter(
			{
				employerId: this.$wss.getCurrent.id,
				startDate: moment().format("YYYY-MM-DD")
			}
		)
		this.loadVacations()
	}

	private showRequestModal() {
		this.$modal.show(
			VacationRequestCreationModal,
			{
				employment: this.employment,
				callback: () => this.loadRequests()
			}
		)
	}

	private showRequestDetail(vcr: VCR) {
		if (this.$wss.isUserSublimeEmployee || this.$wss.getCurrent.type == WorkspaceType.COMPANY) {
			this.$modal.show(
				VacationRequestEditor,
				{
					vcr: vcr,
					employment: this.employment,
					callback: () => this.loadRequests()
				}
			)
		}
		else {
			this.$modal.show(
				VacationRequestView,
				{
					vcr: vcr,
					employment: this.employment,
					callback: () => this.loadRequests()
				}
			)
		}
	}

	private loadBalanceDetailsForPeriod() {
		Application.startLoading()
		VacationService.getBalanceDetailsForPeriod(this.balanceDetailsFilter)
			.then(
				res => {
					this.balanceDetails = res.data.data
					this.totalPages = res.data.totalPages
					this.balance = res.data.balance
					Application.stopLoading()
				},
				err => processError(err, this)
			)
	}

	private resetBalanceDetailsForPeriod() {
		this.balanceDetailsFilter = new VacationBalanceDetailsFilter(
			{
				employmentId: this.employment.id,
			}
		)
		this.balance = null;
		this.loadAvailableBalance().then(
			() => this.loadBalanceDetails()
		)
	}

}
