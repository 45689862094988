import axios from "axios";
import {INDIVIDUAL_FOREIGN_CONTRACTOR_SERVICE} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import TerminationPayloadDTO from "@/components/payroll/business/employments/contractors/TerminationPayloadDTO";
import {
    IndividualForeignContractorDTO, UpdateIndividualForeignContractorDetailsPayload
} from "@/components/payroll/business/employments/foreignContractors/ForeignContractorPayloads";

class IndividualForeignContractorService {
    public getContractorById(contractorId: number) {
        return axios.get<IndividualForeignContractorDTO>(`${INDIVIDUAL_FOREIGN_CONTRACTOR_SERVICE}/${contractorId}`, {headers: authHeader()})
    }

    public terminateContractor(payload: TerminationPayloadDTO) {
        return axios.put(`${INDIVIDUAL_FOREIGN_CONTRACTOR_SERVICE}/terminate`, payload, {headers: authHeader()})
    }

    public deleteContractor(contractorId: number) {
        return axios.delete(`${INDIVIDUAL_FOREIGN_CONTRACTOR_SERVICE}/${contractorId}`, {headers: authHeader()})
    }

    public updateContractor(payload: UpdateIndividualForeignContractorDetailsPayload) {
        return axios.put(`${INDIVIDUAL_FOREIGN_CONTRACTOR_SERVICE}`, payload, {headers: authHeader()})
    }
}

export default new IndividualForeignContractorService();