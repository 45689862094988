import {OrderDirection} from "@/dto/report/ReportFilter";
import {CounterpartyType} from "@/constants/CounterpartyType";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";

export class EmploymentFilter {
    name: string | null = null;
    employerId: number | null = null;
    detailsId: number | null = null;
    types: Array<"STAFF" | "CONTRACTOR" | "FOREIGN"> = [];
    contractorType: CounterpartyType | null = null
    status: Array<EmploymentStatus> | null = null
    pageNumber = 0;
    pageSize = 20;
    orderField = EmploymentOrderField.EMPLOYMENT_START;
    orderDirection = OrderDirection.DESC;

    constructor(init?: Partial<EmploymentFilter>) {
        Object.assign(this, init);
    }
}

export enum EmploymentOrderField {
    EMPLOYMENT_START = "EMPLOYMENT_START",
    EMPLOYMENT_TERMINATION = "EMPLOYMENT_TERMINATION"
}

export class EmployeeFilter extends EmploymentFilter {
    constructor(init?: Partial<EmploymentFilter>) {
        super(init)
        this.types = ["STAFF"]
    }
}

export class ContractorFilter extends EmploymentFilter {
    contractorType: CounterpartyType | null = null
    constructor(init?: Partial<EmploymentFilter>) {
        super(init)
        if (init && init.contractorType) {
            this.contractorType = init.contractorType
        }
        this.types = ["CONTRACTOR"]
    }
}

export class ForeignContractorFilter extends EmploymentFilter {
    contractorType: CounterpartyType | null = null
    constructor(init?: Partial<EmploymentFilter>) {
        super(init)
        if (init && init.contractorType) {
            this.contractorType = init.contractorType
        }
        this.types = ["FOREIGN"]
    }
}
