import axios, {AxiosResponse} from 'axios';
import {
    COMPANY_ENDPOINT,
    PERSON_ACCOUNT_ENDPOINT,
    PERSON_ADMIN_ENDPOINT,
    PERSON_BATCH_ENDPOINT,
    PERSON_CUSTOMERS_ENDPOINT,
    PERSON_CUSTOMERS_PAGES_ENDPOINT,
    PERSON_DETAILS_ENDPOINT, PERSON_EAD_ENDPOINT,
    PERSON_EMPLOYEE_ENDPOINT,
    PERSON_EMPLOYEES_ENDPOINT,
    PERSON_ENDPOINT, PERSON_FOREIGN_ID_ENDPOINT, PERSON_GREENCARD_ENDPOINT, PERSON_ID_ENDPOINT,
    PERSON_MARITAL_STATUS_ENDPOINT, PERSON_PASSPORT_ENDPOINT, PERSON_RESIDENCY_STATUS_ENDPOINT, PERSON_SSN_ENDPOINT,
    PERSON_XLSX_ENDPOINT
} from '@/constants/endpoints'
import authHeader from "@/services/auth-header";
import PersonDTO from "@/dto/person/PersonDTO";
import QueryUtils from "@/utils/QueryUtils";
import CollectionUtils from "@/utils/CollectionUtils";
import PersonFilterDTO from "@/dto/person/PersonFilterDTO";
import Pageable from "@/dto/Pageable";
import AddressDTO from "@/dto/AddressDTO";
import UpdateAddressRequest from "@/dto/UpdateAddressRequest";
import EditMaritalStatusRequest from "@/dto/person/EditMaritalStatusRequest";
import IdCardDto from "@/dto/person/IdCardDto";
import SaveIdCardRequest from "@/dto/person/SaveIdCardRequest";
import CreatePersonRequestDTO from "@/dto/person/CreatePersonRequestDTO";
import UpdatePersonDetailsRequestDTO from "@/dto/person/UpdatePersonDetailsRequestDTO";
import MoveCustomerOnAnotherAccountRequest from "@/dto/auth/MoveCustomerOnAnotherAccountRequest";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import BankAccountDTO from "@/dto/BankAccountDTO";
import AddEADPayload from "@/dto/person/AddEADPayload";
import AddStateIDPayload from "@/dto/person/AddStateIDPayload";
import StateID_DTO from "@/dto/person/StateIdDTO";
import {EAD_DTO} from "@/dto/person/EAD_DTO";
import {GreenCardDTO} from "@/dto/person/GreenCardDTO";
import AddGreenCardPayload from "@/dto/person/AddGreenCardPayload";
import AddCitizenPassportPayload from "@/dto/person/AddCitizenPassportPayload";
import {CitizenPassportDTO} from "@/dto/person/CitizenPassportDTO";
import SaveSSNCardPayload from "@/dto/person/SaveSSNCardPayload";
import {ResidencyStatus} from "@/constants/PersonConstants";
import StateIdDTO from "@/dto/person/StateIdDTO";
import {ExpirableDocumentType} from "@/constants/DocumentConstants";
import AddForeignIDPayload from "@/dto/person/AddForeignIDPayload";
import ForeignID_DTO from "@/dto/person/ForeignID_DTO";


class PersonService {

    getById(id: number) {
        return axios.get<PersonDTO>(`${PERSON_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    batchUpload(fileId: number) {
        return axios.post(`${PERSON_BATCH_ENDPOINT}/${fileId}`, {}, {headers: authHeader()})
    }

    getAddressById(id: number) {
        return axios.get<AddressDTO>(`${PERSON_ENDPOINT}/${id}/address`, {headers: authHeader()});
    }

    getEmployeesForCategory(employerId: number, category: string, employeeFullName: string | null) {
        const queryString = QueryUtils.toQueryString(
            CollectionUtils.createPathParamsMap(
                ["employerId", employerId],
                ["category", category],
                ["employeeFullName", employeeFullName]));
        return axios.get<Array<PersonDTO>>(`${PERSON_EMPLOYEES_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    getEmployee(employeeId: number, employerId: number) {
        const queryString = QueryUtils.toQueryString(
            CollectionUtils.createPathParamsMap(
                ['employeeId', employeeId],
                ['employerId', employerId]));
        return axios.get<PersonDTO>(`${PERSON_EMPLOYEE_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    loadCustomers() {
        return axios.get<Array<PersonDTO>>(`${PERSON_CUSTOMERS_ENDPOINT}`, {headers: authHeader()});
    }

    loadCustomersPages() {
        return axios.get<number>(`${PERSON_CUSTOMERS_PAGES_ENDPOINT}`, {headers: authHeader()});
    }

    getByFilter(filter: PersonFilterDTO) {
        const queryString = QueryUtils.objectToQueryString(filter);
        return axios.get<Pageable<PersonDTO>>(`${PERSON_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    saveAddress(request: UpdateAddressRequest) {
        return axios.put(`${PERSON_ENDPOINT}/address`, request, {headers: authHeader()});
    }

    editMaritalStatus(request: EditMaritalStatusRequest) {
        return axios.put(PERSON_MARITAL_STATUS_ENDPOINT, request, {headers: authHeader()});
    }

    getPersonalIdCard(id: number) {
        return axios.get<IdCardDto>(`${PERSON_ENDPOINT}/${id}/id_card`, {headers: authHeader()});
    }

    uploadPersonalIdCard(req: SaveIdCardRequest) {
        return axios.post(`${PERSON_ENDPOINT}/id_card`, req, {headers: authHeader()});
    }

    registerCustomer(req: CreatePersonRequestDTO) {
        return axios.post<PersonDTO>(`${PERSON_ENDPOINT}`, req, {headers: authHeader()});
    }

    registerPersonEntityOnExistedEmail(req: CreatePersonRequestDTO) {
        return axios.put(`${PERSON_ENDPOINT}`, req, {headers: authHeader()});
    }

    registerAdmin(req: CreatePersonRequestDTO) {
        return axios.post(`${PERSON_ADMIN_ENDPOINT}`, req, {headers: authHeader()});
    }

    updatePersonDetails(req: UpdatePersonDetailsRequestDTO) {
        return axios.put(`${PERSON_DETAILS_ENDPOINT}`, req, {headers: authHeader()});
    }

    moveToAnotherAccount(req: MoveCustomerOnAnotherAccountRequest) {
        return axios.put(`${PERSON_ACCOUNT_ENDPOINT}`, req, {headers: authHeader()});
    }

    exportToExcel(filter: PersonFilterDTO) {
        const queryString = QueryUtils.buildQueryString(
            ['firstName', filter.firstName],
            ['lastName', filter.lastName],
            ['email', filter.email],
            ['ssn', filter.ssn],
            ['orderField', filter.orderField],
            ['orderDirection', filter.orderDirection]);
        return axios.get<FileMetaDTO>(`${PERSON_XLSX_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    getBankAccounts(id: number) {
        const queryString = QueryUtils.buildQueryString(
            ["onlyVerified", false]
        )
        return axios.get<Array<BankAccountDTO>>(`${PERSON_ENDPOINT}/${id}/bank_accounts${queryString}`, {headers: authHeader()})
    }

    addBankAccount(account: BankAccountDTO) {
        return axios.post(`${PERSON_ENDPOINT}/${account.personId}/bank_accounts`, account, {headers: authHeader()})
    }

    getBankAccountById(id: number | null, personId: number): Promise<AxiosResponse<BankAccountDTO>>  {
        return axios.get<BankAccountDTO>(`${PERSON_ENDPOINT}/${personId}/bank_accounts/${id}`, {headers: authHeader()})
    }

    updateBankAccount(account: BankAccountDTO): Promise<AxiosResponse<void>> {
        return axios.put(`${PERSON_ENDPOINT}/${account.personId}/bank_accounts`, account, {headers: authHeader()})
    }

    verify(bankAccountId: number | null, verified: boolean) {
        return axios.put<void>(`${PERSON_ENDPOINT}/bank_accounts/verify`, {isVerified: verified, bankAccountId: bankAccountId}, {headers: authHeader()})
    }

    deleteAchForm(id: number | null) {
        return axios.delete<void>(`${PERSON_ENDPOINT}/bank_accounts/${id}/ach`, {headers:authHeader()})
    }

    getIsClientStatus(id: number) {
        return axios.get<boolean>(`${PERSON_ENDPOINT}/${id}/isClient`, {headers:authHeader()})
    }

    setIsClientStatus(id: number, isClient: boolean) {
        return axios.put<void>(`${PERSON_ENDPOINT}/isClient`, {id: id, isClient: isClient}, {headers:authHeader()})
    }

    addEAD(payload: AddEADPayload){
        return axios.post(PERSON_EAD_ENDPOINT, payload, {headers: authHeader()});
    }

    editEAD(payload: EAD_DTO){
        return axios.put(PERSON_EAD_ENDPOINT, payload, {headers: authHeader()});
    }

    getEAD(personId: number){
        const queryString = QueryUtils.buildQueryString(
            ["personId", personId]
        );
        return axios.get<Array<EAD_DTO>>(PERSON_EAD_ENDPOINT + queryString, {headers: authHeader()});
    }

    addGreenCard(payload: AddGreenCardPayload){
        return axios.post(PERSON_GREENCARD_ENDPOINT, payload, {headers: authHeader()});
    }

    editGreenCard(payload: GreenCardDTO){
        return axios.put(PERSON_GREENCARD_ENDPOINT, payload, {headers: authHeader()});
    }

    getGreenCard(personId: number){
        const queryString = QueryUtils.buildQueryString(
            ["personId", personId]
        );
        return axios.get<Array<GreenCardDTO>>(PERSON_GREENCARD_ENDPOINT + queryString, {headers: authHeader()});
    }


    addCitizenPassport(payload: AddCitizenPassportPayload){
        return axios.post(PERSON_PASSPORT_ENDPOINT, payload, {headers: authHeader()});
    }

    editCitizenPassport(payload: CitizenPassportDTO){
        return axios.put(PERSON_PASSPORT_ENDPOINT, payload, {headers: authHeader()});
    }

    getCitizenPassport(personId: number){
        const queryString = QueryUtils.buildQueryString(
            ["personId", personId]
        );
        return axios.get<Array<CitizenPassportDTO>>(PERSON_PASSPORT_ENDPOINT + queryString, {headers: authHeader()});
    }

    addStateId(payload: AddStateIDPayload){
        return axios.post(PERSON_ID_ENDPOINT, payload, {headers: authHeader()});
    }

    editStateId(payload: StateIdDTO){
        return axios.put(PERSON_ID_ENDPOINT, payload, {headers: authHeader()});
    }

    getStateId(personId: number){
        const queryString = QueryUtils.buildQueryString(
            ["personId", personId]
        );
        return axios.get<Array<StateID_DTO>>(PERSON_ID_ENDPOINT + queryString, {headers: authHeader()});
    }

    saveSSNCard(payload: SaveSSNCardPayload){
        return axios.put(PERSON_SSN_ENDPOINT, payload, {headers: authHeader()});
    }

    changeResidencyStatus(personId: number, status: ResidencyStatus){
        return axios.put(`${PERSON_RESIDENCY_STATUS_ENDPOINT}/${personId}`, {value: status}, {headers: authHeader()});
    }

    deletePersonalDocument(personId: number, documentType: ExpirableDocumentType, documentId: number){
        return axios.delete(`${PERSON_ENDPOINT}/${personId}/documents/${documentType}/${documentId}`, {headers: authHeader()});
    }

    addForeignID(personId: number, payload: AddForeignIDPayload){
        return axios.post(`${PERSON_FOREIGN_ID_ENDPOINT}/${personId}`, payload, {headers: authHeader()});
    }

    getForeignId(personId: number){
        return axios.get<Array<ForeignID_DTO>>(`${PERSON_FOREIGN_ID_ENDPOINT}/${personId}`, {headers: authHeader()});
    }

}


export default new PersonService();