




















import {Prop, Vue} from "vue-property-decorator";
import {Component} from "vue-property-decorator";
import PortalInput from "@/components/common/PortalInput.vue";
import {CreateProjectPayload} from "@/dto/payroll/ProjectDTOs";
import Workspaces from "@/state/Workspaces";
import Application from "@/state/Application";
import CompanyService from "@/services/CompanyService";
import Notifications from "@/state/Notifications";
import {ifValid, processError} from "@/utils/ComponentUtils";

@Component({
	components: {PortalInput}
})
export default class ProjectCreationComponent extends Vue {

	@Prop()
	private onSuccess!: () => any

	private createReq = new CreateProjectPayload()

	public created() {
		this.createReq.companyId = Workspaces.getCurrent.id
	}

	private createProject(): void {
		ifValid(this, () => {
			Application.startLoading()
			CompanyService.createProject(this.createReq).then(
				() => {
					Notifications.success('The project has been successfully created!')
					Application.stopLoading()
					this.$modal.hideAll()
					this.onSuccess()
				},
				err => processError(err, this)
			)
		})
	}
}
