















import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import StringMask from '@pedrobslisboa/js-string-mask';


@Component
export default class PortalTextarea extends Vue {
  @VModel({type: String})
  private inputValue!: string;

  @Prop({default: null})
  private name!: string | null;

  @Prop({default: null})
  private id!: string | null;

  @Prop({default: null})
  private hint!: string | null;

  @Prop()
  private label!: string;

  @Prop({default: null})
  private maxLength!: number | null;

  @Prop({default: 5})
  private rows!: number;

  @Prop({default: null})
  private cols!: number | null;

  @Prop({default: ''})
  private placeholder!: string;

  @Prop({default: ''})
  private validatePattern!: string;

  /**
   * Token -> Description
   * 0 -> Int
   * A -> Letter
   * S -> Letter or Int
   * * -> Any char
   * { -> Start escape group
   * } -> End escape group
   * [ -> Start recursive group
   * ] -> End recursive group
   * $ -> Escape next char
   */
  @Prop({default: null})
  private mask!: string | null;

  private processInput(target: any): void {
    if (this.mask) {
      this.processMask(target);
    } else if (this.maxLength) {
      this.processInput(target);
    }
  }

  private processMaxLength(target: any) {
    if (this.maxLength !== null && target.value.length >= this.maxLength) {
      target.value = target.value.substr(0, this.maxLength);
      this.$emit('input', target.value)
    }
  }

  private processMask(target: any) {
    const formatter = new StringMask({mask: this.mask as string})
    formatter.apply(target.value)
    target.value = formatter.maskedValue;
    this.$emit('input', formatter.maskedValue)
  }

  get isRequired(): boolean {
    return this.validatePattern?.includes("required");
  }

}
