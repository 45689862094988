
















































































































import { register } from "vue-advanced-chat";
import { Component, Vue } from "vue-property-decorator";
import MessageDTO from "@/dto/chat/MessageDTO";
import RoomDTO from "@/dto/chat/RoomDTO";
import { namespace } from "vuex-class";
import Workspace from "@/dto/auth/Workspace";
import ParticipantDTO from "@/dto/chat/ParticipantDTO";
import ChatService from "@/services/ChatService";
import moment from "moment";
import { documentAccept } from "@/constants/FileConstants";
import CreateChatRequest from "@/dto/chat/CreateChatRequest";
import { getCustomerUUID } from "@/services/auth-header";
import FileService from "@/services/FileService";
import AttachFile from "@/dto/chat/AttachFile";
import session from "@/store/modules/session.module";
import { MessageEvent } from "@/constants/MessageEvent";
import Workspaces from "@/state/Workspaces";

register()

const Auth = namespace("Auth");

@Component
export default class Chat extends Vue {
	private workspace: Workspace = Workspaces.getCurrent as Workspace;

	@Auth.Getter
	private isLoggedIn!: boolean;

	private isOpen = false;

	private fileAccept = documentAccept;

	private countUnseenMess = 0;

	private messages: Array<MessageDTO> = [];

	private messagesLoaded = false;

	private roomLoaded = true;

	private loadingRooms = true;

	private userName = "";

	private email: string | null = null;

	private isChatExist = false;

	private rooms: Array<RoomDTO> = [];

	private countMess = 0;

	private messageActions = [{
		name: 'replyMessage',
		title: 'Reply'
	}]

	private styles = {
		general: {
			colorButtonClear: '#436356',
			backgroundColorButton: '#436356',
			colorCaret: '#436356',
		},

		sidemenu: {
			colorActive: '#436356',
		},

		content: {
			background: '#EDEDEC'
		},

		message: {
			colorTag: '#436356',
			colorNewMessages: '#436356',
			backgroundScrollCounter: '#436356',
			colorReactionCounterMe: '#436356',
			backgroundAudioProgress: '#455247',
			backgroundAudioProgressSelector: '#455247',
		},

		footer: {
			borderInputSelected: '#436356',
		},

		room: {
			backgroundCounterBadge: '#436356',
		},

		icons: {
			add: '#436356',
			file: '#436356',
			paperclip: '#436356',
			send: '#436356',
			emoji: '#436356',
			document: '#436356',
			checkmarkSeen: '#436356',
			microphone: '#436356',
		}
	}

	buildParticipant(id: string, name: string): ParticipantDTO {
		let user = new ParticipantDTO();
		user._id = id;
		user.username = name;
		user.status = {
			state: "ONLINE",
			lastChanged: moment().utc().toISOString()
		}
		return user;
	}

	downloadMessage(obj: any){
		Vue.prototype.$docUtils.download(obj.file.file.fileMetaId);
	}

	mounted() {
		session.subscribe(
			{
				destination: `/portal/customer/${this.getSenderId()}/support`,
				onReceiveMessageMethod: this.onReceivedMessage
			}
		);
		this.getChat();
	}

	private getChat() {
		ChatService.getChatsById(this.getSenderId()).then(
			res => {
				if (res.data) {
					this.prepareExistRoom(res.data);
				} else if (this.isLoggedIn) {
					this.createRoom();
				}
			}
		)
	}

	private getOrCreateRoom() {
		if (!this.rooms[0]) {
			return ChatService.getChatsById(this.getSenderId()).then(
				res => {
					if (res.data) {
						this.prepareExistRoom(res.data);
					} else {
						this.createRoom();
					}
				},
				err => {
					setTimeout(() => this.getOrCreateRoom(), 5000);
				}
			)
		} else {
			return new Promise((resolve) => {
				resolve(this.rooms[0]);
			});
		}
	}

	private prepareExistRoom(room: RoomDTO) {
		room.index = moment(room.createDateTime).format("YYYY-MM-DD HH:mm:ss").toString()
		this.userName = room.roomName as string;
		this.isChatExist = this.userName != "";
		this.rooms = [room];
		this.countUnseenMess = room.unreadCount;
		this.loadingRooms = false;
	}

	private setName(): void {
		this.$validator.validateAll().then((isValid) => {
			if (isValid) {
				this.isChatExist = isValid;
				this.getOrCreateRoom();
			}
		})
	}

	private createRoom() {
		let req = new CreateChatRequest();
		req.email = this.email;
		req.name = this.workspace ? this.workspace.name : this.userName;
		req.user = this.buildParticipant(this.getSenderId(), req.name)
		ChatService.createChat(req).then(
			res => {
				this.prepareExistRoom(res.data);
			},
			err => console.log(err)
		)
	}

	private sendMessage(message: any) {
		let mess = new MessageDTO()
		mess.content = message.content;
		mess.senderId = this.getSenderId();
		mess.username = this.rooms[0].roomName;
		mess.replyMessage = message.replyMessage;
		mess.chatId = this.rooms[0].roomId;
		this.prepareReplyMessage(mess.replyMessage);
		if (message.files) {
			Promise.all((message.files as Array<any>).map(file => this.httpUploadFile(file)))
				.then((res) => {
					this.sendMessageDTO(mess, (message.files as Array<any>).map((file, index) => this.convertFile(file, res[index].data.id)));
				});
		} else {
			this.sendMessageDTO(mess);
		}
	}

	private prepareReplyMessage(message: MessageDTO | null) {
		if (!message) return;
		if (message.files) {
			message.files = this.prepareFiles(message.files);
		}
		if (message.replyMessage) {
			this.prepareReplyMessage(message.replyMessage)
		}

	}

	private prepareFiles(files: Array<AttachFile>) {
		return files.map(file => {
			file.preview = "";
			file.url = "";
			return file
		})
	}

	private httpUploadFile(file: any) {
		const preparedFile = new File([file.blob], file.name);
		const userId = this.workspace ? this.workspace.userId : null;
		return FileService.uploadFile(preparedFile, userId, null);
	}

	sendMessageDTO(message: MessageDTO, files: Array<AttachFile> = []) {
		message.files = files;
		session.getSessionClient!.send('/app/support', {}, JSON.stringify(message))
		this.countMess = this.countMess + 1;
		if (this.countMess != 0) {
			this.messagesLoaded = false;
		}
		return message;
	}

	private getSenderId(): string {
		return this.workspace ? this.workspace.id.toString() : getCustomerUUID();
	}

	private onReceivedMessage(obj: any) {
		let newMessage = JSON.parse(obj.body) as MessageDTO;
		if (this.getSenderId() == newMessage.senderId
			&& (newMessage.event === MessageEvent.RECEIVED)) {
			this.countMess -= 1;
			if (this.countMess == 0) {
				this.messagesLoaded = true;
			}
		}

		if (newMessage.event === MessageEvent.RECEIVED) {
			newMessage.timestamp = moment(newMessage.createDateTime).format("LT")
			newMessage.date = moment(newMessage.createDateTime).format("MM.DD.yyyy")
			this.messages = [...this.messages, newMessage];
			if (!this.isOpen && (this.getSenderId() != newMessage.senderId)) {
				this.countUnseenMess = this.countUnseenMess + 1;
			}
		}

		if (newMessage.event === MessageEvent.DELIVERED) {
			for (let message of this.messages) {
				if (message._id === newMessage._id) {
					message.event = newMessage.event;
					message.seen = newMessage.seen;
					message.distributed = newMessage.distributed;
					this.messages = [...this.messages];
					break;
				}
			}
		}

		if (this.isOpen && newMessage.event == MessageEvent.RECEIVED) {
			this.seenMessage(newMessage);
		}
	}

	private seenMessage(mess: MessageDTO) {
		if (mess.senderId != this.getSenderId() && mess.event == MessageEvent.RECEIVED) {
			let seenMessage = MessageDTO.clone(mess);
			seenMessage.seen = true;
			seenMessage.distributed = true;
			seenMessage.event = MessageEvent.DELIVERED;
			this.prepareFiles(seenMessage.files)
			this.prepareReplyMessage(seenMessage.replyMessage);
			session.getSessionClient!.send('/app/support', {}, JSON.stringify(seenMessage))
		}
	}

	private fetchMessages(params: any) {
		this.messagesLoaded = false;
		ChatService.fetchMessages(params.room.roomId as string).then(
			res => {
				let messages = res.data.map(mess => {
					mess.timestamp = moment(mess.createDateTime).format("LT");
					mess.date = moment(mess.createDateTime).format("MM.DD.yyyy")
					this.seenMessage(mess)
					return mess;
				})
				this.messages = [...messages]
				this.messagesLoaded = true;
			},
			err => {
				setTimeout(() => this.fetchMessages(params), 3000);
			}
		)
	};

	convertFile(file: any, fileMetaId: number) {
		let attach = new AttachFile();
		attach.size = file.size;
		attach.name = file.name;
		attach.type = file.type;
		attach.extension = file.extension;
		attach.fileMetaId = fileMetaId;
		return attach;
	}


	toggle() {
		this.isOpen = !this.isOpen;
		if (!this.isOpen) {
			this.messagesLoaded = false;
		}

		if (this.countUnseenMess !== 0) {
			this.countUnseenMess = 0;

		}
	}
}
