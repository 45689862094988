import axios from 'axios';
import {PUBLIC_FEEDBACK_ENDPOINT} from '@/constants/endpoints'
import FeedbackDTO from "@/dto/FeedbackDTO";

class PublicService {

    sendFeedback(request: FeedbackDTO){
        return axios.post(PUBLIC_FEEDBACK_ENDPOINT, request);
    }
}

export default new PublicService();