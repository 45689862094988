import {
    CreateTimeSheetRecordPayload, TimeSheetFilter, TimeSheetPayPeriod,
    TimeSheetRecord,
    UpdateTimeSheetRecordPayload
} from "@/dto/payroll/timesheet/TimesheetPayloads";
import {TIMESHEET_SERVICE} from "@/constants/endpoints";
import QueryUtils from "@/utils/QueryUtils";
import authHeader from "@/services/auth-header";
import axios from "axios";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

class TimesheetService {
    getPayPeriod(filter: TimeSheetFilter) {
        const queryString = QueryUtils.objectToQueryString(filter)
        return axios.get<Array<TimeSheetPayPeriod>>(`${TIMESHEET_SERVICE}${queryString}`, {headers: authHeader()})
    }

    createReportFile(filter: TimeSheetFilter) {
        const queryString = QueryUtils.objectToQueryString(filter)
        return axios.post<FileMetaDTO>(`${TIMESHEET_SERVICE}/report${queryString}`, {}, {headers: authHeader()})
    }

    createRecord(req: CreateTimeSheetRecordPayload) {
        return axios.post<void>(`${TIMESHEET_SERVICE}`, req, {headers: authHeader()})
    }

    updateRecord(req: UpdateTimeSheetRecordPayload) {
        return axios.put<void>(`${TIMESHEET_SERVICE}`, req, {headers: authHeader()})
    }

    deleteRecord(recordId: number, employmentId: number) {
        return axios.delete(`${TIMESHEET_SERVICE}/${employmentId}/${recordId}`, {headers: authHeader()})
    }
}

export default new TimesheetService()