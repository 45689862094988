




























































import { Component, Prop } from "vue-property-decorator";
import FileSplitDTO from "@/dto/files/FileSplitDTO";
import authHeader from "@/services/auth-header";
import FileService from "@/services/FileService";
import { namespace } from "vuex-class";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import { FILE_PUBLISH_DOCUMENT_ENDPOINT } from "@/constants/endpoints";
import { processError } from "@/utils/ComponentUtils";
import PageFileDTO from "@/dto/files/PageFileDTO";
import PortalSignaturePad from "@/components/common/PortalSignaturePad.vue";
import GetRandomId from "@/mixins/getRandomId";
import SignerDTO from "@/dto/signature/SignerDTO";
import LabelDTO from "@/dto/signature/LabelDTO";
import { EventBus } from "@/utils/EventBus";
import { LabelType } from "@/constants/SignatureConstants";

const AppModule = namespace("App");

class FileSplitPage extends PageFileDTO {
  public src!: string;
}

@Component<EmbeddedFile>({
  components: { PortalSignaturePad },
  computed: {
    LabelType() {
      return LabelType;
    }
  },
  watch: {
    file() {
      this.getSplitFile();
    }
  }
})
export default class EmbeddedFile extends GetRandomId {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @AppModule.State
  private isMobile!: boolean;

  @Prop()
  private file!: FileMetaDTO | SignerDTO

  @Prop({ default: '100%' })
  private width!: string

  @Prop({ default: 'calc(100vh - 220px)' })
  private height!: string

  @Prop({ default: null })
  private signatures!: Record<string, string> | null

  $refs!: {
    imagesPages: Array<HTMLImageElement>,
    signaturesLabels: Array<HTMLButtonElement>,
  }

  splitFile = new FileSplitDTO();
  pages: Array<FileSplitPage> | [] = [];
  heightsImagesPages: Array<number> | [] = [];

  mounted(): void {
    EventBus.$on('sign', this.showTooltipPermanently);
    window.addEventListener('resize', this.setHeightsImagesPages);
    this.getSplitFile();
  }

  beforeDestroy(): void {
    EventBus.$off('sign');
    window.removeEventListener('resize', this.setHeightsImagesPages);
  }

  isFileMetaDTO(file: FileMetaDTO | SignerDTO): file is FileMetaDTO {
   return 'extension' in file;
  }

  getSplitFile(): void {
    this.startLoading();
    let internalKey;
    if (this.isFileMetaDTO(this.file)) {
      internalKey = this.file.internalKey;
    } else {
      internalKey = this.file.document.displayedFile.internalKey;
    }
    FileService.getSplitFile(internalKey).then(
      resp => {
        this.splitFile = resp.data;
        this.downloadAllPages();
        this.stopLoading();
      },
      error => {
        processError(error, this);
        this.stopLoading();
      }
    )
  }

  downloadAllPages(): void {
    const token = authHeader().Authorization;
    for (const page of this.splitFile.pages) {
      FileService.publishDocument(page.pageFile.id).then(
        resp => {
          this.pages.push({ ...page, src: `${FILE_PUBLISH_DOCUMENT_ENDPOINT}/${resp.data}${token? "?token=" + token : ''}`} as never);
        },
        error => {
          processError(error, this);
          this.stopLoading();
        }
      )
    }
  }

  getLabelsPage(page: FileSplitPage): Array<LabelDTO> | [] {
    if (this.isFileMetaDTO(this.file)) {
      return [];
    } else {
      return this.file.labels.filter(label => label.pageNumber === page.pageNumber);
    }
  }

  setHeightsImagesPages(): void {
    this.heightsImagesPages = this.$refs.imagesPages?.map(imagePage => imagePage.height);
  }

  openSignatureModal(label: LabelDTO): void {
    if (label.type === LabelType.SIGNATURE) {
      return this.$modal.show(
        PortalSignaturePad,
        { label }
      );
    }
    else {
      return this.$modal.show(
        PortalSignaturePad,
        { label }
      );
    }
  }

  showTooltipPermanently(id: number, url: string): void {
    this.$root.$emit('bv::show::tooltip', id.toString())
  }

  get sortedPages(): FileSplitPage[] | [] {
    return this.pages.sort((p1, p2) => p1.pageNumber - p2.pageNumber);
  }

}
