





























import {Vue, Component, Prop} from 'vue-property-decorator';
import PortalSelect from "@/components/common/PortalSelect.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";
import {CreateVCRequestPayload, VacationBalance} from "@/dto/payroll/VacationDTO";
import Application from "@/state/Application";
import VacationService from "@/services/payroll/VacationService";
import {ifValid, processError} from "@/utils/ComponentUtils";
import PortalTextarea from "@/components/common/PortalTextarea.vue";

@Component({
	components: {PortalTextarea, PortalCheckbox, PortalDate, PortalSelect}
})
export default class VacationRequestCreationModal extends Vue {
	@Prop({default: null})
	private employment!: IEmployment | null

	@Prop()
	private callback!: () => void

	private balance: VacationBalance | null = null

	private req = new CreateVCRequestPayload(
		{
			employmentId: this.employment ? this.employment.id! : null,
			startTime: "10:00",
			endTime: "18:00"
		}
	)

	private loadAvailableBalance() {
		if (this.req.employmentId) {
			Application.startLoading()
			VacationService.getVacationBalance(this.req.employmentId).then(
				res => {
					this.balance = res.data;
					Application.stopLoading()
				},
				err => processError(err, this)
			)
		}
	}

	public created() {
		this.loadAvailableBalance()
	}

	private create() {
		ifValid(this, () => {
			Application.startLoading();
			VacationService.requestVacation(this.req).then(
				() => {
					this.$modal.hideAll();
					Application.stopLoading()
					this.callback()
				},
				err => processError(err, this)
			)
		})
	}

}
