import Notifications from "@/state/Notifications";

class Clipboard{

    public write(text: string){
        navigator.clipboard.writeText(text)
        Notifications.info("Сopied to clipboard");
    }

}

export default new Clipboard();