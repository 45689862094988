












































import {Component, Vue} from "vue-property-decorator";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {
	IRSLetter,
	IRSLetterFilter,
} from "@/components/documents/notice/IRSLetter";
import Workspace from "@/dto/auth/Workspace";
import Workspaces from "@/state/Workspaces";
import Application from "@/state/Application";
import DocumentService from "@/services/DocumentService";
import {processError} from "@/utils/ComponentUtils";

@Component({
	components: {PortalCheckbox, BTPortalCollapse, PortalInput, SingleFileHolder, PaginationComponent}
})
export default class CustomerNoticeLetterList extends Vue {
	private letters: Array<IRSLetter> = [];

	private workspace: Workspace = Workspaces.getCurrent;

	private totalPages = 1;

	private filter: IRSLetterFilter = new IRSLetterFilter();

	public created() {
		this.filter.companyId = this.workspace.id;
		this.loadIrsLetters()
	}

	private loadIrsLetters() {
		Application.startLoading()
		DocumentService.getIRSLettersByFilter(this.filter).then(
			res => {
				if (res.data.data) {
					this.letters = res.data.data
					this.totalPages = res.data.countOfPages
				}
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	changePage(page: number){
		this.filter.page = page - 1;
		this.loadIrsLetters();
	}

	private reset() {
		this.filter = new IRSLetterFilter();
		this.filter.companyId = this.workspace.id;
		this.loadIrsLetters()
	}
}

