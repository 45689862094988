import Pageable from "@/dto/Pageable";
import AuditDTO from "@/components/admin/audit/AuditDTO";
import axios from "axios";
import QueryUtils from "@/utils/QueryUtils";
import CollectionUtils from "@/utils/CollectionUtils";
import authHeader from "@/services/auth-header";
import {AUDIT_EVENTS_ENDPOINT} from "@/constants/endpoints";
import AuditFilter from "@/components/admin/audit/AuditFilter";

class AuditService {
    public getAllAuditEvents(filter: AuditFilter) {
        const queryString = QueryUtils.objectToQueryString(filter)
        console.log(`${AUDIT_EVENTS_ENDPOINT}${queryString}`)
        return axios.get<Pageable<AuditDTO>>(`${AUDIT_EVENTS_ENDPOINT}${queryString}`, {headers: authHeader()})
    }

    public getAllEvents() {
        return axios.get<Array<string>>(`${AUDIT_EVENTS_ENDPOINT}/events`, {headers: authHeader()})
    }
}

export default new AuditService()