
class Application {
    private enableRunLoader = false;

    public startLoading(): void {
        if (!document.getElementById("main_spinner")) {
            const overlay = document.createElement("div")
            overlay.setAttribute("class", "overlay")
            overlay.setAttribute("id", "main_spinner")
            const div = document.createElement("div");
            div.setAttribute("class", "overlay__inner")
            const childDiv = document.createElement("div")
            childDiv.setAttribute("class", "overlay__content")
            const spinner = document.createElement("span")
            spinner.setAttribute("class", "spinner")
            childDiv.append(spinner)
            div.append(childDiv)
            overlay.append(div)
            document.getElementById("app")?.append(overlay)
        }
    }

    public stopLoading(): void {
        document.getElementById("main_spinner")?.remove()
    }

    get enableLoader(): boolean {
        return this.enableRunLoader;
    }
}

export default new Application();