export default class ReportFilter {
    public firstName: string | null = null;

    public lastName: string | null = null;

    public reportType: ReportType | null = null;

    public pageNumber = 0;

    public pageSize = 20;

    public orderDirection = OrderDirection.DESC;

    public orderField = OrderField.CREATE_DATE
}

export enum ReportType {
    GENERAL_ACH = "GENERAL_ACH"
}

export enum OrderDirection {
    ASC = "ASC",
    DESC = "DESC"
}

export enum OrderField {
    CREATE_DATE = "CREATE_DATE"
}