















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {
	ApproveRequestPayload,
	ChangeStatusVCRRequestPayload,
	RejectRequestPayload,
	VCR,
	VCRStatus
} from "@/dto/payroll/VacationDTO";
import VacationReaquestView from "@/components/payroll/vacation/VacationReaquestView.vue";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import moment, {Moment} from "moment-timezone";
import {prettyEnum} from "../../../utils/StringUtils";
import Application from "@/state/Application";
import VacationService from "@/services/payroll/VacationService";
import {processError} from "@/utils/ComponentUtils";
import {IEmployment} from "@/dto/payroll/IEmployment";

@Component({
	computed: {
		VCRStatus() {
			return VCRStatus
		}
	},
	methods: {
		prettyEnum,
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		},
	},
	components: {PortalTextarea, VacationReaquestView}
})
export default class VacationRequestEditor extends Vue {

	@Prop()
	private vcr!: VCR

	@Prop()
	private callback!: () => void

	@Prop()
	private employment!: IEmployment

	private comment: string | null = null

	public created() {
		if (this.vcr.status == VCRStatus.REQUESTED) {
			Application.startLoading()
			VacationService.changeStatus(
				new ChangeStatusVCRRequestPayload(
					{
						requestId: this.vcr.id,
						status: VCRStatus.IN_PROGRESS,
						employmentId: this.employment.id
					}
				)
			).then(
				() => {
					this.vcr.status = VCRStatus.IN_PROGRESS
					Application.stopLoading()
				},
				err => processError(err, this)
			)
		}

	}

	private reject() {
		Application.startLoading()
		VacationService.rejectRequest(
			new RejectRequestPayload(
				{
					requestId: this.vcr.id,
					companyId: this.$wss.getCurrent.id,
					comment: this.comment
				}
			)
		).then(
			() => {
				this.$modal.hideAll()
				Application.stopLoading()
				this.callback()
			},
			err => processError(err, this)
		)
	}

	private approve() {
		Application.startLoading()
		VacationService.approveRequest(
			new ApproveRequestPayload(
				{
					requestId: this.vcr.id,
					companyId: this.$wss.getCurrent.id,
					comment: this.comment
				}
			)
		).then(
			() => {
				this.$modal.hideAll()
				Application.stopLoading()
				this.callback()
			},
			err => processError(err, this)
		)
	}

	get changeable() {
		return [VCRStatus.REQUESTED, VCRStatus.IN_PROGRESS].includes(this.vcr.status)
	}

}
