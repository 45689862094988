import AttachFile from "@/dto/chat/AttachFile";
import { MessageEvent } from "@/constants/MessageEvent";

export default class MessageDTO {
  public _id: string | null = null;

  public indexId: string | null = null;

  public uuid: string | null = null;

  public content: string | null = null;

  public senderId: string | null = null;

  public chatId: string | null = null;

  public username: string | null = null;

  public date: string | null = null;

  public seen = false;

  public saved = true;

  public distributed = false;

  public timestamp: string | null = null;

  public createDateTime: string | null = null;

  public files: Array<AttachFile> = [];

  public replyMessage: MessageDTO | null = null;

  public event: MessageEvent = MessageEvent.RECEIVED

  public static clone(mess: MessageDTO): MessageDTO {
    const attach = new Array<AttachFile>()
    mess.files.map(file => AttachFile.clone(file))
        .forEach(file => attach.push(file))
    return MessageDTO.builder()
        ._id(mess._id)
        .indexId(mess.indexId)
        .uuid(mess.uuid)
        .content(mess.content)
        .senderId(mess.senderId)
        .chatId(mess.chatId)
        .username(mess.username)
        .date(mess.date)
        .seen(mess.seen)
        .saved(mess.saved)
        .distributed(mess.distributed)
        .timestamp(mess.timestamp)
        .createDateTime(mess.createDateTime)
        .files(attach)
        .replyMessage(mess.replyMessage)
        .event(mess.event)
        .build();
  }

  public static builder(): Builder {
    return new Builder();
  }

}

class Builder {
  private readonly mess!: MessageDTO;

  constructor() {
    this.mess = new MessageDTO();
  }

  public _id(_id: string | null): Builder {
    this.mess._id = _id;
    return this;
  }

  public indexId(indexId: string | null): Builder {
    this.mess.indexId = indexId;
    return this;
  }

  public uuid(uuid: string | null): Builder {
    this.mess.uuid = uuid;
    return this;
  }

  public content(content: string | null): Builder {
    this.mess.content = content;
    return this;
  }

  public date(date: string | null): Builder {
    this.mess.date = date;
    return this;
  }

  public senderId(senderId: string | null): Builder {
    this.mess.senderId = senderId;
    return this;
  }

  public chatId(chatId: string | null): Builder {
    this.mess.chatId = chatId;
    return this;
  }

  public username(username: string | null): Builder {
    this.mess.username = username;
    return this;
  }

  public seen(seen = false): Builder {
    this.mess.seen = seen;
    return this;
  }

  public saved(saved = false): Builder {
    this.mess.saved = saved;
    return this;
  }

  public distributed(distributed = true): Builder {
    this.mess.distributed = distributed;
    return this;
  }

  public timestamp(timestamp: string | null): Builder {
    this.mess.timestamp = timestamp;
    return this;
  }

  public createDateTime(createDateTime: string| null): Builder {
    this.mess.createDateTime = createDateTime;
    return this;
  }

  public files(files: Array<AttachFile> = []): Builder {
    this.mess.files = files;
    return this;
  }

  public replyMessage(replyMessage: MessageDTO | null) : Builder {
    this.mess.replyMessage = replyMessage;
    return this;
  }

  public event(event: MessageEvent = MessageEvent.RECEIVED): Builder {
    this.mess.event = event;
    return this;
  }

 public build(): MessageDTO {
    return this.mess;
 }
}
