import {NotificationOptions} from "vue-notification";
import {Vue} from "vue-property-decorator";
import moment from "moment/moment";
import DateUtils from "@/utils/DateUtils";

export interface NotificationSettings {
    title?: string,
    duration?: number;
    speed?: number;
}

class Notifications {

    public info(text: string, settings?: NotificationSettings): void {
        this.clean("error")
        this.notify({
            title: settings?.title ? settings.title : "Information",
            text: text,
            type: "info",
            duration: settings?.duration,
            speed: settings?.speed
        })
    }

    public warning(text: string, settings?: NotificationSettings): void {
        this.clean("error")
        this.notify({
            title: settings?.title ? settings.title : "Warning",
            text: text,
            type: "warning",
            duration: settings?.duration,
            speed: settings?.speed
        })
    }

    public success(text: string, settings?: NotificationSettings): void {
        this.clean("error")
        this.notify({
            title: settings?.title ? settings.title : "Success",
            text: text,
            type: "success",
            duration: settings?.duration,
            speed: settings?.speed
        })
    }

    public error(text: string, settings?: NotificationSettings): void {
        this.clean("error")
        this.clean();
        this.notify({
            title: settings?.title ? settings.title : "Error",
            text: text,
            group: "error",
            type: "error",
            duration: settings?.duration ? settings.duration : 30000,
            speed: settings?.speed
        })
    }

    public clean(group?: string) {
        this.notify({
            clean: true,
            group: group
        })
    }

    private notify(options: NotificationOptions) {
        const timeStamp= DateUtils.formatDateToAmericanFormatWithTime(new Date())
        Vue.notify({
            title: options.title,
            type: options.type,
            group: options.group,
            text: `${options.text} <br/><span>${timeStamp}</span>`,
            duration: options.duration ? options.duration : 6000,
            speed: options.speed,
            clean: options.clean
        })
    }
}

export default new Notifications();