import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const localesRU = require.context(
    "./locales/ru",
    true,
    /[A-Za-z0-9-_.,\s]+\.json$/i
  );
  const localesEN = require.context(
    "./locales/en",
    true,
    /[A-Za-z0-9-_.,\s]+\.json$/i
  );
  const messages: LocaleMessages = {};

  function addLocale(locales: __WebpackModuleApi.RequireContext): void {
    locales.keys().forEach((key) => {
      const matched = key.match(/([^}]+?)\.([^}]+?)\./);
      if (matched && matched.length > 1) {
        const locale = matched[2];
        if (locale in messages) {
          Object.assign(messages[locale], locales(key));
        } else {
          messages[locale] = locales(key);
        }
      }
    });
  }

  addLocale(localesEN);
  addLocale(localesRU);

  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});
