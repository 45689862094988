

















import {Vue, Component} from 'vue-property-decorator';
import {IEmployment} from "@/dto/payroll/IEmployment";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import Application from "@/state/Application";
import EmploymentService from "@/services/EmploymentService";
import {processError} from "@/utils/ComponentUtils";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PTOView from "@/components/payroll/policies/pto/PTOView.vue";
import EmploymentVacationList from "@/components/payroll/vacation/EmploymentVacationList.vue";

@Component({
	components: {EmploymentVacationList, PTOView, BTPortalCollapse}
})
export default class IndividualVacations extends Vue {

	private employments: Array<IEmployment> = [];

	private filter = new EmploymentFilter(
		{
			detailsId: this.$wss.getCurrent.id
		}
	)

	private loadEmployments() {
		Application.startLoading();
		EmploymentService.getAllByFilter<IEmployment>(this.filter).then(
			res => {
				this.employments = res.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created(): void {
		this.loadEmployments()
	}

}

