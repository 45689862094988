







































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AdminService from "@/services/AdminService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import { STATE_LIST } from "@/constants/ProfileConstants";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";
import UpdateSublimeEmployeeRequest from "@/dto/sublime/employee/UpdateSublimeEmployeeRequest";
import PortalSelect from "@/components/common/PortalSelect.vue";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
	components: {PortalSelect}
})
export default class SublimeEmployee extends Vue {
    private request: UpdateSublimeEmployeeRequest = new UpdateSublimeEmployeeRequest();

    private employees: Array<SublimeEmployeeDTO> = [];

    private message = "";

    private successful = false;

    private updateFlag = false;

    private states = STATE_LIST;

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    @Auth.Getter("hasAdministrativePrivileges")
    private hasAdministrativePrivileges!: boolean;

    mounted() {
        this.loadEmployee();
    }

    private loadEmployee(): void {
        this.startLoading();
        AdminService.getEmployee(this.$route.params.employeeId as string).then(
            res => this.processResp(res.data),
            err => {
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
                this.successful = false;
                this.stopLoading();
            }
        )
    }

    private processResp(employee: SublimeEmployeeDTO) {
        this.updateFlag = true;
        this.request.firstName = employee.firstName;
        this.request.lastName = employee.lastName;
        this.request.position = employee.position;
        this.request.officePhoneNumber = employee.officePhoneNumber;
        this.stopLoading();
    }


    private update(): void {
        this.message = "";
        this.$validator.validateAll().then(
            isValid => {
                if (isValid) {
                    this.request.employeeId = Number.parseInt(this.$route.params.employeeId, 10);
                    console.log(this.request.employeeId)
                    this.startLoading();
                    AdminService.updateEmployeeData(this.request).then(
                        () => {
                            this.stopLoading();
                        },
                        err => {
                            this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
                            this.successful = false;
                            this.stopLoading();
                        }
                    )
                }
            }
        )
    }
};
