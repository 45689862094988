
























import {Component, Prop, Vue} from "vue-property-decorator";
import EmploymentService from "@/services/EmploymentService";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import Workspaces from "@/state/Workspaces";
import {
	TimeSheetFilter,
	TimeSheetPayPeriod,
} from "@/dto/payroll/timesheet/TimesheetPayloads";
import {processError} from "@/utils/ComponentUtils";
import Application from "@/state/Application";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import {IEmployment} from "@/dto/payroll/IEmployment";
import AdminService from "@/services/AdminService";
import {namespace} from "vuex-class";
import {CounterpartyType} from "@/constants/CounterpartyType";
import moment from "moment-timezone";
import {v4 as uuidv4} from "uuid";
import TimeSheetPeriod from "@/components/payroll/timesheet/TimeSheetPeriod.vue";
import TimesheetService from "@/services/payroll/TimesheetService";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";

const AppModule = namespace("App");

@Component(
	{
		components: {
			BTPortalCollapse,
			TimeSheetPeriod
		}
	}
)
export default class IndividualTimesheet extends Vue{

	private id = uuidv4()

	@Prop()
	private timeFormat!: string

	private employmentIdArr: Array<number> = [];

	private timesheetPeriods: Array<TimeSheetPayPeriod> = [];

	private employmentFilter: EmploymentFilter = new EmploymentFilter(
		{
			types: ["STAFF", "FOREIGN"],
			contractorType: CounterpartyType.PERSON,
			detailsId: Workspaces.getCurrent.id,
			status: [EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS, EmploymentStatus.NEW]
		}
	)

	public created(): void {
		Application.startLoading();
		this.loadEmploymentIds().then(
			() => {
				if (this.employmentIdArr.length > 0) this.loadTimesheetRecords()
			},
			err => processError(err, this)
		)
			.then(Application.stopLoading)
	}

	private async loadEmploymentIds() {
		if (Workspaces.isCurrentWorkspaceSublimeEmployee) {
			return AdminService.getEmployeePersonProfile().then(
				res => this.employmentFilter.detailsId = res.data.id,
				err => processError(err, this)
			).then(
				() => EmploymentService.getAllByFilter<IEmployment>(this.employmentFilter).then(
					res => {
						this.employmentIdArr = res.data.map(it => it.id!)
					},
					err => processError(err, this)
				)
			)
		} else {
			return EmploymentService.getAllByFilter(this.employmentFilter).then(
				res => {
					this.employmentIdArr = res.data.map(it => it.id!)
				},
				err => processError(err, this)
			)
		}
	}

	private async loadTimesheetRecords() {
		const timesheetFilter = new TimeSheetFilter(
			{
				startDate: moment().set({weekday: 0}).format("YYYY-MM-DD"),
				endDate: moment().set({weekday: 6}).format("YYYY-MM-DD"),
				employmentsId: this.employmentIdArr
			}
		)
		TimesheetService.getPayPeriod(timesheetFilter).then(
			res => {
				this.timesheetPeriods = res.data
			},
			err => processError(err, this)
		)
	}

}

