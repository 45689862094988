
































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Workspace from "@/dto/auth/Workspace";
import { documentAccept } from "@/constants/FileConstants";
import MessageDTO from "@/dto/chat/MessageDTO";
import RoomDTO from "@/dto/chat/RoomDTO";
import moment from "moment/moment";
import FileService from "@/services/FileService";
import AttachFile from "@/dto/chat/AttachFile";
import session from "@/store/modules/session.module";
import ChatService from "@/services/ChatService";
import RouteNames from "@/router/RouteNames";
import { MessageEvent } from "@/constants/MessageEvent";
import Workspaces from "@/state/Workspaces";

@Component
export default class AdminSupport extends Vue {
	private workspace: Workspace = Workspaces.getCurrent as Workspace;

    private fileAccept = documentAccept;

    private messages: Array<MessageDTO> = [];

    private messagesLoaded = false;

    private loadingRooms = true;

    private roomLoaded = false;

    private roomPage = 0;

    private selectedRoomId = "";

    private rooms: Array<RoomDTO> = [];

    private messageActions = [{
        name: 'replyMessage',
        title: 'Reply'
    }]

	private styles = {
		general: {
			colorButtonClear: '#436356',
			backgroundColorButton: '#436356',
			colorCaret: '#436356',
		},

		sidemenu: {
			backgroundActive: '#A9C8BC',
			colorActive: '#436356',
		},

		content: {
			background: '#EDEDEC'
		},

		message: {
			colorTag: '#436356',
			colorNewMessages: '#436356',
			backgroundScrollCounter: '#436356',
			colorReactionCounterMe: '#436356',
			backgroundAudioProgress: '#455247',
			backgroundAudioProgressSelector: '#455247',
		},

		footer: {
			borderInputSelected: '#436356',
		},

		room: {
			backgroundCounterBadge: '#436356',
		},

		icons: {
			add: '#436356',
			file: '#436356',
			paperclip: '#436356',
			send: '#436356',
			emoji: '#436356',
			document: '#436356',
			checkmarkSeen: '#436356',
			microphone: '#436356',
		}
	}

    public mounted(): void {
        session.subscribe(
            {
                destination: `/portal/customer/${this.getSenderId()}/chat`,
                onReceiveMessageMethod: this.onChatUpdate
            }
        )
        session.subscribe(
            {
                destination: `/portal/customer/${this.getSenderId()}/support`,
                onReceiveMessageMethod: this.onReceivedMessage
            }
        )
        this.getChats()
    }

    private fetchMoreRooms(): void {
        this.getChats(++this.roomPage);
    }

    private onChatUpdate(obj: any) {
        let roomArr = this.rooms;
        let newRoom = JSON.parse(obj.body) as RoomDTO
        let isExist = false;

        for (let i = 0; i < roomArr.length; i++) {
            if (roomArr[i].roomId === newRoom.roomId) {
                isExist = true;
                roomArr[i] = newRoom;
            }
        }

        if (!isExist) {
            roomArr = [newRoom, ...roomArr];
        }

        this.rooms = [...roomArr];
    }

    private getChats(pageNumber = 0) {
        if (this.roomPage === 0) {
            this.loadingRooms = true;
        }
        ChatService.getChats(this.workspace.id, pageNumber).then(
            (res) => {
                let rooms = res.data.chatSequence;
                for (let room of rooms) {
                    if (room.lastMessage) {
                        let lastMess = room.lastMessage;
                        lastMess.timestamp = moment(lastMess.createDateTime).format("LT");
                        lastMess.date = moment(lastMess.createDateTime).format("MM.DD.yyyy")
                        room.lastMessage = lastMess;
                    }
                }
                this.rooms = [...this.rooms, ...rooms];
                this.roomLoaded = this.rooms.length >= res.data.count;
                if (this.roomPage === 0) {
                    this.loadingRooms = false;
                }
            },
            () => setTimeout(() => this.getChats(), 3000)
        )
    }

    downloadMessage(obj: any){
        Vue.prototype.$docUtils.download(obj.file.file.fileMetaId);
    }

    private onReceivedMessage(obj: any) {
        let newMessage = JSON.parse(obj.body) as MessageDTO;
        newMessage.timestamp = moment(newMessage.createDateTime).format("LT")
        newMessage.date = moment(newMessage.createDateTime).format("MM.DD.yyyy")
        let isSelectedRoom = newMessage.chatId === this.selectedRoomId
            && this.$route.name === RouteNames.SUPPORT;
        if (isSelectedRoom) {
            if (newMessage.event === MessageEvent.RECEIVED) {
                this.messages = [...this.messages, newMessage];
            }
            if (newMessage.event === MessageEvent.DELIVERED) {
                for (let message of this.messages) {
                    if (message._id === newMessage._id) {
                        message.event = newMessage.event;
                        message.seen = newMessage.seen;
                        message.distributed = newMessage.distributed;
                        this.messages = [...this.messages];
                        break;
                    }
                }
            }
            if (isSelectedRoom) {
                this.seenMessage(newMessage);
            }
        } else {
            for (let room of this.rooms) {
                if (room.roomId === newMessage.chatId) {
                    room.unreadCount += 1;
                }
            }
            this.rooms = [...this.rooms];
        }

        this.updateLastMessage(newMessage);
    }



    private seenMessage(mess: MessageDTO) {
        if (mess.senderId != this.getSenderId() && mess.event == MessageEvent.RECEIVED) {
			let seenMessage = MessageDTO.clone(mess);
	        seenMessage.seen = true;
	        seenMessage.distributed = true;
	        seenMessage.event = MessageEvent.DELIVERED;
			this.prepareFiles(seenMessage.files)
            this.prepareReplyMessage(seenMessage.replyMessage);
            session.getSessionClient!.send('/app/support', {}, JSON.stringify(seenMessage))
        }
    }

    private prepareReplyMessage(message: MessageDTO | null) {
        if (!message) return;
        if (message.files) {
            message.files = this.prepareFiles(message.files);
        }
        if (message.replyMessage) {
            this.prepareReplyMessage(message.replyMessage)
        }

    }

    private prepareFiles(files: Array<AttachFile>) {
        return files.map(file => {
            file.preview = "";
            file.url = "";
            return file
        })
    }

    private fetchMessages(params: any) {
        this.selectedRoomId = params.room.roomId as string;
        for (let room of this.rooms) {
            if (room.roomId === this.selectedRoomId) {
                room.unreadCount = 0;
                this.rooms = [...this.rooms]
                break;
            }
        }
        this.messagesLoaded = false;
        ChatService.fetchMessagesForAdmin(params.room.roomId as string).then(
            res => {
                let messages = res.data.map(mess => {
                    mess.timestamp = moment(mess.createDateTime).format("LT");
                    mess.date = moment(mess.createDateTime).format("MM.DD.yyyy")
                    this.seenMessage(mess);
                    return mess;
                })
                this.messages = [...messages]
                this.messagesLoaded = true;
            },
            err => {
                setTimeout(() => this.fetchMessages(null), 3000);
            }
        )
    };

    private getSenderId(): string {
        return this.workspace.id.toString();
    }

    private sendMessage(message: any) {
        let mess = new MessageDTO()
        mess.content = message.content;
        mess.senderId = this.getSenderId();
        mess.chatId = message.roomId;
        mess.username = this.workspace ? this.workspace.name : "UNKNOWN"
        mess.replyMessage = message.replyMessage;
		this.prepareFiles(mess.files)
        this.prepareReplyMessage(mess.replyMessage);
        if (message.files) {
            Promise.all((message.files as Array<any>).map(file => FileService.uploadFile(new File([file.blob], file.name), this.workspace ? this.workspace.userId : null, file.name + "." + file.extension)))
                .then((res) => this.sendMessageDTO(mess, res.map((httpResponse, index) => this.convertFile(message.files[index], httpResponse.data.id))))
        } else {
            this.sendMessageDTO(mess);
        }
    }

    convertFile(file: any, fileMetaId: number) {
        let attach = new AttachFile();
        attach.size = file.size;
        attach.name = file.name;
        attach.type = file.type;
        attach.extension = file.extension;
        attach.fileMetaId = fileMetaId;
        return attach;
    }

    sendMessageDTO(message: MessageDTO, files: Array<AttachFile> = []) {
        message.files = files;
        session.getSessionClient?.send('/app/support', {}, JSON.stringify(message))
    }

    private updateLastMessage(message: MessageDTO): void {
        for (let room of this.rooms) {
            if (room.roomId === message.chatId) {
                room.lastMessage = message;
            }
        }
    }
};
