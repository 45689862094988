import axios from 'axios';
import {
    TAXATION_QUESTIONNAIRE_ENDPOINT,
    TAXATION_FORM_LIST_ENDPOINT,
    TAXATION_GET_QUESTIONNAIRE_LIST_ENDPOINT,
    TAXATION_SAVE_FORM_VALUE_ENDPOINT,
    TAXATION_ASSIGN_QUESTIONNAIRE_ENDPOINT,
    TAXATION_PAGES_ENDPOINT, TAXATION_SUBMIT_QUESTIONNAIRE_ENDPOINT
} from '@/constants/endpoints'
import authHeader from "@/services/auth-header";
import TaxationUserQuestionnaireDTO, {TaxationFormDTO} from "@/dto/TaxationUserQuestionnaireDTO";
import TaxationQuestionnaireShortDataDTO from "@/dto/QuestionnaireShortDataDTO";
import SaveFormValueRequestDTO from "@/dto/SaveFormValueRequestDTO";
import AssignQuestionnaireRequestDTO from "@/dto/AssignQuestionnaireRequestDTO";
import UserDataDTO from "@/dto/UserDataDTO";
import RequestQuestionnaireFilterDTO from "@/dto/RequestQuestionnaireFilterDTO";
import CreateQuestionnaireRequestDTO from "@/dto/CreateQuestionnaireRequestDTO";

class TaxationService {

    getFormList(filter: RequestQuestionnaireFilterDTO) {
        return axios.post<Array<TaxationQuestionnaireShortDataDTO>>(TAXATION_FORM_LIST_ENDPOINT, filter, {headers: authHeader()});
    }

    saveQuestionnaire(questionnaire: TaxationUserQuestionnaireDTO) {
        return axios.post(`${TAXATION_QUESTIONNAIRE_ENDPOINT}`, questionnaire, {headers: authHeader()});
    }

    getQuestionnaireById(id: number) {
        return axios.get<TaxationUserQuestionnaireDTO>(`${TAXATION_QUESTIONNAIRE_ENDPOINT}/${id}`, {headers: authHeader()})
    }

    createQuestionnaire(request: CreateQuestionnaireRequestDTO){
        return axios.put<TaxationUserQuestionnaireDTO>(
            TAXATION_QUESTIONNAIRE_ENDPOINT, request, {headers: authHeader()})
    }

    getQuestionnaireListForCurrentUser(){
        return axios.get<Array<TaxationQuestionnaireShortDataDTO>>(
            `${TAXATION_GET_QUESTIONNAIRE_LIST_ENDPOINT}`, {headers: authHeader()})
    }

    getQuestionnaireListForUser(userId: number){
        return axios.get<Array<TaxationQuestionnaireShortDataDTO>>(
            `${TAXATION_GET_QUESTIONNAIRE_LIST_ENDPOINT}/${userId}`, {headers: authHeader()})
    }

    saveFormValue(value: SaveFormValueRequestDTO){
        return axios.post<TaxationFormDTO>(`${TAXATION_SAVE_FORM_VALUE_ENDPOINT}`, value, {headers: authHeader()})
    }

    assignQuestionnaire(request: AssignQuestionnaireRequestDTO){
        return axios.post<UserDataDTO>(TAXATION_ASSIGN_QUESTIONNAIRE_ENDPOINT, request, {headers: authHeader()})
    }

    getPagesCount(filter: RequestQuestionnaireFilterDTO) {
        return axios.post<number>(TAXATION_PAGES_ENDPOINT, filter, {headers: authHeader()});
    }

    submitQuestionnaire(questionnaire: TaxationUserQuestionnaireDTO){
        return axios.post<TaxationUserQuestionnaireDTO>(TAXATION_SUBMIT_QUESTIONNAIRE_ENDPOINT, questionnaire, {headers: authHeader()});
    }
}

export default new TaxationService();