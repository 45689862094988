import PersonDTO from "@/dto/person/PersonDTO";
import CompanyDTO from "@/dto/company/CompanyDTO";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";

export default class Workspace {

    public name!: string;

    public id!: number;

    public type!: WorkspaceType;

    public userId: number;

    constructor(name: string, id: number, type: WorkspaceType, userId: number) {
        this.name = name;
        this.id = id;
        this.type = type;
        this.userId = userId;
    }

    public equals(other: Workspace): boolean {
        return this.type == other.type && this.id == other.id;
    }

    public get getPathElement(){
        return getPathFromWorkspaceType(this.type);
    }

}

export enum WorkspaceType {
    PERSON = 'PERSON',
    COMPANY = 'COMPANY',
    SUBLIME_EMPLOYEE = 'SUBLIME_EMPLOYEE'
}

export function fromPerson(person: PersonDTO): Workspace {
    return new Workspace(person.firstName + ' ' + person.lastName, person.id as number, WorkspaceType.PERSON, person.userId as number);
}

export function fromCompany(company: CompanyDTO): Workspace {
    return new Workspace(company.name, company.id as number, WorkspaceType.COMPANY, company.userId);
}

export function fromSublimeEmployee(employee: SublimeEmployeeDTO): Workspace {
    return new Workspace(employee.firstName + ' ' + employee.lastName, employee.id as number, WorkspaceType.SUBLIME_EMPLOYEE, employee.userId);
}

export enum WorkspaceTypePathElement {
    PERSON = 'p',
    COMPANY = 'c',
    SUBLIME_EMPLOYEE = 'e'
}

export function getPathFromWorkspaceType(type: WorkspaceType): WorkspaceTypePathElement{
    switch (type){
        case WorkspaceType.COMPANY: return WorkspaceTypePathElement.COMPANY;
        case WorkspaceType.PERSON: return WorkspaceTypePathElement.PERSON;
        case WorkspaceType.SUBLIME_EMPLOYEE: return WorkspaceTypePathElement.SUBLIME_EMPLOYEE;
    }
}