
import {Component, Vue} from 'vue-property-decorator';
import {
	IForeignContractor
} from "@/components/payroll/business/employments/foreignContractors/ForeignContractorPayloads";
import {CounterpartyType} from "@/constants/CounterpartyType";
import Compensation from "@/components/payroll/business/employments/foreignContractors/individual/Compensation.vue";
import IndividualForeignContractor
	from "@/components/payroll/business/employments/foreignContractors/individual/IndividualForeignContractorDetails.vue";
import BusinessForeignContractor
	from "@/components/payroll/business/employments/foreignContractors/business/BusinessForeignContractor.vue";
import PoliciesView from "@/components/payroll/policies/PoliciesView.vue";
import EmploymentSickLeaveList from "@/components/payroll/sickLeave/EmploymentSickLeaveList.vue";
import EmploymentVacationList from "@/components/payroll/vacation/EmploymentVacationList.vue";
import EmploymentCard from "@/components/payroll/business/employments/EmploymentCard.vue";

@Component({})
export default class ForeignContractor extends EmploymentCard<IForeignContractor> {

	get components() {
		const components: Map<string, typeof Vue> = new Map<string, typeof Vue>()
		if (this.getEmployment) {
			switch (this.getEmployment.counterpartyType) {
				case CounterpartyType.PERSON:
					components.set(`${this.getEmployment.details.name}`, IndividualForeignContractor);
					components.set(`Compensation`, Compensation)
					components.set("Policies", PoliciesView)
					components.set("Vacations", EmploymentVacationList)
					components.set("Sick Leaves", EmploymentSickLeaveList)
					break;
				case CounterpartyType.COMPANY:
					components.set(`${this.getEmployment.details.name}`, BusinessForeignContractor)
			}
		}
		return components
	}

	created() {
		super.created()
		this.setTitle("Foreign Contractor Card");
	}

}
