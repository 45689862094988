































import {Component, Prop, Vue} from 'vue-property-decorator';
import {ChangeStatusVCRRequestPayload, VCR, VCRStatus} from "@/dto/payroll/VacationDTO";
import moment, {Moment} from "moment-timezone";
import {prettyEnum} from "../../../utils/StringUtils";
import {cancel} from "typescript-debounce-decorator";
import Application from "@/state/Application";
import VacationService from "@/services/payroll/VacationService";
import {IEmployment} from "@/dto/payroll/IEmployment";
import {processError} from "@/utils/ComponentUtils";

@Component(
	{
		computed: {
			VCRStatus() {
				return VCRStatus
			}
		},
		methods: {
			cancel,
			prettyEnum,
			moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
				return moment(inp, format, language, strict)
			},
		}
	}
)
export default class VacationRequestView extends Vue {

	@Prop()
	private vcr!: VCR

	@Prop()
	private employment!: IEmployment

	@Prop()
	private callback!: () => void

	private cancel() {
		Application.startLoading()
		VacationService.changeStatus(
			new ChangeStatusVCRRequestPayload(
				{
					requestId: this.vcr.id,
					employmentId: this.employment.id,
					status: VCRStatus.CANCELLED
				}
			)
		).then(
			() => {
				this.$modal.hideAll()
				Application.stopLoading()
				this.callback()
			},
			err => processError(err, this)
		)
	}

	get changeable() {
		return [VCRStatus.REQUESTED, VCRStatus.IN_PROGRESS].includes(this.vcr.status)
	}

}
