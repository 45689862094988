



































import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import StringMask from '@pedrobslisboa/js-string-mask';
import {InputType} from "@/components/common/InputType";
import {getCustomerUUID} from "@/services/auth-header";
import {v4 as uuidv4} from "uuid";

@Component<PortalInput>({
  inheritAttrs: false,
})
export default class PortalInput extends Vue {
  @VModel({type: [String, Number]})
  private inputValue!: string;

  @Prop({default: false})
  private readonly !: boolean

  @Prop({default: null})
  private name!: string | null;
  private nameInternal: string | null = this.name;

  @Prop({default: null})
  private id!: string | null;
  private idInternal: string | null = this.id;

  @Prop({default: "text"})
  private type!: "text" | "number" | "email" | "password";
  private typeInternal: "text" | "number" | "email" | "password" = this.type;

  @Prop({default: ''})
  private cssClasses!: string;

  @Prop({default: null})
  private hint!: string | null;

  @Prop()
  private label!: string;

  @Prop({default: null})
  private maxLength!: number | null;

  @Prop({default: ''})
  private placeholder!: string;

  @Prop({default: ''})
  private validatePattern!: string | Record<string, any>;

  public created(): void{
    if(this.idInternal == null && this.nameInternal == null) {
      this.idInternal = "portal_inpput_id_" + uuidv4();
		  this.nameInternal = "portal_inpput_name_" + uuidv4();
    }
    this.$validator = this.$parent.$validator;
  }

  @Prop({default: null})
  private callBack!: () => void | null;

  /**
   * Token -> Description
   * 0 -> Int
   * A -> Letter
   * S -> Letter or Int
   * * -> Any char
   * { -> Start escape group
   * } -> End escape group
   * [ -> Start recursive group
   * ] -> End recursive group
   * $ -> Escape next char
   */
  @Prop({default: null})
  private mask!: string | null;

	private processInput(target: any): void {
    this.trimValue()
		if (this.callBack) {
			this.callBack()
		}
		if (this.mask) {
			this.processMask(target);
		} else if (this.maxLength) {
			this.processMaxLength(target);
		}
	}

  private processMaxLength(target: any) {
    if (this.maxLength !== null && target.value.length >= this.maxLength) {
      target.value = target.value.substr(0, this.maxLength);
      this.$emit('input', target.value)
    }
  }

  private processMask(target: any) {
    const formatter = new StringMask({mask: this.mask as string})
    formatter.apply(target.value)
    target.value = formatter.maskedValue;
    this.$emit('input', formatter.maskedValue)
  }

  get isRequired(): boolean {
    if (typeof this.validatePattern === 'string') {
      return this.validatePattern?.includes("required");
    } else {
      return this.validatePattern?.required;
    }
  }

  get internalValue(): string {
    return this.inputValue;
  }

  set internalValue(value: string) {
    this.$emit('input', value);
  }

  trimValue(): void {
    if (this.type in ["text", "email"]) {
      this.internalValue = this.internalValue.trim();
    }
  }

}
