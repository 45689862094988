







































import {Vue, Component, Prop, VModel} from 'vue-property-decorator';
import CompanyDTO from "@/dto/company/CompanyDTO";
import { debounce } from 'typescript-debounce-decorator';
import CompanyService from "@/services/CompanyService";
import CompanyFilterDTO from "@/dto/company/CompanyFilterDTO";
import PortalInput from "@/components/common/PortalInput.vue";

export type OptionType = "ALL" | "NAME" | "EMAIL" | "EIN"

@Component({
	components: {PortalInput}
})
export default class CompanySearch extends Vue {
	@Prop({default: 'ALL'})
	private options!: OptionType | Array<OptionType>

	@Prop({default: "company"})
	private name!: string

	@VModel({type: CompanyDTO})
	private selectedCompany!: CompanyDTO;

	private filter = new CompanyFilterDTO({pageSize: 8});

	private companies: Array<CompanyDTO> = [];

	private showList = false;

	public mounted(): void {
		if (this.selectedCompany) {
			this.filter.companyName = this.selectedCompany.name;
			this.filter.ein = this.selectedCompany.ein;
			this.filter.email = this.selectedCompany.email;
		}
	}

	@debounce(500)
	search(){
		CompanyService.getByFilter(this.filter).then(
			ok => {
				this.companies = ok.data.data;
				this.showList = true;
			},
			err => {
				console.log(JSON.stringify(err))
			}
		)
	}

	selectCompany(c: CompanyDTO){
		this.showList = false;
		this.filter.companyName = c.name;
		this.filter.ein = c.ein;
		this.filter.email = c.email;
		this.$emit('input', c);
		this.$emit("change", c);
	}

}
