import { render, staticRenderFns } from "./VacationRequestCreationModal.vue?vue&type=template&id=073a1cbc&scoped=true&"
import script from "./VacationRequestCreationModal.vue?vue&type=script&lang=ts&"
export * from "./VacationRequestCreationModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073a1cbc",
  null
  
)

export default component.exports