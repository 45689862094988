export default class AttachFile {
    public fileMetaId: number | null = null;

    public preview: string | null = null;

    public name: string | null = null;

    public size: number | null = null;

    public type: string | null = null;

    public url: string | null = null;

    public extension: string | null = null;

    public localUrl: string | null = null;

    public blob: Blob | null = null

    public static clone(file: AttachFile): AttachFile {
        const attach = new AttachFile();
        attach.preview = file.preview;
        attach.blob = file.blob;
        attach.url = file.url;
        attach.type = file.type;
        attach.name = file.name;
        attach.extension = file.extension;
        attach.fileMetaId = file.fileMetaId;
        attach.localUrl = file.localUrl;
        attach.size = file.size;
        return attach
    }
}
