import CollectionUtils from "@/utils/CollectionUtils";

class QueryUtils {

    toQueryString(params: Map<string, string>): string {
        return params.size != 0 ? `?${Array.from(params.entries()).map(e => `${e[0]}=${e[1]}`).join('&')}` : '';
    }

    buildQueryString(...params: Array<[string, string | number | boolean | null | undefined]>): string {
        return this.toQueryString(CollectionUtils.createPathParamsMap(...params));
    }

    objectToQueryString(object: Record<string, any>){
        const params = Object.keys(object)
            .filter(key => object[key] != null && object[key] != undefined)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(`${object[key]}`)}`);
        return params.length != 0 ?  `?${params.join('&')}` : '';
    }

}

export default new QueryUtils();