class RegexUtils {

    private ssnRegex = new RegExp('^\\d(\\d(\\d(-(\\d(\\d(-(\\d(\\d(\\d(\\d)?)?)?)?)?)?)?)?)?)?$');
    private ssnPreSeparatorRegex = new RegExp('(^\\d{3}-\\d{2}$)|(^\\d{3}$)');

    private itinRegex = new RegExp('^\\d(\\d(\\d(-(\\d(\\d(-(\\d(\\d(\\d(\\d)?)?)?)?)?)?)?)?)?)?$');
    private itinPreSeparatorRegex = new RegExp('(^\\d{3}-\\d{2}$)|(^\\d{3}$)');

    private einRegex = new RegExp('^\\d(\\d(-(\\d(\\d(\\d(\\d(\\d(\\d(\\d)?)?)?)?)?)?)?)?)?$');
    private einPreSeparatorRegex = new RegExp('^\\d{2}$');

    private bankAccountNumberRegex = new RegExp('^\\d{1,10}$');

    private floatNumberRegex = new RegExp('^[0-9]*[.]?[0-9]*$');

    private phoneRegex = new RegExp("^[0-9]{1,13}$");

    private zipRegex = new RegExp("^\\d{1,5}$");

    private latinRegex = new RegExp("^[A-Za-z]*$");

    private anyLetter = new RegExp("^[А-Яа-яA-Za-z\\W]*$");

    private emailCharacters = new RegExp("^([A-Za-z@\\d\\._-]*)$");

    private numericRegex = new RegExp("^\\d*$");

    private addressCharactersRegex = new RegExp("^([\\(\\)A-Za-z\\d\\.-\\s]*)$");

    private companyCharactersRegex = new RegExp("^([A-Za-z\\s\\d-]*)$");

    private nameCharactersRegex = new RegExp("['A-Za-z\\-]*");

    public testSsn(ssn: string): boolean {
        return this.ssnRegex.test(ssn);
    }

    public testSsnPreSeparator(ssn: string){
        return this.ssnPreSeparatorRegex.test(ssn);
    }

    public testItin(itin: string): boolean {
        return this.itinRegex.test(itin);
    }

    public testItinPreSeparator(itin: string){
        return this.itinPreSeparatorRegex.test(itin);
    }

    public testEin(ein: string): boolean {
        return this.einRegex.test(ein);
    }

    public testEinPreSeparator(ein: string): boolean {
        return this.einPreSeparatorRegex.test(ein);
    }

    public testBankAccountNumber(num: string): boolean {
        return this.bankAccountNumberRegex.test(num);
    }

    public testFloatNumber(number: string): boolean {
        return this.floatNumberRegex.test(number)
    }

    public testPhone(phone: string): boolean {
        return this.phoneRegex.test(phone);
    }

    public testZip(zip: string): boolean {
        return this.zipRegex.test(zip);
    }

    public testLatin(str: string){
        return this.latinRegex.test(str);
    }

    public testAnyLetter(str: string){
        return this.anyLetter.test(str);
    }

    public testEmailCharacters(str: string){
        return this.emailCharacters.test(str);
    }

    public testNumeric(str: string){
        return this.numericRegex.test(str);
    }

    public testAddressCharacters(str: string){
        return this.addressCharactersRegex.test(str);
    }

    public testCompanyCharacters(str: string){
        return this.companyCharactersRegex.test(str);
    }

    public testNameCharacters(str: string){
        return this.nameCharactersRegex.test(str);
    }
}

export default new RegexUtils();