import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import TaxationService from "@/services/TaxationService";
import TaxationUserQuestionnaireDTO from "@/dto/TaxationUserQuestionnaireDTO";
import CreateQuestionnaireRequestDTO from "@/dto/CreateQuestionnaireRequestDTO";

@Module({namespaced: true})
class Taxation extends VuexModule {

    public questionnaire: TaxationUserQuestionnaireDTO | null = null

    public totalPrice = 0;

    public totalFiles = 0;

    @Mutation
    userQuestionnaireLoaded(questionnaire: TaxationUserQuestionnaireDTO | null) {
        this.questionnaire = questionnaire
    }

    @Mutation
    userQuestionnaireNotLoaded() {
        this.questionnaire = null
    }

    @Mutation
    calcPriceAndFilesCount() {
        this.totalPrice = 0;
        this.totalFiles = 0;
        if (this.questionnaire != null) {
            this.questionnaire.formGroups.forEach(g => {
                g.forms.forEach(f => {
                    f.files.forEach(file => {
                        this.totalFiles++;
                        this.totalPrice += f.pricePerFile;
                    })
                })
            });
        }
    }

    @Action({rawError: true})
    saveQuestionnaire() {
        if (this.questionnaire != null) {
            TaxationService.saveQuestionnaire(this.questionnaire).then(
                response => {
                    this.context.commit("userQuestionnaireLoaded", response.data)
                    this.context.commit("calcPriceAndFilesCount")
                    return Promise.resolve(response.data);
                },
                error => {
                    const message =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                    return Promise.reject(message);
                }
            )
        }
    }

    @Action({rawError: true})
    loadQuestionnaire(id: number) {
        return TaxationService.getQuestionnaireById(id).then(
            questionnaire => {
                this.context.commit("userQuestionnaireLoaded", questionnaire.data)
                this.context.commit("calcPriceAndFilesCount")
                return Promise.resolve(questionnaire.data)
            },
            error => {
                this.context.commit("userQuestionnaireNotLoaded")
                const message =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                return Promise.reject(message);
            }
        )
    }

    @Action
    recalcPrice() {
        this.context.commit("calcPriceAndFilesCount")
    }

    @Action
    createQuestionnaire(request: CreateQuestionnaireRequestDTO) {
        TaxationService.createQuestionnaire(request).then (
            response => {
                this.context.commit("userQuestionnaireLoaded", response.data)
                this.context.commit("calcPriceAndFilesCount")
                return Promise.resolve(response.data)
            },
            error => {
                this.context.commit("userQuestionnaireNotLoaded")
                const message =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                return Promise.reject(message);
            }
        );
    }

    @Action({rawError: true})
    submitQuestionnaire(){
        TaxationService.submitQuestionnaire(this.questionnaire as TaxationUserQuestionnaireDTO).then(
            response => {
                this.context.commit("userQuestionnaireLoaded", response.data);
                this.context.commit("calcPriceAndFilesCount");
                return Promise.resolve(response.data);
            },
            error => {
                this.context.commit("userQuestionnaireNotLoaded")
                const message =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                return Promise.reject(message);
            }
        );
    }


}

export default Taxation;