









































































































import {Component, Prop, Vue} from "vue-property-decorator";
import PortalInput from "@/components/common/PortalInput.vue";
import {IndividualContractorDTO} from "@/components/payroll/business/employments/contractors/Contractor";
import {namespace} from "vuex-class";
import IndividualContractorService
	from "@/components/payroll/business/employments/contractors/IndividualContractorService";
import PortalDate from "@/components/common/PortalDate.vue";
import RouteNames from "@/router/RouteNames";
import {WorkspaceType, WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import TerminationModal from "@/components/payroll/business/employments/contractors/TerminationModal.vue";
import TerminationPayloadDTO from "@/components/payroll/business/employments/contractors/TerminationPayloadDTO";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {ifValid, processError} from "@/utils/ComponentUtils";
import PortalSelect from "@/components/common/PortalSelect.vue";
import BranchDto from "@/components/profile/branches/BranchDtos";
import CompanyDTO from "@/dto/company/CompanyDTO";
import SelectOption from "@/components/common/SelectOption";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import {ContractType, contractTypeOptions} from "@/constants/EmploymentType";
import Application from "@/state/Application";
import {EmploymentVue} from "@/components/payroll/business/employments/EmploymentVue";

const AppModule = namespace("App");

@Component({
	computed: {
		ContractType() {
			return ContractType
		},
		SelectOption() {
			return SelectOption
		},
		WorkspaceTypePathElement() {
			return WorkspaceTypePathElement
		},
		WorkspaceType() {
			return WorkspaceType
		}
	},
	methods: {
		contractTypeOptions: contractTypeOptions,
		RouteNames() {
			return RouteNames
		}
	},
	components: {PortalRadio, PortalSelect, PortalDate, PortalInput}
})
export default class IndividualContractor extends EmploymentVue<IndividualContractorDTO> {

	private message: string | null = null;

	private branches: Array<BranchDto> = [];

	private successful = false;

	@Prop()
	employment!: IndividualContractorDTO;

	public mounted(): void {
		this.assembleBranches(this.employment.employer)
	}

	private openTerminateModal():void {
		this.$modal.show(
			TerminationModal,
			{
				terminateId: this.employment.id,
				applyTerminate: (payload: TerminationPayloadDTO) => this.terminate(payload)
			},
			{
				height: "auto"
			}
		)
	}

	private terminate(payload: TerminationPayloadDTO): void {
		Application.startLoading();
		IndividualContractorService.terminateContractor(payload).then(
			() => {
				this.successful = true;
				this.message = "The contractor's contract has been terminated"
				this.$router.push({name: RouteNames.HR_EMPLOYEES, params: {companyId: String(this.employment.employer?.id)}})
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private assembleBranches(company: CompanyDTO) {
		if (company.headquarter) {
			this.branches.push(company.headquarter);
		}
		if (company.branches) {
			this.branches.push(...company.branches)
		}
	}

	private deleteContractor(): void {
		Application.startLoading()
		IndividualContractorService.deleteContractor(this.employment.id as number).then(
			() => {
				Application.stopLoading()
				this.$router.push({name: RouteNames.HR_CONTRACTORS, params: {companyId: String(this.employment.employer?.id)}})
			},
			err => processError(err, this)
		)
	}

	private update(): void {
		ifValid(this, () => {
			Application.startLoading()
			IndividualContractorService.updateContractor(this.employment).then(
				() => {
					this.successful = true;
					this.message = "The contractor's data has been updated"
					Application.stopLoading()
				},
				err => processError(err, this)
			)
		})
	}
}

