
















import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import {v4 as uuidv4} from "uuid";
import {PropType} from "vue";


@Component
export default class PortalDateTime extends Vue {
	@VModel({type: [String, Date] as PropType<any>})
	private inputValue!: any;

	@Prop({default: null})
	private name!: string | null;

	@Prop({default: function () {return `${uuidv4()}`}})
	private id!: string | null;

	@Prop({default: null})
	private hint!: string | null;

	@Prop()
	private label!: string;

	@Prop({default: null})
	private max!: string | null;

	@Prop({default: null})
	private min!: string | null;

	@Prop({default: null})
	private placeholder!: string | null;

	@Prop({default: ''})
	private validatePattern!: string;

	public created(): void {
		if (this.name == null && this.id == null) {
			this.id = uuidv4()
		}
	}

	get isRequired(): boolean {
		return this.validatePattern?.includes("required");
	}
}
