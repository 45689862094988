














import {Component, Prop, Vue} from "vue-property-decorator";
import PTOView from "@/components/payroll/policies/pto/PTOView.vue";
import SLPView from "@/components/payroll/policies/sickLeave/SLPView.vue";
import {IEmployment, TariffedEmployment} from "@/dto/payroll/IEmployment";
import {EmploymentVue} from "@/components/payroll/business/employments/EmploymentVue";

@Component({
	components: {SLPView, PTOView}
})
export default class PoliciesView extends EmploymentVue<IEmployment>{

	@Prop()
	employment!: IEmployment

}

