import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import Stomp, { Client } from "stompjs";
import { SOCKET_ENDPOINT } from "@/constants/endpoints";
import SockJS from "sockjs-client";

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'chat'
})
class Session extends VuexModule {
    private sessionClient: Client | null = null;

    private subscriptionsBuffer: Map<string, (sessionClient: Client) => any> = new Map<string, (sessionClient: Client) => any>();

    private mapDestinationAnsSubId: Map<string, string> = new Map<string, string>();

    get getSessionClient() {
        return this.sessionClient;
    }

    get getSubscriptionsBuffer() {
        return this.subscriptionsBuffer;
    }

    @Mutation
    private SET_SESSION(session: Client) {
        this.sessionClient = session;
    }

    @Mutation
    private SUBSCRIBE(prop: {destination: string, onReceiveMessageMethod: (obj: any) => any}) {
        if (this.sessionClient && this.sessionClient.connected) {
            if (this.mapDestinationAnsSubId.get(prop.destination)) {
                this.sessionClient?.unsubscribe(<string>this.mapDestinationAnsSubId.get(prop.destination))
                this.mapDestinationAnsSubId.delete(prop.destination);
            }
            const sub = this.sessionClient?.subscribe(prop.destination, prop.onReceiveMessageMethod);
            this.mapDestinationAnsSubId.set(prop.destination, sub!.id);
            this.subscriptionsBuffer.set(prop.destination, prop.onReceiveMessageMethod);
        } else {
            if (this.subscriptionsBuffer.get(prop.destination)) this.subscriptionsBuffer.delete(prop.destination);
            this.subscriptionsBuffer.set(prop.destination, prop.onReceiveMessageMethod);
        }
    }

    @Action
    public setConnection() {
        if (!this.getSessionClient || !this.getSessionClient.connected) {
            const session = Stomp.over(new SockJS(SOCKET_ENDPOINT));
            session.debug = (log) => {
                const logItem= JSON.stringify(log)
                if (logItem.includes('SUBSCRIBE') || logItem.includes('CONNECTION')) {
                    console.log(log);
                    return;
                }
            };
            session.connect({},
                () => {
                    this.context.commit("SET_SESSION", session);
                    this.getSubscriptionsBuffer.forEach((foo, dest) => this.context.commit('SUBSCRIBE', {destination: dest, onReceiveMessageMethod: foo}))
                },
                () => {
                    setTimeout(() => {
                        if (!this.getSessionClient || !this.getSessionClient.connected) {
                            this.setConnection();
                        }
                    }, 5000);
                })
        }
    }

    @Action({commit: 'SUBSCRIBE'})
    public subscribe(props : {destination: string, onReceiveMessageMethod: (obj: any) => any}) {
        return props;
    }
}

export default getModule(Session)
