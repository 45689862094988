
















import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class PaginationComponent extends Vue{
    @Prop()
    private totalPages!: number;

    @Prop({default: 5})
    private rangePages!: number;

    @Prop()
    private pageNumber!: number;

    @Prop()
    private onChangePage!: (page: number) => void;

    private pages: Array<number> = [this.pageNumber];

    mounted() {
      this.calcPages();
    }

    @Watch("totalPages")
    onTotalPagesChanges(oldValue: number, newValue: number){
      this.calcPages();
    }

    @Watch("pageNumber")
    onPageChanges(oldValue: number, newValue: number){
      this.calcPages();
    }

    calcPages(){
      let left = this.pageNumber - Math.floor(this.rangePages / 2);
      if(left < 1){
        left = 1;
      }
      let right = this.pageNumber + Math.round((this.rangePages - 1) / 2);
      if(right > this.totalPages){
        right = this.totalPages;
      }
      let pages = [];
      for(let num = left; num <= right; num++){
        pages.push(num);
      }
      this.pages = pages;
    }


    changePage(page: number) {
        this.onChangePage(page);
    }

    endPages(x: number) {
        return this.totalPages > x ? x : this.totalPages;
    }

}
