class CollectionUtils {

    /**
     * every even is a key
     * every odd is a value
     * */
    createStringStringMap(...args: Array<string>): Map<string, string> {
        if (args.length % 2 != 0) {
            throw new Error('Arguments length should be even');
        }
        const result = new Map<string, string>()
        for (let i = 0; i < args.length; i += 2) {
            result.set(args[i], args[i + 1]);
        }
        return result;
    }

    createMap<K, V>(...entries: Array<[K, V | null | undefined]>): Map<K, V> {
        const result = new Map<K, V>()
        for (let i = 0; i < entries.length; i++) {
            const value = entries[i][1]
            if (value) {
                result.set(entries[i][0], value);
            }
        }
        return result;
    }

    createPathParamsMap(...entries: Array<[string, string | number | null | undefined | boolean]>): Map<string, string> {
        const result = new Map<string, string>()
        for (let i = 0; i < entries.length; i++) {
            const value = entries[i][1]
            if (value != null && value != undefined) {
                result.set(entries[i][0], `${value}`);
            }
        }
        return result;
    }

}

export default new CollectionUtils();