import axios from "axios";
import {BUSINESS_FOREIGN_CONTRACTOR_SERVICE} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import TerminationPayloadDTO from "@/components/payroll/business/employments/contractors/TerminationPayloadDTO";
import {
    BusinessForeignContractorDTO, UpdateBusinessForeignContractorDetailsPayload
} from "@/components/payroll/business/employments/foreignContractors/ForeignContractorPayloads";

class BusinessForeignContractorService {
    public getContractorById(contractorId: number) {
        return axios.get<BusinessForeignContractorDTO>(`${BUSINESS_FOREIGN_CONTRACTOR_SERVICE}/${contractorId}`, {headers: authHeader()})
    }

    public terminateContractor(payload: TerminationPayloadDTO) {
        return axios.put(`${BUSINESS_FOREIGN_CONTRACTOR_SERVICE}/terminate`, payload, {headers: authHeader()})
    }

    public deleteContractor(contractorId: number) {
        return axios.delete(`${BUSINESS_FOREIGN_CONTRACTOR_SERVICE}/${contractorId}`, {headers: authHeader()})
    }

    public updateContractor(payload: UpdateBusinessForeignContractorDetailsPayload) {
        return axios.put(`${BUSINESS_FOREIGN_CONTRACTOR_SERVICE}`, payload, {headers: authHeader()})
    }
}

export default new BusinessForeignContractorService();