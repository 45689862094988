





















import {Vue, Component, Model, Prop} from 'vue-property-decorator';
import { debounce } from 'typescript-debounce-decorator';
import PersonDTO from "@/dto/person/PersonDTO";
import PersonService from "@/services/PersonService";
import PersonFilterDTO from "@/dto/person/PersonFilterDTO";

@Component({})
export default class PersonSearch extends Vue {

  @Model('change', {type: PersonDTO, default: null})
  private selectedPerson!: PersonDTO | null;

  @Prop({default: "persons"})
  private name!: string

  private filter = new PersonFilterDTO({pageSize: 8});

  private persons: Array<PersonDTO> = [];

  private showList = false;

  mounted(){
    if(this.selectedPerson){
      this.filter.fullNameFilter = this.selectedPerson.firstName + ' ' + this.selectedPerson.lastName;
    }
  }

  @debounce(200)
  search(){
    PersonService.getByFilter(this.filter).then(
        ok => {
          this.persons = ok.data.data;
          this.showList = true;
        },
        err => {
          console.log(JSON.stringify(err))
        }
    )
  }

  selectPerson(p: PersonDTO){
    this.showList = false;
    // this.selectedPerson = p;
    this.filter.fullNameFilter = p.firstName + ' ' + p.lastName;
    this.$emit('change', p);
  }

}
