











































import {Component, Prop, Vue} from "vue-property-decorator";
import {SickLeavePolicy, SickLeavePolicyFilter} from "@/dto/payroll/policies/SickLeavePolicyDTO";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {processError} from "@/utils/ComponentUtils";
import {prettyEnum} from "../../../../utils/StringUtils";
import {PTOPolicy, PTOPolicyFilter} from "@/dto/payroll/policies/PTOPolicyDTO";
import PolicyService from "@/services/payroll/PolicyService";

@Component({
	methods: {prettyEnum}
})
export default class PTOAttachModal extends Vue {

	@Prop()
	private employmentId!: number

	@Prop()
	private callback!: () => void

	private filter = new PTOPolicyFilter(
		{
			companyId: this.$wss.getCurrent.id,
			isActive: true,
			signed: true
		}
	)

	private policies: Array<PTOPolicy> = []

	private loadPolicies() {
		Application.startLoading();
		PolicyService.getPageOfPTOPolicies(this.filter).then(
			res => {
				this.policies = res.data.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created() {
		this.loadPolicies()
	}

	private attachPolicy(policy: PTOPolicy) {
		Application.startLoading()
		console.log(this.employmentId)
		PolicyService.attachPTO(this.employmentId, policy.id).then(
			() => {
				this.$modal.hideAll()
				Application.stopLoading();
				this.callback()
			},
			err => processError(err, this)
		)
	}

}

