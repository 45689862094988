import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import Home from '@/components/main/Home.vue';
import Login from '@/components/auth/Login.vue';
import RouteNames from '@/router/RouteNames'
import AdminSupport from "@/components/support/AdminSupport.vue";
import {CHANGE_WORKSPACE_ID, CHANGE_WORKSPACE_TYPE, COMPANY_ID, CONTRACTOR_TYPE, PERSON_ID, SUBLIME_EMPLOYEE_ID, WORKSPACE_ID, WORKSPACE_TYPE} from "@/constants/Paths";
import {WorkspaceType, WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import Workspaces from "@/state/Workspaces";
import {isPublicPage, shouldContainCompanyId, shouldContainPersonId, shouldContainSublimeEmployeeId, shouldContainWorkspaceTypeAndId} from "@/utils/RoutePathUtils";
import Viewer from "@/components/profile/Viewer.vue";
import Audit from "@/components/admin/audit/Audit.vue";
import Notifications from "@/state/Notifications";
import CustomerNoticeLetterList from "@/components/documents/notice/CustomerNoticeLetterList.vue";
import TimeSheet from "@/components/payroll/timesheet/TimeSheet.vue";
import Projects from "@/components/payroll/business/Projects.vue";
import PayrollCloser from "@/components/payroll/business/employments/employee/PayrollCloser.vue";
import PayrollReportList from "@/components/payroll/business/employments/employee/PayrollReportList.vue";
import PayrollReportBuilder from "@/components/payroll/business/employments/employee/PayrollReportBuilder.vue";
import PTOPolicyList from "@/components/payroll/policies/pto/PTOPolicyList.vue";
import SickLeavePolicyList from "@/components/payroll/policies/sickLeave/SickLeavePolicyList.vue";
import IndividualPTOPolicy from "@/components/payroll/policies/pto/IndividualPTOPolicy.vue";
import IndividualSLP from "@/components/payroll/policies/sickLeave/IndividualSLP.vue";
import VacationList from "@/components/payroll/vacation/VacationList.vue";
import SickLeaveList from "@/components/payroll/sickLeave/SickLeaveList.vue";
import ForeignContractor from "@/components/payroll/business/employments/foreignContractors/ForeignContractor.vue";
import Contractor from "@/components/payroll/business/employments/contractors/Contractor.vue";
import IndividualVacations from "@/components/payroll/vacation/IndividualVacation.vue";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: RouteNames.HOME,
        component: Home,
    },
    {
        path: '/login',
        name: RouteNames.LOGIN,
        component: Login
    },
    {
        path: '/support',
        name: RouteNames.SUPPORT,
        component: AdminSupport
    },
    {
        path: `/timesheet/:${WORKSPACE_TYPE}?/:${WORKSPACE_ID}?`,
        name: RouteNames.TIMESHEET,
        component: TimeSheet
    },
    {
        path: '/admin',
        name: RouteNames.ADMIN_PANEL,
        component: Viewer,
        children: [
            {
                path: `/admin/:${SUBLIME_EMPLOYEE_ID}?/audit`,
                name: RouteNames.AUDIT,
                component: Audit
            },
            {
                path: `/admin/mail`,
                name: RouteNames.MAIL_REGISTRY,
                component: () => import('@/components/admin/mail/MailRegistry.vue')
            }
        ]
    },
    {
        path: '/sublime',
        name: RouteNames.SUBLIME,
        component: () => import('@/components/profile/Viewer.vue'),
        children: [
            {
                path: '/sublime/offices',
                name: RouteNames.OFFICES,
                component: () => import('@/components/admin/office/SublimeOfficeList.vue'),
            },
            {
                path: '/sublime/offices/:officeId',
                name: RouteNames.OFFICE,
                component: () => import('@/components/admin/office/SublimeOffice.vue')
            },
            {
                path: '/sublime/employments',
                name: RouteNames.EMPLOYEES,
                component: () => import('@/components/admin/employee/SublimeEmployeesList.vue')
            },
            {
                path: '/sublime/employments/:employeeId',
                name: RouteNames.EMPLOYEE,
                component: () => import('@/components/admin/employee/SublimeEmployee.vue')
            },
            {
                path: '/sublime/assignments',
                name: RouteNames.SUBLIME_AUTO_ASSIGNMENTS,
                component: () => import('@/components/admin/AutoAssignmentOptions.vue')
            },
            {
                path: '/sublime/report',
                name: RouteNames.REPORTS,
                component: () => import('@/components/admin/report/ReportList.vue')
            }
        ]
    },
    {
        path: `/userprofile/:${WORKSPACE_TYPE}?/:${WORKSPACE_ID}?`,
        name: RouteNames.USER_PROFILE,
        component: () => import('@/components/profile/Viewer.vue'),
        props(route) {
            const id = Number.parseInt(route.params[WORKSPACE_ID], 10);
            return Number.isNaN(id) ? null : {id}
        },
        children: [
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/account`,
                name: RouteNames.PROFILE_ACCOUNT,
                component: () => import('@/components/profile/AccountSettings.vue'),
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/address`,
                name: RouteNames.PROFILE_ADDRESS,
                component: () => import('@/components/profile/Address.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/headquarter`,
                name: RouteNames.PROFILE_HEADQUARTER,
                component: () => import('@/components/profile/branches/Headquarter.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/branches`,
                name: RouteNames.PROFILE_BRANCH_LIST,
                component: () => import('@/components/profile/branches/BranchList.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/branches/:branchId`,
                name: RouteNames.PROFILE_BRANCH,
                component: () => import('@/components/profile/branches/BranchEditor.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/bank_accounts`,
                name: RouteNames.PROFILE_BANK_ACCOUNTS,
                component: () => import('@/components/profile/bank/BankAccountList.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/bank_accounts/:accountId`,
                name: RouteNames.PROFILE_BANK_ACCOUNT,
                component: () => import('@/components/profile/bank/BankAccount.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/marital`,
                name: RouteNames.PROFILE_MARITAL_STATUS,
                component: () => import('@/components/profile/MaritalStatus.vue'),
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/id_card`,
                name: RouteNames.PROFILE_ID_CARD,
                component: () => import('@/components/profile/person/PersonalIdCard.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/shareholders`,
                name: RouteNames.PROFILE_SHAREHOLDER,
                component: () => import('@/components/profile/Shareholders.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/person_details`,
                name: RouteNames.PROFILE_PERSON_DETAILS,
                component: () => import('@/components/profile/person/PersonDetails.vue'),
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/company_details`,
                name: RouteNames.PROFILE_COMPANY_DETAILS,
                component: () => import('@/components/profile/company/CompanyDetails.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/bank_statements`,
                name: RouteNames.PROFILE_COMPANY_BANK_STATEMENTS,
                component: () => import('@/components/profile/bank/BankStatements.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/business_tax_return`,
                name: RouteNames.PROFILE_COMPANY_BUSINESS_TAX_RETURN,
                component: () => import('@/components/profile/taxation/BusinessTaxReturn.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/other_bank_products`,
                name: RouteNames.PROFILE_OTHER_BANK_PRODUCTS,
                component: () => import('@/components/profile/company/OtherCompanyBankProducts.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/ead`,
                name: RouteNames.PROFILE_PERSON_EAD,
                component: () => import('@/components/profile/person/EADList.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/id`,
                name: RouteNames.PROFILE_PERSON_ID,
                component: () => import('@/components/profile/person/StateIdList.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/greencard`,
                name: RouteNames.PROFILE_PERSON_GREENCARD,
                component: () => import('@/components/profile/person/GreenCardList.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/uspassport`,
                name: RouteNames.PROFILE_PERSON_USPASSPORT,
                component: () => import('@/components/profile/person/CitizenPassportList.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/ssn`,
                name: RouteNames.PROFILE_PERSON_SSN,
                component: () => import('@/components/profile/person/SSNCard.vue')
            },
            {
                path: `/userprofile/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/documents`,
                name: RouteNames.PROFILE_PERSON_DOCUMENTS,
                component: () => import('@/components/profile/PersonDocuments.vue')
            }

        ]
    },
    {
        path: '/admin',
        name: RouteNames.ADMIN,
        component: () => import('@/components/profile/admin/BoardAdmin.vue'),
        children: [
            {
                path: '/admin/individuals',
                name: RouteNames.ADMIN_INDIVIDUALS_LIST,
                component: () => import('@/components/profile/admin/person/PersonList.vue')
            },
            {
                path: '/admin/admins',
                name: RouteNames.ADMIN_ADMINS_LIST,
                component: () => import('@/components/profile/admin/admin/AdminList.vue')
            },
            {
                path: '/admin/companies',
                name: RouteNames.ADMIN_COMPANIES_LIST,
                component: () => import('@/components/profile/admin/company/CompanyList.vue')
            }
        ]
    },
    {
        path: '/taxation/individual',
        name: RouteNames.TAXATION_INDIVIDUAL,
        component: () => import('@/components/profile/Viewer.vue'),
        children: [
            {
                path: '/taxation/individual/estimated',
                name: RouteNames.TAXATION_INDIVIDUAL_ESTIMATED_SUBSCRIPTIONS,
                component: () => import('@/components/taxation/individual/estimated/ETPSubscriptionList.vue'),
            },
            {
                path: '/taxation/individual/estimated/:id',
                name: RouteNames.TAXATION_INDIVIDUAL_ESTIMATED_SUBSCRIPTION,
                component: () => import('@/components/taxation/individual/estimated/ETPSubscription.vue'),
            },
            {
                path: `/taxation/individual/:${PERSON_ID}?/incometax/federal`,
                name: RouteNames.PAYROLL_INDIVIDUAL_INCOME_TAX_FEDERAL,
                component: () => import('@/components/payroll/individual/FederalIncomeTaxReturn.vue')
            },
            {
                path: `/taxation/individual/:${PERSON_ID}?/incometax/state`,
                name: RouteNames.PAYROLL_INDIVIDUAL_INCOME_TAX_STATE,
                component: () => import('@/components/payroll/individual/StateIncomeTaxReturn.vue')
            },
        ]
    },
    {
        path: '/p/etp/offer/:id',
        name: RouteNames.TAXATION_INDIVIDUAL_ESTIMATED_OFFER,
        component: () => import('@/components/taxation/individual/estimated/ETPOffer.vue'),
    },
    {
        path: '/taxation/individual/link/:id',
        name: RouteNames.TAXATION_INDIVIDUAL_QUESTIONNAIRE_PUBLIC,
        component: () => import('@/components/taxation/individual/questionnaire/IndividualQuestionnaire.vue')
    },
    {
        path: '/confirmemail/:confirmationCode',
        name: RouteNames.EMAIL_CONFIRMATION,
        component: () => import('@/components/auth/ConfirmMail.vue')
    },
    {
        path: '/invitation/:invitationCode',
        name: RouteNames.INVITATION,
        component: () => import('@/components/auth/InvitationCompletion.vue')
    },
    {
        path: '/resetpassword/:resetCode',
        name: RouteNames.RESET_PASSWORD,
        component: () => import('@/components/auth/PasswordResetCompletion.vue')
    },
    {
        path: '/locations',
        name: RouteNames.LOCATIONS,
        component: () => import('@/components/main/Locations.vue')
    },
    {
        path: `/archive/:${WORKSPACE_TYPE}?/:${WORKSPACE_ID}?`,
        name: RouteNames.ARCHIVE,
        component: () => import('@/components/profile/Viewer.vue'),
        props: { hasViewerInside: true },
        children: [
            {
                path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/auth`,
                name: RouteNames.ARCHIVE_AUTH,
                component: () => import('@/components/archive/AuthForms.vue')
            },
            {
                path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/w8ben`,
                name: RouteNames.ARCHIVE_W8BEN,
                component: () => import('@/components/archive/w8ben/W8BENList.vue')
            },
            {
                path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/w8ben/:id`,
                name: RouteNames.ARCHIVE_W8BEN_INTERNAL,
                component: () => import('@/components/archive/w8ben/FormW8BEN.vue')
            },
            {
                path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/w9`,
                name: RouteNames.ARCHIVE_W9,
                component: () => import('@/components/archive/w9/W9List.vue')
            },
            {
                path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/fw9/:id`,
                name: RouteNames.ARCHIVE_W9_COMPANY_INTERNAL,
                component: () => import('@/components/archive/w9/FormW9Company.vue')
            },
            {
                path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/fw9/:id`,
                name: RouteNames.ARCHIVE_W9_PERSON_INTERNAL,
                component: () => import('@/components/archive/w9/FormW9Person.vue')
            },
            {
                path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/w4`,
                name: RouteNames.ARCHIVE_W4,
                component: () => import('@/components/archive/w4/W4List.vue')
            },
            {
                path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/fw4/:id`,
                name: RouteNames.ARCHIVE_W4_INTERNAL,
                component: () => import('@/components/archive/w4/FormW4.vue')
            },
            {
                path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/f8822`,
                name: RouteNames.ARCHIVE_F8822,
                component: () => import('@/components/archive/f8822/F8822List.vue')
            },
            // {
            //     path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/i9`,
            //     name: RouteNames.ARCHIVE_I9,
            //     component: () => import('@/components/archive/i9/I9List.vue')
            // },
            // {
            //     path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/fi9/:id`,
            //     name: RouteNames.ARCHIVE_I9_INTERNAL,
            //     component: () => import('@/components/archive/i9/FormI9.vue')
            // },
            {
                path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/it2104`,
                name: RouteNames.ARCHIVE_IT2104,
                component: () => import('@/components/archive/it2104/IT2104List.vue')
            },
            {
                path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/it2104/:id`,
                name: RouteNames.ARCHIVE_IT2104_INTERNAL,
                component: () => import('@/components/archive/it2104/FormIT2104.vue')
            },
            {
                path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/admin/irs_letters`,
                name: RouteNames.ARCHIVE_ADMIN_IRS_LETTERS,
                component: () => import("@/components/documents/notice/AdminNoticeLetterList.vue")
            },
            {
                path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/irs_letters`,
                name: RouteNames.ARCHIVE_IRS_LETTERS,
                component: CustomerNoticeLetterList
            },
            {
                path: `/archive/signable`,
                name: RouteNames.ARCHIVE_SIGNABLE_LIST,
                component: () => import('@/components/archive/SignableDocumentsList.vue')
            },
            {
                path: `/archive/signable_new`,
                name: RouteNames.ARCHIVE_SIGNABLE_LIST_NEW,
                component: () => import('@/components/archive/SignableDocumentsListNew.vue')
            },
            {
                path: `/archive/expirng`,
                name: RouteNames.ARCHIVE_EXPIRING,
                component: () => import("@/components/documents/ExpiringDocumentsTable.vue")
            },
            {
                path: `/archive/receipts/client`,
                name: RouteNames.ARCHIVE_RECEIPTS_CLIENT,
                component: () => import("@/components/documents/ReceiptsClient.vue")
            },
            {
                path: `/archive/receipts/admin`,
                name: RouteNames.ARCHIVE_RECEIPTS_ADMIN,
                component: () => import("@/components/documents/ReceiptsAdmin.vue")
            },
            {
                path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/insurance`,
                name: RouteNames.ARCHIVE_INSURANCE,
                props: true,
                component: () => import("@/components/documents/insurance/InsuranceMain.vue"),
                children: [
                    {
                        path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/insurance/companies`,
                        name: RouteNames.ARCHIVE_INSURANCE_COMPANIES,
                        component: () => import("@/components/documents/insurance/BusinessInsuranceAggregate.vue")
                    },
                    {
                        path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/insurance/settings`,
                        name: RouteNames.ARCHIVE_INSURANCE_SETTINGS,
                        props: true,
                        component: () => import("@/components/documents/insurance/InsuranceSettings.vue")
                    },
                    {
                        path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/insurance/general_liability`,
                        name: RouteNames.ARCHIVE_INSURANCE_GENERAL_LIABILITY,
                        props: { type: 'GENERAL_LIABILITY' },
                        component: () => import("@/components/documents/insurance/InsuranceList.vue")
                    },
                    {
                        path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/insurance/professional_liability`,
                        name: RouteNames.ARCHIVE_INSURANCE_PROFESSIONAL_LIABILITY,
                        props: { type: 'PROFESSIONAL_LIABILITY' },
                        component: () => import("@/components/documents/insurance/InsuranceList.vue")
                    },
                    {
                        path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/insurance/workers_compensation`,
                        name: RouteNames.ARCHIVE_INSURANCE_WORKERS_COMPENSATION,
                        props: { type: 'WORKERS_COMPENSATION' },
                        component: () => import("@/components/documents/insurance/InsuranceList.vue")
                    },
                    {
                        path: `/archive/:${WORKSPACE_TYPE}/:${WORKSPACE_ID}/insurance/disability`,
                        name: RouteNames.ARCHIVE_INSURANCE_DISABILITY,
                        props: { type: 'DISABILITY' },
                        component: () => import("@/components/documents/insurance/InsuranceList.vue")
                    },
                ]
            },
            {
                path: '/archive/payments',
                name: RouteNames.ARCHIVE_PAYMENTS,
                component: () => import('@/components/documents/payments/Payments.vue')
            },
            {
                path: '/archive/payment/:id',
                name: RouteNames.ARCHIVE_PAYMENT,
                component: () => import('@/components/documents/payments/Payment.vue'),
            },
            {
                path: `/archive/reports`,
                name: RouteNames.ARCHIVE_REPORTS_P_ST,
                component: () => import("@/components/documents/DocumentsReports.vue")
            },
            {
                path: `/archive/boi`,
                name: RouteNames.DOCS_FINCEN_BOI,
                component: () => import("@/components/documents/fincen/BOIReportList.vue")
            },
            {
                path: `/archive/annual`,
                name: RouteNames.ARCHIVE_ANNUAL_REPORTS,
                component: () => import("@/components/documents/AnnualReportList.vue")
            }
        ]
    },
    {
        path: '/payroll',
        name: RouteNames.PAYROLL,
        component: () => import('@/components/payroll/Payroll.vue'),
        children: [
            {
                path: `/payroll/individual/:${PERSON_ID}?`,
                name: RouteNames.PAYROLL_INDIVIDUAL,
                component: Viewer
            },
            {
                path: `/payroll/individual/:${PERSON_ID}?/policy`,
                name: RouteNames.PAYROLL_INDIVIDUAL_POLICY,
                component: Viewer
            },
            {
                path: `/payroll/individual/:${PERSON_ID}?/policy/pto`,
                name: RouteNames.PAYROLL_INDIVIDUAL_POLICY_PTO,
                component: IndividualPTOPolicy
            },
            {
                path: `/payroll/individual/:${PERSON_ID}?/policy/slp`,
                name: RouteNames.PAYROLL_INDIVIDUAL_POLICY_SICK_LEAVE,
                component: IndividualSLP
            },
            {
                path: `/payroll/individual/:${PERSON_ID}/paystubs`,
                name: RouteNames.PAYROLL_INDIVIDUAL_PAYSTUBS,
                component: () => import('@/components/payroll/IndividualPaystubs.vue')
            },
            {
                path: `/payroll/individual/:${PERSON_ID}/forms`,
                name: RouteNames.PAYROLL_INDIVIDUAL_FORMS,
                component: () => import('@/components/payroll/IndividualPayrollForms.vue')
            },
            {
                path: `/payroll/individual/:${PERSON_ID}/forms/w2`,
                name: RouteNames.PAYROLL_INDIVIDUAL_FORMS_W2,
                component: () => import('@/components/payroll/IndividualW2Forms.vue')
            },
            {
                path: `/payroll/individual/:${PERSON_ID}/forms/1099`,
                name: RouteNames.PAYROLL_INDIVIDUAL_FORMS_1099,
                component: () => import('@/components/payroll/Individual1099Forms.vue')
            },
            {
                path: `/payroll/business/:${COMPANY_ID}?`,
                name: RouteNames.PAYROLL_BUSINESS,
                component: () => import('@/components/payroll/BusinessPayroll.vue')
            },
            {
                path: `/payroll/business/:${COMPANY_ID}?/projects`,
                name: RouteNames.PAYROLL_COMPANY_PROJECT,
                component: Projects
            },
            {
                path: `/payroll/business/:${COMPANY_ID}?/run_payroll`,
                name: RouteNames.PAYROLL_CLOSE_PAY_PERIOD,
                component: PayrollCloser
            },
            {
                path: `/payroll/business/:${COMPANY_ID}?/reports`,
                name: RouteNames.PAYROLL_REPORTS,
                component: PayrollReportList
            },
            {
                path: `/payroll/business/:${COMPANY_ID}?/reports/builder`,
                name: RouteNames.PAYROLL_REPORTS_BUILDER,
                component: PayrollReportBuilder
            },
            {
                path: `payroll/business/:${COMPANY_ID}?/vacations`,
                name: RouteNames.PAYROLL_COMPANY_VACATION_LIST,
                component: VacationList
            },
            {
                path: `payroll/business/:${COMPANY_ID}?/sick_leves`,
                name: RouteNames.PAYROLL_COMPANY_SICK_LEAVE_LIST,
                component: SickLeaveList
            },
            {
                path: `/payroll/business/:${COMPANY_ID}?/policy`,
                name: RouteNames.PAYROLL_COMPANY_POLICY,
                component: Viewer,
                children: [
                    {
                        path: `/payroll/business/:${COMPANY_ID}/policy/pto`,
                        name: RouteNames.PAYROLL_COMPANY_POLICY_PTO,
                        component: PTOPolicyList
                    },
                    {
                        path: `/payroll/business/:${COMPANY_ID}/policy/slp`,
                        name: RouteNames.PAYROLL_COMPANY_POLICY_SICK_LEAVE,
                        component: SickLeavePolicyList
                    }
                ]
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/employees/jumper/:then`,
                name: RouteNames.PAYROLL_BUSINESS_EMPLOYEES,
                component: () => import('@/components/payroll/PayrollBusinessEmployees.vue')
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/:${PERSON_ID}?/paystubs`,
                name: RouteNames.PAYROLL_BUSINESS_PAYSTUBS,
                component: () => import('@/components/payroll/BusinessEmployeePaystubs.vue'),
                props(route) {
                    const personId = Number.parseInt(route.params[PERSON_ID], 10);
                    return Number.isNaN(personId) ? null : {personId};
                }
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/forms`,
                name: RouteNames.PAYROLL_BUSINESS_FORMS,
                component: () => import('@/components/payroll/BusinessPayrollForms.vue')
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/:${PERSON_ID}?/fw2`,
                name: RouteNames.PAYROLL_BUSINESS_FORMS_W2,
                component: () => import('@/components/payroll/BusinessW2Forms.vue'),
                props(route) {
                    const personId = Number.parseInt(route.params.personId, 10);
                    return Number.isNaN(personId) ? null : {personId};
                }
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/f1099`,
                name: RouteNames.PAYROLL_BUSINESS_FORMS_1099,
                component: () => import('@/components/payroll/business/Forms1099CustomerView.vue')
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/f1042s/:counterpartyId`,
                name: RouteNames.PAYROLL_BUSINESS_FORMS_1042S,
                component: () => import('@/components/payroll/business/BusinessForms1042S.vue')
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/k1/:shareholderId`,
                name: RouteNames.PAYROLL_BUSINESS_FORMS_K1,
                component: () => import('@/components/payroll/business/BusinessFormsK1.vue')
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/:${PERSON_ID}?/details`,
                name: RouteNames.PAYROLL_BUSINESS_DETAILS,
                component: () => import('@/components/payroll/BusinessPayrollDetails.vue'),
                props(route) {
                    const personId = Number.parseInt(route.params.personId, 10);
                    return Number.isNaN(personId) ? null : {personId};
                }
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/general_details`,
                name: RouteNames.PAYROLL_BUSINESS_GENERAL_DETAILS,
                component: () => import('@/components/payroll/GeneralPayrollDetailList.vue')
            },
            {
                path: `/payroll/business/:${COMPANY_ID}?/individual_reports`,
                name: RouteNames.PAYROLL_BUSINESS_REPORTS,
                component: () => import('@/components/profile/Viewer.vue')
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/reports/federal`,
                name: RouteNames.PAYROLL_BUSINESS_REPORTS_FEDERAL,
                component: () => import('@/components/payroll/BusinessFederalReports.vue')
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/reports/regional`,
                name: RouteNames.PAYROLL_BUSINESS_REPORTS_REGIONAL,
                component: () => import('@/components/payroll/BusinessStateReports.vue')
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/employees/:employeeId`,
                name: RouteNames.PAYROLL_COMPANY_EMPLOYEE_DETAILS,
                component: () => import('@/components/payroll/business/employments/employee/EmployeeCard.vue'),
            },


            {
                path: `/payroll/business/:${COMPANY_ID}/contractors/business/:contractorId`,
                name: RouteNames.PAYROLL_COMPANY_BUSINESS_CONTRACTOR,
                component: Contractor,
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/contractors/individual/:contractorId`,
                name: RouteNames.PAYROLL_COMPANY_INDIVIDUAL_CONTRACTOR,
                component: Contractor,
            },
            {
                path: `/payroll/individual/:${PERSON_ID}/vacations`,
                name: RouteNames.PAYROLL_INDIVIDUAL_VACATIONS,
                component: IndividualVacations
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/foreign_contractors/business/:contractorId`,
                name: RouteNames.PAYROLL_COMPANY_BUSINESS_FOREIGN_CONTRACTOR,
                component: ForeignContractor,
            },
            {
                path: `/payroll/business/:${COMPANY_ID}/foreign_contractors/individual/:contractorId`,
                name: RouteNames.PAYROLL_COMPANY_INDIVIDUAL_FOREIGN_CONTRACTOR,
                component: ForeignContractor,
            }
        ]
    },
    {
        path: '/forms/w8ben/:publicId',
        name: RouteNames.PUBLIC_FORMS_W8BEN,
        component: () => import('@/components/archive/w8ben/FormW8BEN.vue')
    },
    {
        path: '/forms/w9/company/:publicId',
        name: RouteNames.PUBLIC_FORMS_W9_COMPANY,
        component: () => import('@/components/archive/w9/FormW9Company.vue')
    },
    {
        path: '/forms/w9/person/:publicId',
        name: RouteNames.PUBLIC_FORMS_W9_PERSON,
        component: () => import('@/components/archive/w9/FormW9Person.vue')
    },
    {
        path: '/forms/w4/:publicId',
        name: RouteNames.PUBLIC_FORMS_W4,
        component: () => import('@/components/archive/w4/FormW4.vue')
    },
    {
        path: '/forms/it2104/:publicId',
        name: RouteNames.PUBLIC_FORMS_IT2104,
        component: () => import('@/components/archive/it2104/FormIT2104.vue')
    },
    {
        path: '/forms/f8822/:documentId?',
        name: RouteNames.FORM_8822_B,
        component: () => import('@/components/archive/f8822/Form8822.vue')
    },
    {
        path: '/upload/companies',
        name: RouteNames.UPLOAD_COMPANIES,
        component: () => import('@/components/upload/CompaniesUpload.vue')
    },
    {
        path: '/upload/employments',
        name: RouteNames.UPLOAD_EMPLOYEES,
        component: () => import('@/components/upload/EmployeesUpload.vue')
    },
    {
        path: `/salestax/:${COMPANY_ID}?`,
        name: RouteNames.SALES_TAX,
        component: () => import('@/components/profile/Viewer.vue'),
        children: [
            {
                path: `/salestax/documents/:${COMPANY_ID}?`,
                name: RouteNames.SALES_TAX_DOCUMENTS,
                component: () => import('@/components/salestax/SalesTaxDocuments.vue')
            },
            {
                path: `/salestax/:${COMPANY_ID}?/permits`,
                name: RouteNames.SALES_TAX_PERMITS,
                component: () => import('@/components/salestax/SalesTaxPermitList.vue')
            },
            {
                path: `/salestax/:${COMPANY_ID}?/subscriptions`,
                name: RouteNames.SALES_TAX_SUBSCRIPTIONS,
                component: () => import('@/components/salestax/SalesTaxSubscriptionList.vue')
            },
            {
                path: `/salestax/:${COMPANY_ID}?/subscriptions/:subscriptionId`,
                name: RouteNames.SALES_TAX_SUBSCRIPTION,
                component: () => import('@/components/salestax/SalesTaxSubscription.vue')
            },
            {
                path: `/salestax/:${COMPANY_ID}?/workflows`,
                name: RouteNames.SALES_TAX_WORKFLOWS,
                component: () => import('@/components/salestax/SalesTaxWorkflowList.vue')
            },
            {
                path: `/salestax/workflows`,
                name: RouteNames.SALES_TAX_WORKFLOWS_ADMIN,
                component: () => import('@/components/salestax/SalesTaxWorkflowList.vue')
            },
            {
                path: `/salestax/:${COMPANY_ID}?/workflows/:workflowId`,
                name: RouteNames.SALES_TAX_WORKFLOW,
                component: () => import('@/components/salestax/workflow/EmployeeWorkflow.vue')
            },
            {
                path: `/salestax/request/:key`,
                name: RouteNames.SALES_TAX_PUBLIC_WORKFLOW,
                component: () => import('@/components/salestax/workflow/CustomerWorkflow.vue')
            },
            {
                path: `/salestax/:${COMPANY_ID}?/reports`,
                name: RouteNames.SALES_TAX_REPORTS,
                component: () => import('@/components/salestax/SalesTaxReportList.vue')
            },
            {
                path: `/salestax/schedule`,
                name: RouteNames.SALES_TAX_SCHEDULE,
                component: () => import('@/components/salestax/SalesTaxSchedule.vue')
            }
        ]
    },
    {
        path: '/requests',
        name: RouteNames.REQUESTS,
        component: () => import('@/components/request/RequestList.vue')
    },
    {
        path: "/rccr/:id",
        name: RouteNames.RCCR,
        component: () => import("@/components/request/rccr/RCCR.vue")
    },
    {
        path: "/p/rccr/:id",
        name: RouteNames.RCCR_CUSTOMER,
        component: () => import("@/components/request/rccr/CustomerRCCR.vue")
    },
    {
        path: "/p/ccdr/:id",
        name: RouteNames.CCDR_CUSTOMER,
        component: () => import("@/components/request/ccdr/CustomerCCDR.vue")
    },
    {
        path: "/p/cbsr/:id",
        name: RouteNames.CBSR_CUSTOMER,
        component: () => import("@/components/request/cbsr/CustomerCBSR.vue")
    },
    {
        path: "/cbsr/:id",
        name: RouteNames.CBSR,
        component: () => import("@/components/request/cbsr/CBSR.vue")
    },
    {
        path: "/p/w9cr/:id?",
        name: RouteNames.W9CR_CUSTOMER,
        component: () => import("@/components/request/w9cr/CustomerW9CR.vue")
    },
    {
        path: "/p/w9cr/c/:id?",
        name: RouteNames.W9CR_COMPANY,
        component: () => import("@/components/request/w9cr/CompanyW9CR.vue")
    },
    {
        path: "/p/w9cr/p/:id?",
        name: RouteNames.W9CR_PERSON,
        component: () => import("@/components/request/w9cr/PersonW9CR.vue")
    },
    {
        path: "/w9cr/:id",
        name: RouteNames.W9CR,
        component: () => import("@/components/request/w9cr/EmployeeW9CR.vue")
    },
    {
        path: "/frsoqwertycreate",
        name: RouteNames.FRSO_CREATE,
        component: () => import("@/components/request/fincen/frso/CreateFRSO.vue")
    },
    {
        path: "/p/o/frso/:id",
        name: RouteNames.FRSO_CUSTOMER,
        component: () => import("@/components/request/fincen/frso/CustomerFRSO.vue")
    },
    {
        path: "/frso/:id",
        name: RouteNames.FRSO,
        component: () => import("@/components/request/fincen/frso/EmployeeFRSO.vue")
    },
    {
        path: '/p/itrq/:id',
        name: RouteNames.ITRQ_CUSTOMER,
        component: () => import("@/components/request/itrq/CustomerITRQ.vue")
    },
    {
        path: '/itrq/:id',
        name: RouteNames.ITRQ,
        component: () => import("@/components/request/itrq/EmployeeITRQ.vue")
    },
    {
        path: '/p/rer/employee/:id',
        name: RouteNames.RER_EMPLOYEE_PUBLIC,
        component: () => import("@/components/request/rer/PublicEmployeeRER.vue")
    },
    {
        path: '/rer/employee/:id',
        name: RouteNames.RER_EMPLOYEE,
        component: () => import("@/components/request/rer/EmployeeRER.vue")
    },
    {
        path: '/rer/:id',
        name: RouteNames.RER,
        component: () => import("@/components/request/rer/RER.vue")
    },
    {
        path: '/p/rer/:id',
        name: RouteNames.RER_CUSTOMER,
        component: () => import("@/components/request/rer/CreateRERCustomerMode.vue")
    },

    {
        path: `/hr`,
        name: RouteNames.HR,
        component: Viewer,
        children: [
            {
                path: `/hr/:${COMPANY_ID}?/employees`,
                name: RouteNames.HR_EMPLOYEES,
                component: () => import('@/components/payroll/business/employments/employee/Employees.vue'),
            },

            {
                path: `/hr/:${COMPANY_ID}?/contractors`,
                name: RouteNames.HR_CONTRACTORS,
                component: () => import('@/components/payroll/business/employments/contractors/Contractors.vue')
            },

            {
                path: `/hr/:${COMPANY_ID}?/foreign_contractors`,
                name: RouteNames.HR_FOREIGN_CONTRACTORS,
                component: () => import('@/components/payroll/business/employments/foreignContractors/ForeignContractors.vue')
            },

            {
                path: `/hr/:${COMPANY_ID}?/requests`,
                name: RouteNames.HR_REQUESTS,
                component: () => import('@/components/request/RequestList.vue')
            },
        ]
    },
    {
        path: '/ridc/:id',
        name: RouteNames.RIDC,
        component: () => import("@/components/request/ridc/RIDC.vue")
    },
    {
        path: '/p/ridc/:id',
        name: RouteNames.RIDC_PUBLIC,
        component: () => import("@/components/request/ridc/PublicRIDC.vue")
    },
    {
        path: '/p/ridc/contractor/:id',
        name: RouteNames.RIDC_PUBLIC_CONTRACTOR,
        component: () => import("@/components/request/ridc/PublicContractorRIDC.vue")
    },
    {
        path: '/sign/:publicId',
        name: RouteNames.SIGN_PUBLIC,
        component: () => import("@/components/signature/SignaturePage.vue")
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    Notifications.clean("error");
    next();
})

router.beforeEach((to, from, next) => {

    const authRequired = to.name && !isPublicPage(to.name)
    const loggedIn = localStorage.getItem('user');
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    // restore workspace if needed
    if(to.params[CHANGE_WORKSPACE_TYPE] && to.params[CHANGE_WORKSPACE_ID]){ // is needed to avoid using these params in other components
        let wsType: WorkspaceType | null = null;
        let wsId: number | null = null;
        switch (to.params[CHANGE_WORKSPACE_TYPE]) {
            case WorkspaceTypePathElement.COMPANY:
                wsType = WorkspaceType.COMPANY;
                break;
            case WorkspaceTypePathElement.PERSON:
                wsType = WorkspaceType.PERSON;
                break;
            case WorkspaceTypePathElement.SUBLIME_EMPLOYEE:
                wsType = WorkspaceType.SUBLIME_EMPLOYEE;
                break;
        }
        wsId = Number.parseInt(to.params[CHANGE_WORKSPACE_ID], 10);
        Workspaces.loadWorkspace(wsId, wsType as WorkspaceType).then(
            resp => {
                // remove type and id from params
                const { [CHANGE_WORKSPACE_TYPE]: _, [CHANGE_WORKSPACE_ID]: __, ...restOfParams } = to.params;
                next({ name: to.name as string, params: restOfParams });
            },
            err => {
                console.log(JSON.stringify(err));
            }
        );
    }else if ((to.params[WORKSPACE_TYPE] && to.params[WORKSPACE_ID]) || to.params[PERSON_ID] || to.params[COMPANY_ID] || to.params[SUBLIME_EMPLOYEE_ID]) {
        let wsType: WorkspaceType | null = null;
        let wsId: number | null = null;

        if (to.params[WORKSPACE_TYPE] && to.params[WORKSPACE_ID]) {
            switch (to.params[WORKSPACE_TYPE]) {
                case WorkspaceTypePathElement.COMPANY:
                    wsType = WorkspaceType.COMPANY;
                    break;
                case WorkspaceTypePathElement.PERSON:
                    wsType = WorkspaceType.PERSON;
                    break;
                case WorkspaceTypePathElement.SUBLIME_EMPLOYEE:
                    wsType = WorkspaceType.SUBLIME_EMPLOYEE;
                    break;
            }
            wsId = Number.parseInt(to.params[WORKSPACE_ID], 10);
        } else if (to.params[PERSON_ID]) {
            wsType = WorkspaceType.PERSON;
            wsId = Number.parseInt(to.params[PERSON_ID], 10);
        } else if (to.params[COMPANY_ID]) {
            wsType = WorkspaceType.COMPANY;
            wsId = Number.parseInt(to.params[COMPANY_ID], 10);
        } else if (to.params[SUBLIME_EMPLOYEE_ID]) {
            wsType = WorkspaceType.SUBLIME_EMPLOYEE;
            wsId = Number.parseInt(to.params[SUBLIME_EMPLOYEE_ID], 10);
        }
        if (wsId && wsType && !Workspaces.isCurrentWorkspace(wsId, wsType)) {
            Workspaces.loadWorkspace(wsId, wsType).then(
                resp => {
                    next();
                },
                err => {
                    console.log(JSON.stringify(err));
                }
            );
        } else {
            next();
        }
    }
    // or restore params if needed
    else if (shouldContainWorkspaceTypeAndId(to)) {
        const params = to.params;
        params[WORKSPACE_TYPE] = Workspaces.getPathElement;
        params[WORKSPACE_ID] = `${Workspaces.getCurrent?.id}`;
        next({name: `${to.name}`, params: to.params});
    } else if (Workspaces.isCurrentWorkspaceSublimeEmployee && shouldContainSublimeEmployeeId(to)) {
        const params = to.params;
        params[SUBLIME_EMPLOYEE_ID] = `${Workspaces.getCurrent?.id}`;
        next({name: `${to.name}`, params: to.params});
    } else if (Workspaces.isCurrentWorkspacePerson && shouldContainPersonId(to)) {
        const params = to.params;
        params[PERSON_ID] = `${Workspaces.getCurrent?.id}`;
        next({name: `${to.name}`, params: to.params});
    } else if (Workspaces.isCurrentWorkspaceCompany && shouldContainCompanyId(to)) {
        const params = to.params;
        params[COMPANY_ID] = `${Workspaces.getCurrent?.id}`;
        next({name: `${to.name}`, params: to.params});
    }

    // else ok
    else {
        next();
    }

});


export default router;
