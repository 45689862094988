
import {Component, Vue} from 'vue-property-decorator';
import EmploymentCard from "@/components/payroll/business/employments/EmploymentCard.vue";
import {IContractor} from "@/components/payroll/business/employments/contractors/Contractor";
import {CounterpartyType} from "@/constants/CounterpartyType";
import PoliciesView from "@/components/payroll/policies/PoliciesView.vue";
import EmploymentVacationList from "@/components/payroll/vacation/EmploymentVacationList.vue";
import EmploymentSickLeaveList from "@/components/payroll/sickLeave/EmploymentSickLeaveList.vue";
import IndividualContractor from "@/components/payroll/business/employments/contractors/IndividualContractor.vue";
import BusinessContractor from "@/components/payroll/business/employments/contractors/BusinessContractor.vue";

@Component
export default class Contractor extends EmploymentCard<IContractor> {

	get components(): Map<string, typeof Vue> {
		const componentMap: Map<string, typeof Vue> = new Map<string, typeof Vue>()
		if (this.getEmployment) {
			switch (this.getEmployment.counterpartyType) {
				case CounterpartyType.PERSON:
					componentMap.set(`${this.getEmployment.details.name}`, IndividualContractor);
					break;
				case CounterpartyType.COMPANY:
					componentMap.set(`${this.getEmployment.details.name}`, BusinessContractor)
			}
		}
		return componentMap
	}


	public created() {
		super.created();
		this.setTitle("Contractor Card");
	}
}
