import UserDTO from "@/dto/UserDTO";
import {NODES_BY_ROLES, Role, ROLES_BY_CODES} from "@/constants/Role";

class Auth {

    private user: UserDTO | null = this.initUser();
    private roles: Array<Role> = this.initRoles();

    private initUser(): UserDTO | null {
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    }

    private initRoles(): Array<Role> {
        const storedRoles = localStorage.getItem("roles");
        if(storedRoles){
            const parsedHashCodes: Array<number> = JSON.parse(storedRoles)
            return parsedHashCodes.map(hash => ROLES_BY_CODES.get(hash)).filter(r => r != undefined) as Array<Role>;
        }else {
            return [];
        }
    }

    get getUser(): UserDTO | null {
        return this.user;
    }

    setUser(user: UserDTO | null) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(user));
    }

    setPerms(secondaryToken: string) {
        const decodedString = atob(secondaryToken);
        const data = JSON.parse(decodedString) as { roles: Array<Role> };
        localStorage.setItem('roles', JSON.stringify(data.roles.map(r => NODES_BY_ROLES.get(r)?.hashCode)));
    }

    hasPermission(role: Role): boolean{
        return !!this.roles.find(r => NODES_BY_ROLES.get(r)?.isOrHasInTree(role));
    }

    clear(){
        this.user = null;
        this.roles = [];
        localStorage.removeItem("user");
        localStorage.removeItem("roles")
    }

}

export default new Auth();

